import React, {useCallback, useEffect, useState} from "react";
import useAuth from "../../core/hooks/useAuth";
import {CircularProgress, Theme} from "@material-ui/core";
import {withRouter, Redirect, RouteComponentProps} from 'react-router-dom';
import {SettingsActions} from "@core/store/actions/settings";
import {useDispatch, useMappedState} from "redux-react-hook";
import useDropdown from "../../core/hooks/useDropdown";
import Payment from "../pages/Payment/PlanPaymentForm";
import PaymentToolbar from "../../shared/Common/Topbar/Toolbar/Payment";
import {makeStyles} from "@material-ui/styles";
import {img} from "@core/utils/string";
import Lockpage from "@featured/pages/Payment/Lockpage";
import Support from "@shared/Common/Support";
import useModal from "@core/hooks/useModal";
import {createEvent} from "@shared/Common/GAEvent";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    background: `url(${img('bg_payment.png')}) no-repeat`,
    backgroundPosition: '0 -80px'
  },
  container: {
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '99%'
    }
  }
}))

const PaymentLayout = (props: React.PropsWithChildren<RouteComponentProps>) => {
  const classes = useStyles();
  const auth = useAuth(props.history);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const {language, company} = useMappedState((state) => ({language: state.settings.language, company: state.stats.company}))

  useEffect(() => {
    if(auth.token !== '' && loading) {
      setLoading(false);
    }
  }, [auth, loading]);


  const dropdown = useDropdown({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }
  });

  const changeLanguage = useCallback((lang: string) => {
    return () => {
      localStorage.setItem('lang', lang);
      dropdown.handleClose();
      dispatch({
        type: SettingsActions.CHANGE_LANG,
        payload: lang
      })
    }
  }, [dispatch, dropdown]);

  const support = useModal();
  const handleSupportToggle = useCallback(() => {
    createEvent({
      category: 'Payment',
      action: 'PaymentSupport'
    })
    support.handleToggle();
  }, [support]);

  return (
    <div className={classes.root}>
      {
        loading
          ? <div className="preloader-main"><h3>Eqman</h3><CircularProgress/></div>
          :
          <div className={classes.container}>
            <Support control={{...support, handleToggle: handleSupportToggle}}/>
            <PaymentToolbar title="Eqman" changeLanguage={changeLanguage} lang={language} dropdown={dropdown} />
            <div style={{zIndex: 100}} className='main'>
               {
                 auth.user ? (auth.user.role === 'root' || auth.user.role === 'admin') ? <Payment/> : <Lockpage expired={company && new Date(company.subscriptionEndDate) < new Date()}/> : <Redirect to="/"/>}
            </div>
          </div>
      }
    </div>
  )
};

export default withRouter(PaymentLayout)
