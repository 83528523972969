import firebase from 'firebase/app';
import 'firebase/messaging';
import {IMessage} from "@core/types";

const firebaseConfig = {
  apiKey: "AIzaSyCuZFxwWKu3MR-quwI_Qoz6MZpKzAN2Rs8",
  authDomain: "eqman-245ab.firebaseapp.com",
  databaseURL: "https://eqman-245ab.firebaseio.com",
  projectId: "eqman-245ab",
  storageBucket: "eqman-245ab.appspot.com",
  messagingSenderId: "753194651551",
  appId: "1:753194651551:web:bef30e717e64d9108277aa",
};

firebase.initializeApp(firebaseConfig);

export function getHandlers() {
  if (!firebase.messaging.isSupported()) return false;
  const messaging = firebase.messaging();

  const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
      const delayDate: any = localStorage.getItem('notifyRequestDelay');
      if(delayDate && (new Date()) < new Date(delayDate)) return;
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch((err) => {
          reject(err);
        });
    });

  const onMessageListener = (): Promise<IMessage> =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload.data);
      });
    });

  return {
    requestFirebaseNotificationPermission,
    onMessageListener
  }
}


