import {all, takeLatest, put, select, delay} from 'redux-saga/effects';
import {IAction} from "../actions";
import service from '../../services/stocktaking';
import {StocktakingActions} from "../actions/stocktaking";
import {normalizeFilters} from "../../utils/normalize";
import notify from "@core/utils/notify";
import {companySelector} from "@core/store/selectors/config";

function* getItems(action: IAction) {
  try {
    yield put({
      type: StocktakingActions.SET_LOADING,
      payload: 'fetching'
    });
    const {auth: {user: {company}}} = yield select();
    const items = yield service.getAll(company, action.payload.pagination, normalizeFilters(action.payload.filters));

    yield put({
      type: StocktakingActions.SET_ITEMS,
      payload: {
        data: items.data,
        total: items.count
      }
    });

    yield put({
      type: StocktakingActions.SET_LOADING,
      payload: 'fetched'
    });
  } catch(E) {
    console.log(E);
  }
}

function* getItem(action: IAction) {
  try {
    yield put({
      type: StocktakingActions.SET_LOADING,
      payload: 'fetching'
    });

    const {auth: {user: {company}}} = yield select();
    const {data: {items: data, ...item}, count} = yield service.getItem(company, action.payload.id, action.payload.pagination);

    yield put({
      type: StocktakingActions.SET_ITEMS,
      payload: {
        data: data,
        total: count
      }
    });

    yield put({
      type: StocktakingActions.SET_ITEM,
      payload: item
    });


    yield delay(100, true);
    yield put({
      type: StocktakingActions.SET_LOADING,
      payload: 'fetched'
    });

  } catch(E) {
    console.log(E);
  }
}


function* downloadReport(action: IAction<string, { id: string }>) {
  try {
    const {_id} = yield select(companySelector);
    const link = yield service.getReportLink(_id, action.payload.id);
    yield service.downloadReport(link);
  } catch (e) {
    notify.error('Cannot download report');
  }
}

export function* rootStocktakingSaga() {
  yield all([
    yield takeLatest(StocktakingActions.GET_ITEMS, getItems),
    yield takeLatest(StocktakingActions.GET_ITEM, getItem),
    yield takeLatest(StocktakingActions.DOWNLOAD_STOCKTAKING_REPORT, downloadReport),
  ])
}
