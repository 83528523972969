import React, {useContext} from 'react'
import { Redirect, Route } from 'react-router-dom'
import {RouteProps} from "react-router";
import {AuthProvider} from "@featured/layout/Main";

const RootRoute = (props: RouteProps) => {
  const user = useContext(AuthProvider);
  if (user && (user.role === 'root' || user.role === 'admin')) {
    return <Route path={props.path} exact={props.exact} render={props.render} component={props.component}/>
  } else {
    return <Redirect to="/items" />
  }
};
export default RootRoute
