import React, {useState} from 'react';
import Welcome from './Welcome'
import CreateCompanyPage from "./Create";


const CompanyPage = () => {
  const [isWelcomePage, setWelcomePage] = useState(true);
  return (
    <div>
      {isWelcomePage && <Welcome continue={() => setWelcomePage(false)}/>}
      {!isWelcomePage && <CreateCompanyPage/>}
    </div>
  )
};

export default CompanyPage;