import {all, put, takeLatest} from "redux-saga/effects";
import {PaymentActions} from "@core/store/actions/payment";
import {IAction, IActionWithErrorAndSuccess} from "@core/store/actions";
import Service from '@core/services/payment';
import {isMobile} from "react-device-detect";
import {normalizeFilters} from "@core/utils/normalize";
import notify from "@core/utils/notify";

function* getAll(action: IAction) {
  try {
    yield put({
      type: PaymentActions.SET_LOADING,
      payload: true
    });

    const items = yield Service.getPayment({...normalizeFilters(action.payload.filters), ...action.payload.pagination});

    yield put({
      type: PaymentActions.SET_ITEMS,
      payload: {
        data: items.data,
        total: items.count,
        isMobile
      }
    });

    yield put({
      type: PaymentActions.SET_LOADING,
      payload: false
    });
  } catch(E) {
    yield put({
      type: PaymentActions.SET_ITEMS,
      payload: {
        data: [],
        total: 0
      }
    });
  }
}

function* getSingle(action: IAction) {
  try {
    yield put({
      type: PaymentActions.SET_LOADING,
      payload: true
    });

    const item = yield Service.getSinglePayment(action.payload.id);

    yield put({
      type: PaymentActions.SET_ITEM,
      payload: item
    })

    yield put({
      type: PaymentActions.SET_LOADING,
      payload: false
    });
  } catch (e) {
    yield put({
      type: PaymentActions.SET_LOADING,
      payload: true
    });
    notify.warning('Error getting payment details')
  }
}

function* create(action: IActionWithErrorAndSuccess) {
  try {
    const data = yield Service.createPayment(action.payload);
    yield action.sideEffectSuccess(data);
  } catch (e) {
    action.sideEffectError(e);
  }
}

function* disableSubscription() {
  try {
    yield Service.disableSubscription();
    notify.success('Запрос на отмену подписки успешно отправлен');
  } catch (e) {
    notify.error('Ошибка отмены подписки. Обратитесь в поддержку');
  }
}


export default function* rootPaymentSaga() {
  yield all([
    yield takeLatest(PaymentActions.CREATE_PAYMENT, create),
    yield takeLatest(PaymentActions.GET_ITEMS, getAll),
    yield takeLatest(PaymentActions.GET_ITEM, getSingle),
    yield takeLatest(PaymentActions.DISABLE_SUBSCRIPTION, disableSubscription),
  ])
}