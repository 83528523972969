import React  from "react";
import {Theme, Button} from "@material-ui/core";
import Card from "../../../../shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    marginTop: -100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    },
    '& h3.text-default, & h3.text-default + p' : {
      textAlign: 'center'
    }
  },
  card: {
    padding: '20px'
  },
  img: {
    width: '70%',
    display: 'block',
    margin: '0 auto'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  imageBlock: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '25px',
    justifyContent: 'space-around',
  },
  appsImg: {
    width: '35%',
  }
}));

const Download = (props: any) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classNames(classes.root, classes.card)}>
      <img src={img('success.jpg')} alt="" className={classes.img}/>
      <h3 className="text-default">{t('password_success')}</h3>
      <p className="subtitle">{t('download_links')}</p>
      <div className={classes.imageBlock}>
        <div className={classes.appsImg}>
        <a href="https://apps.apple.com/ua/app/equipmentman/id1504448666" target="_blank" rel="noopener noreferrer">
          <img src={img('apple.png')} alt="" className={classes.img}/>
        </a>
        </div>
        <div className={classes.appsImg}>
          <a href="https://play.google.com/store/apps/details?id=qrsmarty.eqman" target="_blank" rel="noopener noreferrer">
            <img src={img('android.png')} alt="" className={classes.img}/>
          </a>
        </div>
      </div>
      <div className={classes.actions}>
        <Link to="/auth/login">
          <Button color="secondary">{t('continue')}</Button>
        </Link>
      </div>
  </Card>
  )
};

export default Download;
