import React from "react";
import Card from "@shared/Common/Card";
import classNames from "classnames";
import {Button, Icon, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {IUser} from "@core/types";
import CodeInput from "@shared/Common/Form/CodeInput";
import {useTranslation} from "react-i18next";
import ButtonWithTimer from "@shared/Common/Button/ButtonWithTimer";
import {img} from "@core/utils/string";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    marginTop: -100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    },
    '& h3.text-default, & h3.text-default + p' : {
      textAlign: 'center'
    }
  },
  card: {
    padding: '20px'
  },
  code: {
    margin: '20px 0 40px 0'
  },
  img: {
    width: '50%',
    display: 'block',
    margin: '0 auto',
    marginBottom: 20
  },
  iconBack: {
    cursor: 'pointer'
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }
}));

interface IProps {
  user: IUser,
  back: () => void,
  verify: (code: string) => void
}

const VerifyCode = (props: IProps) => {
  const {
    user: {phone},
    back,
    verify
  } = props;
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Card className={classNames(classes.root, classes.card)}
          title={<Icon className={classes.iconBack} onClick={back}>arrow_back</Icon>}
    >
      <img src={img('verified.svg')} alt="" className={classes.img}/>
      <h3 className="text-default">Верификация номера телелефона</h3>
      <p className="subtitle">Введите код который был отправлен на номер <br/> <strong>{phone}</strong></p>
      <div className={classes.code}>
        <CodeInput length={5} send={verify}/>
      </div>
      <div className={classes.actions}>
        <Button onClick={back}>{t('back')}</Button>
        <ButtonWithTimer awaitTime={60} color="secondary">{t('send_sms')}</ButtonWithTimer>
      </div>
    </Card>
  )
}

export default VerifyCode;