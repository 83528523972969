import React, { PropsWithChildren, ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Slide,
  SwipeableDrawer,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export interface IModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit?: () => void;
  title?: string | ReactNode;
  description?: string;
  canSubmit?: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  actions?: boolean;
  disableBackdropClick?: boolean;
  classes?: any;
  btnSubmit?: string;
  btnCancel?: string;
  cancelAction?: boolean;
}
export function Transition(props: any) {
  return <Slide direction="up" {...props} ref={null} />;
}

const Wrapper = (props: PropsWithChildren<any>) => {
  const { open, onClose, fullWidth, maxWidth, disableBackdropClick } = props;
  return !isMobile ? (
    <Dialog
      open={open}
      onClose={onClose}
      // TransitionComponent={Transition}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={props.className}
      disableBackdropClick={disableBackdropClick}
      ref={null}
    >
      {props.children}
    </Dialog>
  ) : (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={() => {}}
      key="filters-item-drawer"
      onClose={onClose}
    >
      {props.children}
    </SwipeableDrawer>
  );
};

const Modal = (props: PropsWithChildren<IModalProps>) => {
  const {
    open,
    handleClose,
    handleSubmit = () => {},
    title,
    description,
    canSubmit = true,
    fullWidth = false,
    maxWidth = "sm",
    actions = true,
    classes: classesProps = {},
    btnSubmit,
    btnCancel,
    disableBackdropClick = false,
    cancelAction = true,
  } = props;

  const { t } = useTranslation();

  return (
    <Wrapper
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
      className={classesProps.root || ""}
    >
      {title && (
        <DialogTitle>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title}
            <IconButton aria-label="close" onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
      )}
      <DialogContent className={classesProps ? classesProps.content : ""}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {props.children}
      </DialogContent>
      {actions && (
        <DialogActions className={classesProps ? classesProps.actions : ""}>
          {cancelAction && (
            <Button onClick={handleClose} color="primary">
              {btnCancel || t("cancel")}
            </Button>
          )}
          <Button
            onClick={() => {
              handleSubmit();
            }}
            disabled={!canSubmit}
            color="secondary"
          >
            {btnSubmit || t("accept")}
          </Button>
        </DialogActions>
      )}
    </Wrapper>
  );
};

export default Modal;
