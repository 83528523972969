import { all, takeLatest, put, select, delay, call } from "redux-saga/effects";
import {
  IAction,
  IActionWithSingleSideEffect,
  IActionWithErrorAndSuccess,
} from "../actions";
import service from "../../services/user";
import countryApi from "../../services/global";

import { UsersActions } from "../actions/users";
import { normalizeFilters } from "../../utils/normalize";
import { StatsActions } from "@core/store/actions/stats";
import notify from "@core/utils/notify";
import { SagaIterator } from "redux-saga";

function* getItems(action: IAction) {
  try {
    yield put({
      type: UsersActions.SET_LOADING,
      payload: true,
    });

    const {
      auth: {
        user: { company },
      },
    } = yield select();
    const { data, count } = yield service.getItems(company, {
      ...action.payload.pagination,
      ...action.payload.filters,
    });
    yield put({
      type: UsersActions.SET_ITEMS,
      payload: {
        data,
        total: count,
      },
    });

    yield put({
      type: UsersActions.SET_LOADING,
      payload: false,
    });
  } catch (E) {
    yield put({
      type: UsersActions.SET_ITEMS,
      payload: {
        data: [],
        total: 0,
      },
    });
  }
}

function* getItemsMobile(action: IAction) {
  try {
    yield put({
      type: UsersActions.SET_LOADING,
      payload: true,
    });

    const {
      auth: {
        user: { company },
      },
    } = yield select();
    const { data, count } = yield service.getItems(company, {
      ...action.payload.pagination,
      ...action.payload.filters,
    });
    yield put({
      type: UsersActions.SET_ITEMS_MOBILE,
      payload: {
        data,
        total: count,
      },
    });
    yield delay(500, true);
    yield put({
      type: UsersActions.SET_LOADING,
      payload: false,
    });
  } catch (E) {
    yield put({
      type: UsersActions.SET_ITEMS,
      payload: {
        data: [],
        total: 0,
      },
    });
  }
}

function* getItem(action: IAction) {
  try {
    yield put({
      type: UsersActions.SET_LOADING,
      payload: true,
    });

    const {
      auth: {
        user: { company },
      },
      users: { item: storeItem },
    } = yield select();
    let item = null;
    if (!storeItem) {
      item = yield service.getItem(action.payload.id, company);
    }

    yield put({
      type: UsersActions.SET_ITEM,
      payload: item,
    });

    yield put({
      type: UsersActions.GET_ITEM_TRANSFERS,
      payload: {
        id: action.payload.id,
        company: company,
      },
    });

    yield put({
      type: UsersActions.SET_LOADING,
      payload: false,
    });
  } catch (E) {
    console.log(E);
  }
}

function* getItemTransactions(action: IAction) {
  try {
    yield put({
      type: UsersActions.SET_TX_LOADING,
      payload: true,
    });
    const {
      users: {
        txs: { pagination },
        item: { _id },
      },
      auth: {
        user: { company },
      },
    } = yield select();
    yield delay(500);
    const txs = yield service.getItemTransactions(_id, company, pagination);
    yield put({
      type: UsersActions.SET_ITEM_TXS,
      payload: txs,
    });
    yield put({
      type: UsersActions.SET_TX_LOADING,
      payload: false,
    });
  } catch (E) {
    console.log(E);
    yield put({
      type: UsersActions.SET_TX_LOADING,
      payload: false,
    });
  }
}

function* getItemTransfers(action: IAction) {
  try {
    const transfers = yield service.getItemTransfers(
      action.payload.id,
      action.payload.company
    );
    yield put({
      type: UsersActions.SET_TRANSFERS,
      payload: transfers,
    });
  } catch (E) {
    console.log(E);
  }
}

function* create(action: IActionWithErrorAndSuccess): SagaIterator {
  try {
    const {
      auth: {
        user: { company },
      },
      stats: { usersCount },
    } = yield select();

    const { countryCode } = action.payload;
    const country = yield call(countryApi.getCountryByCode, countryCode);

    const { user } = yield call(service.create, company, {
      ...action.payload,
      country: country ? country.name.common : countryCode,
    });
    yield call(action.sideEffectSuccess, user);
    yield put({
      type: StatsActions.SET_STATS,
      payload: {
        usersCount: usersCount + 1,
      },
    });
  } catch (E) {
    const error = E.response ? E.response.data : E;
    const message = error.message || E.message;
    if (message.name === "PhoneAlreadyUsed") {
      action.sideEffectError("phone", "prone_text_error");
    }
  }
}

function* edit(action: IActionWithSingleSideEffect) {
  try {
    const {
      auth: {
        user: { company },
      },
    } = yield select();

    const item = yield service.edit(
      company,
      action.payload.id,
      normalizeFilters(action.payload.data, { preventFalsable: false })
    );
    yield put({
      type: UsersActions.UPDATE_ITEM,
      payload: item,
    });
    yield action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}

function* remove(action: IActionWithSingleSideEffect) {
  try {
    const {
      auth: {
        user: { company },
      },
      stats: { usersCount },
    } = yield select();

    yield service.remove(company, action.payload.id, action.payload.owner);
    yield action.sideEffect();
    yield put({
      type: StatsActions.SET_STATS,
      payload: {
        usersCount: usersCount - 1,
      },
    });
  } catch (E) {
    console.log(E);
  }
}

function* sendSmsVerification(action: IActionWithSingleSideEffect) {
  try {
    const {
      auth: {
        user: { company },
      },
    } = yield select();
    yield service.sendSmsVerificationLink(company, action.payload.id);
    yield put({
      type: UsersActions.UPDATE_ITEM,
      payload: {
        lastSmsSentTime: new Date(),
      },
    });
  } catch (e) {
    notify.error("Ошибка отправки СМС. Возможно неверный номер");
  }
}

export function* rootUsersSaga() {
  yield all([
    yield takeLatest(UsersActions.GET_ITEMS, getItems),
    yield takeLatest(UsersActions.GET_ITEMS_MOBILE, getItemsMobile),
    yield takeLatest(UsersActions.GET_ITEM, getItem),
    yield takeLatest(UsersActions.CREATE_ITEM, create),
    yield takeLatest(UsersActions.REMOVE_ITEM, remove),
    yield takeLatest(UsersActions.EDIT_ITEM, edit),
    yield takeLatest(UsersActions.GET_ITEM_TXS, getItemTransactions),
    yield takeLatest(UsersActions.GET_ITEM_TRANSFERS, getItemTransfers),
    yield takeLatest(UsersActions.SEND_SMS_VERIFICATION, sendSmsVerification),
  ]);
}
