import React, {useCallback, useEffect, useState} from "react";
import {Button, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import usePayment from "@featured/pages/Payment/PlanPaymentForm/hooks/usePayment";
import PaymentPeriod from "@featured/pages/Payment/PlanPaymentForm/Period";
import PaymentPlans from "./Plans";
import PermissionsList from "@featured/pages/Payment/PlanPaymentForm/Permissions";
import classNames from "classnames";
import useModal from "@core/hooks/useModal";
import Modal from "@shared/Common/Modal";
import Select from "@shared/Common/Form/Select/Select";
import useSelect from "@core/hooks/useSelect";
import {useTranslation} from "react-i18next";
import Service from '@core/services/payment';
import notify from "@core/utils/notify";
import {createEvent} from "@shared/Common/GAEvent";
import {IPlan} from "@core/types";
import {img, imgLocal} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '50px 0'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  period: {
    width: '30%',
    marginBottom: 40,
    paddingLeft: 0,
    [theme.breakpoints.down(1440)]: {
      paddingLeft: 0,
      paddingRight: 10,
      marginTop: 0,
    },
    [theme.breakpoints.down(1070)]: {
      width: '100%',
    }
  },
  plans: {
    width: '100%',
  },
  announcement: {
    padding: 20,
    '& img': {
      maxWidth: '100%',
    },
    '& .text-default': {
      fontSize: 20,
      marginTop: 20,
    },
    '& .subtitle': {
      marginTop: 20
    }
  }
}))


const Payment = () => {
  const classes = useStyles();
  const {
    company,
    plans,
    toggleDuration,
    duration,
    createPayment,
    getPlanUpdateType,
  } = usePayment();
  const [notifyViewed, setNotifyViewed] = useState(false);

  const modal = useModal();
  const notificationSubscriptionModal = useModal(() => {
    setNotifyViewed(true);
  });
  useEffect(() => {
    if(!notifyViewed && duration === 1) {
      notificationSubscriptionModal.handleOpen();
    }
  }, [duration, notifyViewed, notificationSubscriptionModal]);
  const {t} = useTranslation();

  const select = useSelect({
    label: t('payment_plan')
  })


  const handleBuy = useCallback((plan: IPlan, promo: string = '') => {
    if(company.plan.title === 'free') {
      createPayment(plan._id, promo);
      createEvent({
        category: "Payment",
        action: `PaymentBuy${plan.title.toUpperCase()}`
      })
    } else {
      const type = getPlanUpdateType(plan);
      createEvent({
        category: "Payment",
        action: `Payment${type}`
      })
      select.setValue(plan._id);
      modal.handleOpen();
    }
  }, [company, select]);

  const handleSendRequest = useCallback(async () => {
    try {
      await Service.updatePlan(select.value);
      modal.handleClose();
      notify.success('Успешно отправлено')
    } catch (e) {
      notify.success('Ошибка отправки')
    }
  }, [modal, select])

  return (
    <div className={classNames("container", classes.root)}>
      <div className={classes.wrapper}>
        <div className={classes.period}>
          <PaymentPeriod duration={duration} handleToggle={toggleDuration}/>
        </div>
        <div className={classes.plans}>
          {company && plans &&
            <PaymentPlans
              currentPlan={company.plan}
              duration={duration}
              plans={plans}
              handleBuy={handleBuy}
              promo={company.settings.promo  || {code: '', sale: duration === 12 ? 10 : 0}}
            />
          }
        </div>
      </div>
      <PermissionsList plans={plans} />
      <Modal {...modal} title={t('update_plan_request')} btnSubmit={t('send_message')} handleSubmit={handleSendRequest}>
        <p className="subtitle">{t('update_plan_request_desc')}</p>
        <Select {...select} data={plans.map(item => ({label: item.title, value: item._id}))}/>
      </Modal>
      <Modal
        {...notificationSubscriptionModal}
        cancelAction={false}
        btnSubmit={t('continue')}
        handleSubmit={notificationSubscriptionModal.handleClose}
      >
        <div className={classes.announcement}>
          <img src={imgLocal('announcement.svg')} alt="" />
          <h2 className="text-default">Ура! Наконец-то подписки в Eqman</h2>
          <p className="subtitle">Теперь выбрав оплату за месяц вы можете включить автоматические списания и больше не прийдется переживать о постоянном продлении тарфиного плана</p>
        </div>
      </Modal>
    </div>
  )
}

export default Payment;