import React, {FormEvent, useCallback, useState} from "react";
import useInput from "@core/hooks/useInput";
import {Paper, Button, Theme, Icon, CircularProgress} from "@material-ui/core";
import Input from "@shared/Common/Form/Input";
import {makeStyles} from "@material-ui/styles";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet'
import {GoogleIcon} from "@shared/Icons/svg";
import GoogleLogin from "react-google-login";
import {AuthAction} from "@core/store/actions/auth";
import {useDispatch} from "redux-react-hook";
import AuthLayout from "../../layout/Auth";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import Service from '@core/services/auth'
import notify from "@core/utils/notify";
import {validate} from "@core/utils/form";
import GAEvent from "@shared/Common/GAEvent";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      width: '100%',
      padding: '20px'
    }
  },
  description: {
    padding: '10px 0px'
  },
  googleBtn: {
    width: '100%',
    padding: '10px 0px',
    marginTop: '10px',
    backgroundColor: "#4485f4",
    color: "#fff",
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
    '&:hover': {
      backgroundColor: "#4778cb",
    }
  }
}));

const Login = ({history}: RouteComponentProps<any>) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [accountError, setAccountError] = useState<string>();
  const [authToken, setAuthToken] = useState<any>();
  const [authTypeError, setAuthTypeError] = useState<string>('')
  const [isLoginClicked , setIsLoginClicked] = useState(false);
  const token = localStorage.getItem('token');
  if(token) {
    history.push('/')
  }

  const login = useInput({
    type: 'text',
    label: t('login'),
    required: true,
    validate: (value: string) => {
      return value.indexOf(' ') !== -1
    }
  });

  const password = useInput({
    type: 'password',
    label: t('password'),
    required: true
  });

  const onLoginError = useCallback((type: 'email' | 'password' | 'account' | 'auth_type', error: string, token?: string) => {
    setIsLoginClicked(false);
    switch (type) {
      case "email":
        login.setError(true);
        login.setHelperText(error);
        break
      case "password":
        password.setError(true);
        password.setHelperText(t('wrong_password'));
        break;
      case "account":
        setAccountError(error);
        setAuthToken(token);
        break;
      case "auth_type":
        setAuthTypeError('auth_type_default_error');
        break;
      default:
        notify.error('Ошибка входа. Обратитесь в поддержку')
    }
  }, [password, login, t]);

  const handleLogin = useCallback((e: FormEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoginClicked(true);
    const valid = validate({login, password});
    if(!valid) return;
    if(!login.value || !password.value) return;
    dispatch({
      type: AuthAction.LOGIN,
      payload: {
        login: login.value,
        password: password.value,
      },
      sideEffectError: onLoginError,
      sideEffectSuccess: () => {
        history.push('/')
      }
    });
  }, [password, login, dispatch, history, onLoginError]);

  const startVerification = useCallback(() => {
    dispatch({
      type: AuthAction.SEND_EMAIL,
      payload: {
        token: authToken
      },
      sideEffect: () => {
        localStorage.removeItem('auth');
        localStorage.setItem('auth', authToken);
        history.push('/auth/verification');
      }
    })
  }, [authToken, history, dispatch]);

  const googleLogin  = useCallback(async (data: any) => {
    try {
      const {token} = await Service.googleLogin(data.tokenId);
      Service.setUserToken(token);
      history.push('/');
    } catch(E) {
      const errorName = E.response ? E.response.data.message.name : '';
      if(errorName === 'AuthTypeError') {
        setAuthTypeError('auth_type_google_error');
      }
      if(errorName === 'UserNotFound') {
        onLoginError('account', t('user_not_found'));
      }
      notify.error(t('login_error'))
    }

  }, [history, t]);

  return (
    <AuthLayout>
      <div className={classes.root}>
        <Helmet>
          <title>Eqman - Sign in</title>
        </Helmet>
        <h2 style={{fontWeight: 700}}>{t('sign_in')}</h2>
        {authTypeError && <Alert severity="error">{t(authTypeError)}</Alert>}
        <form onSubmit={handleLogin} noValidate>
          <Input {...login} />
          <Input {...password} />
          {accountError && <Alert severity="warning" action={(<Link to="/auth/register"><Button>Регистрация</Button></Link>)}>{accountError}</Alert>}
          {!isLoginClicked && <GAEvent category="LogIn" action="LogIn">
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="secondary"
              type="submit"
              style={{marginTop: 10}}
            >{t('sign_in')}</Button>
          </GAEvent>}
        </form>
        {isLoginClicked && <div style={{margin: 10, textAlign: 'center'}}>
          <CircularProgress color="secondary" size={25}/>
        </div>}
        {!isLoginClicked && <>
          <div style={{textAlign: "center", margin: '15px 0', fontWeight: 500, fontSize: 14}}>{t('or')}</div>
          <GoogleLogin
            clientId="753194651551-82mcaoubsptas0qu95bo4as4mp8oj23g.apps.googleusercontent.com"
            buttonText="Auth with Login"
            onSuccess={googleLogin}
            onFailure={(error: any) => {console.log(error)}}
            cookiePolicy={'single_host_origin'}
            render={(renderProps: any) => (
              <GAEvent category="LogIn" action="LogInG">
                <Button
                  className={classes.googleBtn}
                  onClick={renderProps.onClick}
                  style={{margin: 0}}
                  startIcon={ <Icon style={{
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    display: 'block',
                    padding: "5px",
                    width: '30px',
                    height: '30px',
                    lineHeight: 0.9
                  }}>{GoogleIcon}</Icon>}
                >
                  {t('sign_in_google')}
                </Button>
              </GAEvent>
            )}
          />
        </>}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px'}}>
          <span>{t('forgot_password')}</span>
          <GAEvent category="LogIn" action="LogInRestorePass">
            <Link to="/auth/forgot_password" className="link">
              <span className="link green underline" style={{marginLeft: 10}}>Востановить</span>
            </Link>
          </GAEvent>
        </div>
        <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 5}}>
          <span>{t('dont_have_account')}</span>
          <GAEvent category="LogIn" action="LogInReg">
            <Link to='/auth/register'>
              <span className="link green underline" style={{marginLeft: 10}}>{t('sign_up')}</span>
            </Link>
          </GAEvent>
        </div>
      </div>
    </AuthLayout>
  )
};

export default withRouter(Login);
