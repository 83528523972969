import * as React from 'react'
import {Icon, IconButton, Slide, Snackbar} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from "classnames";
import {isMobile} from 'react-device-detect'
import SnackbarMessage from "@shared/Common/Snackbar/SnackbarMessage";

const useStyles = makeStyles({
  success: {
    backgroundColor:"#42cd8c",
    color: '#fff !important'
  },
  error: {
    backgroundColor: '#ee625d !important',
    color: '#fff'
  },
  info: {
    backgroundColor: '#eea040 !important',
    color: '#fff'
  },
  warning: {
    backgroundColor: '#ee625d !important',
    color: '#fff !important'
  },
  notification: {
    backgroundColor: '#ffffff',
    color: '#343434'
  },
  default: {
    backgroundColor: '#959595 !important',
    color: '#fff'
  },
  message: {
    fontSize: 14,
    fontWeight: 500
  },
})
function SlideTransition(props: any) {
  return <Slide {...props} direction="left" />;
}

export interface ISnackbarMessage {
  details: any,
  message: string,
  type: string,
  handleClose: () => void,
}

interface ISnackbarProps extends ISnackbarMessage {
  open: boolean,
}
const SnackbarCustom = (props: ISnackbarProps) => {
  const { open, handleClose, type, details = {}, message } = props;

  const classes = useStyles();
  const getType = React.useCallback(
    type => {
      switch (type) {
        case 'success':
          return classes.success
        case 'warning':
          return classes.warning
        case 'notification':
          return classes.notification
        case 'info':
          return classes.info
        case 'error':
          return classes.error
        default:
          return classes.default
      }
    },
    [classes]
  )
  return (
    <Snackbar
      key={props.message}
      anchorOrigin={!isMobile ? {
        vertical: 'top',
        horizontal: 'right',
      }: {
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={type !== 'notification' ? details.duration || 3000 : null}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      ContentProps={{
        'aria-describedby': 'message-id',
        'className': classNames(classes.message, getType(props.type))
      }}
      message={<SnackbarMessage details={details} message={message} type={type} handleClose={handleClose}/>}
      action={[
        type !== 'notification' && <IconButton key="undo" onClick={handleClose}>
          <Icon className={getType(props.type)}>close</Icon>
        </IconButton>
      ]}
    />
  )
}
export default SnackbarCustom;
