import React, {useCallback} from "react";
import {IMessageStore} from "@core/types";
import {IconButton, Icon, Badge, makeStyles, Theme} from "@material-ui/core";
import useDropdown from "@core/hooks/useDropdown";
import Popover from "@material-ui/core/Popover";
import Notification from './Notification';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

interface INotificationProps {
  data: IMessageStore[],
  onRead: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '400px',
    [theme.breakpoints.down(400)]: {
      maxWidth: '100%'
    }
  },
  listTitle: {
    padding: '15px 20px',
    borderBottom: '1px solid #f4f4f4',
    color: "#767676",
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500
  },
  noNotifications: {
    width: 400,
    textAlign: 'center',
    padding: '20px 0'
  }
}))

const Notifications = ({data = [], onRead}: INotificationProps) => {
  const classes = useStyles();
  const popover = useDropdown();
  const {t} = useTranslation();
  const history = useHistory();

  const unread = data.reduce((acc, item) => {
    return acc + (!item.read ? 1 : 0)
  }, 0)

  const handleOpenPopover = useCallback((event: React.MouseEvent) => {
    popover.handleOpen(event);
    onRead();
  }, [popover]);

  const onMessageClick = useCallback((message: IMessageStore) => {
    if(message.link) {
      history.push(message.link);
      popover.handleClose();
    }
  }, [history, popover]);

  return (
    <div>
      <IconButton onClick={handleOpenPopover}>
        <Badge badgeContent={unread || null} color="secondary">
          <Icon style={{color: '#fff'}}>notifications</Icon>
        </Badge>
      </IconButton>
      <Popover
        open={popover.open}
        anchorEl={popover.anchorEl}
        disableScrollLock
        onClose={popover.handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <div className={classes.root}>
          <div className={classes.listTitle}>{t('notifications')}</div>
          {
            data.length > 0 && data.map((notification) => (
              <Notification message={notification} onClick={onMessageClick}/>
            ))
          }
          {
            data.length === 0 && (
              <div className={classes.noNotifications}>
                <Icon>notifications_off</Icon>
                <h2 className="text-default">{t('no_notifications')}</h2>
              </div>
            )
          }
        </div>
      </Popover>
    </div>
  )
}

export default Notifications;