import {ChangeEvent, useCallback, useState} from "react";


const useSelect = (props: any) => {
  const {onChangeHook} = props;
  const [value, setValue] = useState<any>(props.defaultValue);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<any>(props.helperText || '');

  const handleChange = useCallback((e: ChangeEvent<{ name?: string; value: unknown }>) => {
    if(onChangeHook) {
      onChangeHook(e.target.value)
    }
    setError(false);
    setHelperText('')
    setValue(e.target.value);
  }, [onChangeHook]);

  return {
    value,
    handleChange,
    label: props.label,
    name: props.name || props.label,
    data: props.data || [],
    error,
    setError,
    setValue,
    helperText,
    setHelperText,
    ...props
  }
}

export default useSelect;
