import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {ButtonBase, Paper, Theme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

interface IPeriodDurationProps {
  duration: 1 | 12,
  handleToggle: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& h3': {
      marginBottom: 15,
      color: "#414b5c",
      fontWeight: 500
    }
  },
  buttonRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '50px',
    position: 'relative',
    backgroundColor: "#fff",
    width: '80%',
    boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
    [theme.breakpoints.down(1440)]: {
      width: '100%'
    }
  },
  item: {
    zIndex: 1,
    fontSize: 16,
    fontWeight: 500,
    width: '50%',
    textAlign: "center",
    padding: '15px 20px',
    color: theme.palette.text.primary
  },
  activeItem: {
    color: "#fff"
  },
  pointer: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    borderRadius: 50,
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    transition: 'left 0.3s ease',
    zIndex: 0
  },
  hint: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    position: 'absolute',
    right: '35px',
    top: '100%',
    marginTop: 10,
    fontWeight: 500
  }
}))

const PaymentPeriod = (props: IPeriodDurationProps) => {
  const {duration, handleToggle} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      <h3>{t('payment_type')}</h3>
      <ButtonBase onClick={handleToggle} className={classes.buttonRoot}>
        <div className={classNames(classes.item, {[classes.activeItem]: duration === 1})}>{t('payment_duration_month')}</div>
        <div className={classNames(classes.item, {[classes.activeItem]: duration === 12})}>{t('payment_duration_year')}</div>
        <div className={classes.pointer} style={{left: duration === 1 ? 0 : '50%'}}></div>
        <span className={classes.hint}>{t('payment_year_sale')}</span>
      </ButtonBase>
    </div>
  )
}

export default PaymentPeriod;