import React, { useCallback } from "react";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import {
  Button,
  Icon,
  LinearProgress,
  Theme,
  Toolbar,
  withStyles,
  Breadcrumbs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import AccentButton from "@shared/Common/Button/AccentButton";
import useTutorial from "@core/hooks/useTutorial";
import GAEvent from "@shared/Common/GAEvent";
import Notifications from "@shared/Common/Topbar/Notifications";
import { NotificationActions } from "@core/store/actions/notification";
import Helmet from "react-helmet";
import { LangSettings } from "./LangSettings";

const Progress = withStyles((theme: Theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#0000002b",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#fb8825",
  },
}))(LinearProgress);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: 50,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${57}px)`,
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(
      ["width", "margin", "backgroundColor"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }
    ),
  },
  menuButton: {
    marginRight: 10,
  },
  topActions: {
    flexGrow: 1,
    textAlign: "right",
  },
  lang: {
    color: "#fff",
  },
  progress: {
    margin: "15px 0",
    width: 320,
  },
  progressLabel: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 600,
    color: "#fff",
  },
  counter: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  btn: {
    marginLeft: 20,
  },
  actionsToolbar: {
    // backgroundColor: theme.palette.secondary.main
    borderTop: `1px solid ${theme.palette.primary.light}`,
    height: "auto",
    minHeight: "auto",
  },
}));

const ToolbarCustom = (props: any) => {
  const { open, handleDrawerToggle, breadcrumbs = [], actions } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const { company, itemsCount, notifications } = useMappedState((state) => ({
    ...state.stats,
    notifications: state.notifications,
  }));
  const { start, activate } = useTutorial();

  const getPercent = useCallback(
    (type: "users" | "items", value: number) => {
      return Math.ceil((value * 100) / company.plan[type]);
    },
    [company]
  );

  const runTutorial = useCallback(() => {
    activate();
    start();
  }, [activate, start]);

  const onNotificationsRead = useCallback(() => {
    dispatch({
      type: NotificationActions.MAKE_REED,
    });
  }, [dispatch]);

  return (
    <AppBar
      position="fixed"
      elevation={1}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <GAEvent category="Header" action="HeaderCutDownLeftSideMenu">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <Icon>menu</Icon>
          </IconButton>
        </GAEvent>
        <Breadcrumbs style={{ color: "#fff" }}>
          {breadcrumbs.map((b: any) => (
            <Link to="" key={b}>
              <h1 className="title">{b}</h1>
            </Link>
          ))}
        </Breadcrumbs>
        {company && (
          <div className={classes.counter}>
            <div className={classes.progress} id="plan_counters">
              <div className={classes.progressLabel}>
                <GAEvent category="Header" action="HeaderCounterAssetsLink">
                  <Link to="/items">
                    <span>{t("items")}</span>
                  </Link>
                </GAEvent>
                <b>
                  {itemsCount} / {company.plan.items}
                </b>
              </div>
              <Progress
                variant="determinate"
                value={getPercent("items", itemsCount)}
              />
            </div>
            <GAEvent category="Header" action="HeaderCounterBuy">
              <Link to="/payment">
                <AccentButton
                  variant="contained"
                  id="sixth-step"
                  className={classes.btn}
                  size="large"
                >
                  {t("buy_service")}
                </AccentButton>
              </Link>
            </GAEvent>
            <div
              style={{
                padding: "0 10px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Notifications
                data={notifications.data}
                onRead={onNotificationsRead}
              />
              {location.pathname === "/items" && (
                <IconButton onClick={runTutorial}>
                  <Icon style={{ color: "#fff" }}>help</Icon>
                </IconButton>
              )}
              <LangSettings />
            </div>
          </div>
        )}
      </Toolbar>
      {actions && (
        <Toolbar className={classes.actionsToolbar}>{actions}</Toolbar>
      )}
    </AppBar>
  );
};

export default ToolbarCustom;
