import {rules} from "@core/utils/validation";
import {IInputComponentProps} from "@core/hooks/useInput";

export const isFormSubmitDisabled = (formFields: any = {}) => {
  for(let key of Object.keys(formFields)) {
    const field = formFields[key];
    if(!field.value) return true;
    if(field.error) return true;
  }
  return false;
};

export const oneOfFormFieldsFilled = (formFields: any = {}) => {
  for(let key of Object.keys(formFields)) {
    const field = formFields[key];
    if(field.value && !field.error) return false;
  }
  return true;
};


export const validate = (fields: { [key: string]: IInputComponentProps | any }) => {
  let valid = true;
  for(let key of Object.keys(fields)) {
    const field = fields[key];
    if(field.error) {valid = false}

    if(!field.required && !field.rule) continue;

    if(field.required && (!field.value || (typeof field.value === 'object' && !field.value.value))) {
      field.setError(true);
      valid = false;
      field.setHelperText('required_field');
    }
    if(field.rule && rules[field.rule](field.value)) {
      field.setError(true);
      valid = false;
      field.setHelperText(`invalid_rule_${field.rule}`);
    }
  }

  return valid;
}