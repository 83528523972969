import React, {useCallback, useEffect} from "react";
import {Fab, Grid, Icon, IconButton, Theme, Toolbar, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import useInput from "@core/hooks/useInput";
import {useDispatch, useMappedState} from "redux-react-hook";
import {useTranslation} from "react-i18next";
import Input from "@shared/Common/Form/Input";
import PhoneInput from "@shared/Common/Form/PhoneInput";
import usePhoneInput from "@core/hooks/usePhoneInput";
import {validate} from "@core/utils/form";
import {ItemsActions} from "@core/store/actions/items";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {CompanyActions} from "@core/store/actions/company";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 50,
    right: 50,
    width: 56,
    height: 56,
    borderRadius: '50%',
    transition: '0.2s all',
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1299,
    lineHeight: 0,
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);',
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden'
    }
  },
  opened: {
    width: 600,
    backgroundColor: '#fff',
    height: 500,
    borderRadius: 5,
    transition: '0.3s all',
    [theme.breakpoints.down(700)]: {
      bottom: 0,
      right: 0,
      width: '100%'
    },
    [theme.breakpoints.down(400)]: {
      bottom: 0,
      right: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  },
  topbar: {
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'space-between',
    color: "#fff",
    '& h2': {
      fontSize: 14,
      fontWeight: 500
    }
  },
  form: {
    padding: 20
  },
  input: {
    margin: 0,
    minWidth: '100%'
  }
}))

interface ISupportProps extends RouteComponentProps {
  control: any
}

const Support = ({control, location}: ISupportProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const user = useMappedState((state) => state.auth.user)
  const dispatch = useDispatch();

  const name = useInput({
    label: t('firstName'),
    defaultValue: user.firstName
  });
  const email = useInput({
    label: t('email'),
    defaultValue: user.email
  });
  const phone = usePhoneInput({
    label: t('phone'),
    defaultValue: user.phone,
    required: true
  });
  const message = useInput({
    label: t("your_message")
  });

  const handleSend = useCallback(() => {
    const isValid = validate({phone});
    if(!isValid) return;
    dispatch({
      type: CompanyActions.SEND_SUPPORT,
      payload: {
        name: name.value,
        email: email.value,
        phone: phone.getFullValue(),
        message: message.value,
        location: location.pathname,
        user
      }
    });
    control.handleClose();
  }, [name, email, phone, message, location, user]);

  return (
    <div className={classNames(classes.root, {
      [classes.opened] : control.open
    })}>
      {!control.open && <Fab color="secondary" aria-label="add" onClick={control.handleToggle}>
        <Icon>help</Icon>
      </Fab>}
      {
        control.open && <div>
          <Toolbar className={classes.topbar}>
            <h2>Contact us</h2>
            <IconButton onClick={control.handleClose}><Icon style={{color: '#fff'}}>close</Icon></IconButton>
          </Toolbar>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}><Input {...name} className={classes.input}/></Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}><Input {...email} className={classes.input}/></Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}><PhoneInput input={phone} className={classes.input}/></Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Input {...message} multiline rows={5} className={classes.input}/>
              </Grid>
              <Grid item lg={12}>
                <Button variant="contained" color="secondary" onClick={handleSend}>{t('send_message')}</Button>
              </Grid>
            </Grid>
          </div>
        </div>
      }
    </div>
  );
}

export default withRouter(Support);