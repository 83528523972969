import { all, takeLatest, put, select, call } from "redux-saga/effects";
import {
  IAction,
  IActionWithErrorAndSuccess,
  IActionWithSingleSideEffect,
} from "../actions";
import service from "./../../services/auth";
import countryApi from "../../services/global";
import { AuthAction } from "../actions/auth";
import { SettingsActions } from "../actions/settings";
import notify from "../../utils/notify";
import { StatsActions } from "../actions/stats";
import { loginTypeDetection } from "@core/utils/string";
import { SagaIterator } from "redux-saga";

function* login(action: IActionWithErrorAndSuccess) {
  try {
    const { token } = yield service.login(action.payload);
    yield service.setUserToken(token);
    yield action.sideEffectSuccess();
  } catch (E) {
    let loginError;
    const error = (E as any).response ? (E as any).response.data : E;
    const message = error.message || (E as any).message;

    if (loginTypeDetection(action.payload.login) === "email") {
      loginError = "Пользователь с таким email не найден";
    } else {
      loginError = "Пользователь с таким номером телефона не найден";
    }
    if (message.name === "AuthTypeError") {
      action.sideEffectError("auth_type", "");
    }
    if (message.name === "EmailUnApproved") {
      action.sideEffectError(
        "email",
        "Этот email не был подтвержден используйте номер телефона для авторизации"
      );
    }
    if (message.name === "PhoneUnApproved") {
      action.sideEffectError(
        "email",
        "Этот номер телефона не был подтвержден используйте email для авторизации"
      );
    }
    if (message.name === "UserNotFound") {
      action.sideEffectError("email", loginError);
    }
    if (message.name === "PasswordIncorrect") {
      action.sideEffectError("password", message.response);
    }
    if (message.name === "AccountNotVerified") {
      action.sideEffectError(
        "account",
        "Ваш аккаунт ещё не прошел верификацию",
        message.response
      );
    }
    console.log(E);
  }
}

function* register(action: IActionWithErrorAndSuccess): SagaIterator {
  try {
    const { countryCode } = action.payload;
    const country = yield call(countryApi.getCountryByCode, countryCode);

    const { user, token } = yield call(service.register, {
      ...action.payload,
      country: country ? country.name.common : countryCode,
    });
    yield call(action.sideEffectSuccess, token);
  } catch (E) {
    const error = (E as any).response ? (E as any).response.data : E;
    const message = error.message || (E as any).message;
    if (message.name === "PhoneAlreadyUsed") {
      action.sideEffectError("phone", "Этот номер телефона уже используется");
    }
  }
}

function* getCurrent(action: IAction): SagaIterator {
  try {
    const user = yield call(service.me);
    yield put({
      type: AuthAction.SET_USER,
      payload: {
        user,
        token: action.payload.token,
      },
    });

    if (user.role !== "user") {
      const settings = yield call(service.settings, user.company);
      yield put({
        type: SettingsActions.SET_SETTINGS,
        payload: {
          company: settings,
        },
      });
      yield put({
        type: StatsActions.GET_STATS,
      });
    }
  } catch (E) {
    console.log(E);
  }
}

function* finishRegistration(action: IActionWithSingleSideEffect) {
  try {
    yield service.finishRegistration(
      action.payload.token,
      action.payload.password
    );
    action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}

function* changePassword(action: IActionWithErrorAndSuccess) {
  try {
    yield service.changePassword(action.payload);
    yield action.sideEffectSuccess();
  } catch (E) {
    yield action.sideEffectError();
  }
}

function* editSettings(action: IActionWithSingleSideEffect) {
  try {
    const {
      auth: {
        user: { company },
      },
    } = yield select();
    yield service.editSettings(company, action.payload);
    yield put({
      type: SettingsActions.SET_SETTINGS,
      payload: {
        company: action.payload,
      },
    });
    yield action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}

function* sendEmailVerification(
  action: IActionWithSingleSideEffect<string, { id: string }>
) {
  try {
    yield service.sendEmailVerification(action.payload.id);
    yield action.sideEffect();
  } catch (E) {
    notify.error("Ошибка отправки email");
  }
}

function* sendSmsVerification(action: IActionWithSingleSideEffect) {
  try {
    yield service.verifyPhone(action.payload.token, action.payload.phone);
    yield action.sideEffect();
  } catch (E) {
    notify.error("Ошибка отправки СМС");
  }
}

function* verifyPhone(action: IActionWithSingleSideEffect) {
  try {
    yield service.checkVerificationCode(
      action.payload.token,
      action.payload.code
    );
    yield action.sideEffect();
  } catch (e) {
    notify.error("Неверный код");
  }
}

function* setFirebaseToken(
  action: IActionWithSingleSideEffect<string, { token: string }>
) {
  try {
    yield service.setFirebaseToken(action.payload.token);
  } catch (e) {
    notify.warning("Ошибка подключения к сервису уведомлений");
  }
}

export function* rootAuthSaga(): SagaIterator {
  yield all([
    yield takeLatest(AuthAction.LOGIN, login),
    yield takeLatest(AuthAction.REGISTER, register),
    yield takeLatest(AuthAction.GET_CURRENT_USER, getCurrent),
    yield takeLatest(AuthAction.FINISH_REGISTRATION, finishRegistration),
    yield takeLatest(AuthAction.CHANGE_PASSWORD, changePassword),
    yield takeLatest(AuthAction.EDIT_SETTINGS, editSettings),
    yield takeLatest(AuthAction.SEND_SMS, sendSmsVerification),
    yield takeLatest(AuthAction.VERIFY_PHONE, verifyPhone),
    yield takeLatest(AuthAction.SEND_EMAIL, sendEmailVerification),
    yield takeLatest(AuthAction.SET_FIREBASE_TOKEN, setFirebaseToken),
  ]);
}
