import * as React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const primaryFont = ['Montserrat', 'sans-serif'].join(',');

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        '& fieldset': {
          // borderColor: 'rgba(0,0,0,0.15)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0,0,0,0.05)',
        }
      },
      notchedOutline: {
        borderColor: 'rgba(0,0,0,0.18)',
      },
    },
    MuiFormLabel: {
      colorSecondary: {
        color: "rgba(65,75,92,0.65)"
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#f4f4f4',
        borderRadius: 4,
        fontSize: 14,
        '&:hover, &.Mui-focused': {
          backgroundColor: '#f4f4f4',
        },
        '&.Mui-disabled': {
          backgroundColor: '#f4f4f4',
        },
        '&:before': {
          borderBottom: '1px solid transparent !important',
        },
      },
      colorSecondary: {
        backgroundColor: '#eaf1f5',
        borderRadius: 4,
        fontSize: 14,
        '&:hover, &.Mui-focused': {
          backgroundColor: '#eaf1f5',
        },
        '&.Mui-disabled': {
          backgroundColor: '#f6f6f6',
        },
        '&:before': {
          borderBottom: '1px solid transparent !important',
        },

      },
      // colorSecondary: {
      //   backgroundColor: 'rgba(0, 0, 0, 0.09)',
      //   color: '#fff',

      //   '&.MuiFilledInput-underline:after': {
      //     borderBottomColor: '#fff'
      //   },
      //   '&:hover': {
      //     backgroundColor: 'rgba(0,0,0,0.13)',
      //   }
      // },
    },
    MuiPaper: {
      root: {
        boxShadow:'0 0 13px 0 rgba(82,63,105,.1)',
      },
      elevation1: {
        boxShadow:'0 0 13px 0 rgba(82,63,105,.1)',
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
      },
      secondary: {
        fontSize: 10
      },
      root: {
        marginTop: 0,
        marginBottom: 0,
        '&.small span': {
          fontSize: 12,
          fontWeight: 500,
        }
      }
    },
    MuiTable: {
      root: {
        borderRadius: 3,
        fontSize: 14
      },
    },
    MuiTableHead: {
      root: {
        fontSize: 14
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        borderBottom: '1px solid #f4f4f4'
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiTablePagination: {
      caption: {
        marginBottom: 5,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#f3f3f3'
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 14
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none'
      },
      root: {
        '&.menu .MuiPaper-root': {
          height: '100vh',
          backgroundColor: '#414b5c',
          width: '300px'
        }
      },
    },
    MuiButton: {
      text: {
      },
      textSecondary: {
        '&.action': {
          color: '#9ce7d4',
          textTransform: 'capitalize',
          fontWeight: 500,
          margin: '0 5px',
          '&.active': {
            backgroundColor: '#42cda1',
            color: '#fff'
          }
        }
      }
    },
    MuiChip: {
      sizeSmall: {
        fontSize: 12,
        height: 28
      },
      root: {
        boxShadow:'0 0 13px 0 rgba(82,63,105,.3)',
        backgroundColor: "#cbcbcb",
        '&.green': {
          backgroundColor: "#45cf58",
          color: '#fff'
        },
        '&.purple': {
          backgroundColor: "#976bcc",
          color: '#fff'
        },
        '&.red': {
          backgroundColor: "#e86357",
          color: '#fff'
        },
        '&.blue': {
          backgroundColor: "#5a95f7",
          color: '#fff'
        },
        '&.orange': {
          backgroundColor: "#ff8d00",
          color: '#fff'
        }
      }
    }
  },
  palette: {
    primary: {
      dark: "#1f2632",
      main: '#414b5c',
      light: '#545f72',
      contrastText: '#fafafa'
    },
    secondary: {
      dark: "#236d56",
      main: '#0ab883',
      light: '#4eb89c',
      contrastText: '#fff',
    },
    error: {
      dark: "#976bcc",
      main: '#ff7f70',
      contrastText: '#333'
    },
    text: {
      primary: '#1c2c42',
      secondary: '#5c5e6b',
    }
  },
  typography: {
    fontFamily: primaryFont,
  },
})

const withTheme = (Component: React.FC) => {
  return (
    class withTheme extends React.Component {
      render() {
        return (
          <MuiThemeProvider theme={theme}>
            <Component {...this.props}/>
          </MuiThemeProvider>
        )
      }
    }
  )
}

export default withTheme
