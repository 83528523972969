import React from "react";
import {makeStyles} from "@material-ui/styles";
import {Grid, Theme} from "@material-ui/core";
import Card from "@shared/Common/Card";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: 'flex',
    justifyContent: "center",
    flexDirection: 'column',
    minHeight: 550,
    '& h1': {
      fontWeight: 500,
      marginBottom: 20
    },
    '& p': {
      color: "#727272"
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 130px)',
    }
  },
  mainImg: {
    display: 'flex',
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
}))

const Lockpage = ({expired}: {expired: boolean}) => {
  const classes = useStyles();
  return (
    <Card>
      <Grid container>
        <Grid item lg={4} md={6} sm={12} xs={12} className={classes.info}>
          <h1>Доступ запрещён</h1>
          {expired && <p>К сожалению доступ к админ панели и мобильному приложению временно приостановлен, ввиду неоплаты тарифного плана на текущий месяц</p>}
          {!expired && <p>У вас нет доступа к системе платежей</p>}
        </Grid>
        <Grid item lg={1} md={1}></Grid>
        <Grid item lg={7} md={5} sm={12} className={classes.mainImg}>
          <img src={img('security.jpg')} alt="" style={{width: '100%'}}/>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Lockpage;