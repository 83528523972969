import React, {useEffect, useState} from "react";
import Card from "../../../shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import {Button, CircularProgress, Theme} from "@material-ui/core";
import {Link, RouteComponentProps} from "react-router-dom";
import Auth from '../../../core/services/auth';
import notify from "../../../core/utils/notify";
import {useTranslation} from "react-i18next";
import MainLayout from "@featured/layout/Main";
import {img} from "@core/utils/string";
import AuthLayout from "@featured/layout/Auth";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
    margin: '0 auto',
    textAlign: 'center'
  },
  actions: {
    marginTop: 50,
    textAlign: 'right'
  }
}));

const EmailApproved = (props: RouteComponentProps) => {
  const {location, history} = props;
  const {t} = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  if(Auth.isLoggedIn()) {
    history.push('/');
  }

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if(!token) {
      history.push('/');
      return
    }
    Auth.approveEmail(token).then(() => {
      setLoading(false);
    }).catch((E) => {
      notify.warning(t('link_unavailable'));
      history.push('/');
    })
  }, [location, history, t]);

  return (
    <AuthLayout>
      <Card className={classes.root}>
        {loading && <CircularProgress color="secondary"/>}
        {!loading && <>
          <img src={img('approved.svg')} alt="" style={{width: '60%', margin: '0 auto', marginBottom: 30}}/>
          <h2 className="text-default">{t('verify_done')}</h2>
          <p className="subtitle">{t('verify_desc')}</p>
          <div className={classes.actions}>
            <Link to="/auth/login">
              <Button color="secondary">
                {t('sign_in')}
              </Button>
            </Link>
          </div>
        </>}
      </Card>
    </AuthLayout>
  )
};
export default EmailApproved;