import {IAction} from "@core/store/actions";
import {PaymentActions} from "@core/store/actions/payment";

const initialState = {
  data: null,
  filtersOpen: false,
  filters: {
    search: '',
    status: {title: '', value: ''},
  },
  pagination: {
    limit: 10,
    offset: 0
  },
  total: 0,
  loading: false,
  item: null
}

const PaymentReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case PaymentActions.SET_ITEMS:
      if(action.payload.isMobile) {
        return {
          ...state,
          data: [...(state.data || []), ...action.payload.data],
          total: action.payload.total
        };
      }
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total
      };
    case PaymentActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        },
        pagination: initialState.pagination
      };
    case PaymentActions.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
        pagination: initialState.pagination
      };
    case PaymentActions.TOGGLE_FILTERS:
      return {
        ...state,
        filtersOpen: action.payload
      }
    case PaymentActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case PaymentActions.SET_ITEM:
      return {
        ...state,
        item: action.payload
      };
    case PaymentActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case PaymentActions.CLEAR_ITEM:
      return {
        ...state,
        item: null
      };
    case PaymentActions.CLEAR_ITEMS:
      return initialState;
    default:
      return {
        ...state
      }
  }
};

export default PaymentReducer;