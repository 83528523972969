import React, {useCallback, useEffect, useState} from "react";
import {Button, CircularProgress, Theme} from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import {img} from "@core/utils/string";
import Card from "@shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import {useDispatch} from "redux-react-hook";
import {StatsActions} from "@core/store/actions/stats";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '60%',
    margin: '0 auto'
  },
  img: {
    maxWidth: '80%',
    display: 'block',
    margin: '0 auto'
  },
  description: {
    textAlign: 'center',
    '& button': {
      marginTop: 30
    }
  },
  loader: {
    display: 'flex',
    justifyContent: "center"
  }
}))

const PaymentSuccess = (props: RouteComponentProps<any>) => {
  const {location} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState<boolean>();

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    setSuccess(params.get('response_status') === 'success' || !params.get('response_success'));
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [location.search])

  const handleContinue = useCallback(() => {
    dispatch({
      type: StatsActions.GET_STATS
    });
    props.history.push('/settings/payment')
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <img src={img('payment_illustration.jpg')} alt="" className={classes.img}/>
      {!loading && <div className={classes.description}>
        <h2>{t(`payment_response_title_${success ? 'success' : 'error'}`)}</h2>
        <p>{t(`payment_response_desc_${success ? 'success' : 'error'}`)}</p>
        <Button color="secondary" onClick={handleContinue}>{t('continue')}</Button>
      </div>}
      <div className={classes.loader}>
        {loading && <CircularProgress/>}
      </div>
    </Card>
  )
}

export default PaymentSuccess;