import React from "react";
import Card from "@shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import {Icon, Theme} from "@material-ui/core";
import {IPlan, Permissions, PermissionsSoon} from "@core/types";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    marginTop: 30,
    padding: '30px 0',
    [theme.breakpoints.down(670)]: {
      overflowX: 'scroll',
      padding: 0
    },
  },
  wrapper: {
    width: '100%',
    [theme.breakpoints.down(670)]: {
      width: 670
    },
  },
  item: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    padding: '15px 0',
    borderBottom: '1px solid #D6E2ED',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  title: {
    width: '30%',
    color: '#394252',
    paddingLeft: 30,
    [theme.breakpoints.down(670)]: {
      fontSize: 14,
      width: '40%',
    },
  },
  values: {
    width: '70%',
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    [theme.breakpoints.down(670)]: {
      fontSize: 14,
      width: '60%',
    },
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down(670)]: {
      fontSize: 14,
    },
  },
  headerValues: {
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'capitalize',
    [theme.breakpoints.down(670)]: {
      fontSize: 14,
    },
  },
  icon: {
    color: '#FB7C22',
    fontWeight: 600
  },
  popular: {
    color: theme.palette.secondary.main
  },
  disabled: {
    color: '#B8CCDF'
  },
  header: {
    display: 'flex',
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
  },
  unlim: {
    fontSize: 20
  }
}))

const PermissionsList = ({plans}: {plans: IPlan[]}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Card className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classNames(classes.item, classes.header)}>
          <div className={classNames(classes.title, classes.headerTitle)}>Функция</div>
          <div className={classNames(classes.values, classes.headerValues)}>
            {
              plans.map((plan) => (
                <div
                  style={{width: '65%', textAlign: 'center'}}
                >
                  {plan.title}
                </div>
              ))
            }
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.title}>{t(`items_quantity`)}</div>
          <div className={classes.values}>
            {
              plans.map((plan) => (
                <div
                  style={{width: '65%', textAlign: 'center'}}
                  className={
                    classNames(classes.icon, {
                      [classes.popular]: plan.popular
                    })
                  }
                >
                  {plan.items >= 100000 ? <span className={classes.unlim}>∞</span> : plan.items}
                </div>
              ))
            }
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.title}>{t('quantity')} {t('users')}</div>
          <div className={classes.values}>
            {
              plans.map((plan) => (
                <div
                  style={{width: '65%', textAlign: 'center'}}
                  className={
                    classNames(classes.icon, {
                      [classes.popular]: plan.popular,
                    })
                  }
                >
                  {plan.users >= 100000 ? <span className={classes.unlim}>∞</span> : plan.users}
                </div>
              ))
            }
          </div>
        </div>
        {
          Object.values(Permissions).map((permission: string) => (
            <div className={classes.item}>
              <div className={classes.title}>{t(`payment_${permission}`)}</div>
              <div className={classes.values}>
                {
                  plans.map((plan) => (
                    <div style={{width: '65%', textAlign: 'center'}}>
                      {plan.permissions.includes(permission) && PermissionsSoon.includes(permission) && (<span style={{fontWeight: 600, color: '#B8CCDF'}}>Soon</span>)}

                      {!(plan.permissions.includes(permission) && PermissionsSoon.includes(permission)) && <Icon
                        className={classNames(classes.icon, {
                          [classes.popular]: plan.popular,
                          [classes.disabled]: !plan.permissions.includes(permission)
                        })}
                      >
                        {plan.permissions.includes(permission) && !PermissionsSoon.includes(permission) && 'check'}
                        {!plan.permissions.includes(permission) && 'close'}
                      </Icon>}
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </Card>
  )
}

export default PermissionsList;