import React, {useCallback, useEffect} from "react";
import {makeStyles} from "@material-ui/styles";
import {CircularProgress, Theme} from "@material-ui/core";
import AuthToolbar from "../../shared/Common/Topbar/Toolbar/AuthToolbar";
import {useDispatch, useMappedState} from "redux-react-hook";
import useDropdown from "../../core/hooks/useDropdown";
import {SettingsActions} from "@core/store/actions/settings";
import {img} from "@core/utils/string";
import {StatsActions} from "@core/store/actions/stats";
import {statsSelector} from "@core/store/selectors/config";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    paddingLeft: 50,
    [theme.breakpoints.down(650)]: {
      paddingLeft: 0,
    }
  },
  main: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "stretch",
    width: '100%',
  },
  content: {
    width: '60%',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${img('login-bg.png')})`,
    backgroundSize: 'cover',
    '& img': {
      maxWidth: '800px',
      marginTop: 40,
      [theme.breakpoints.down(1226)]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down(830)]: {
        display: 'none'
      }
    },
    [theme.breakpoints.down(1226)]: {
      width: '50%',
    },
    [theme.breakpoints.down(830)]: {
      width: '30%',
    },
    [theme.breakpoints.down(650)]: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%'
    }
  },
  form: {
    width: '35%',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    [theme.breakpoints.down(1226)]: {
      width: '50%',
    },
    [theme.breakpoints.down(830)]: {
      width: '70%',
    },
    [theme.breakpoints.down(650)]: {
      width: '100%',
    }
  },
  loader: {
    textAlign: 'center'
  }
}));

const AuthLayout = (props: React.PropsWithChildren<any>) => {
  const classes = useStyles();
  const {language} = useMappedState((state) => state.settings)
  const stats = useMappedState(statsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: StatsActions.GET_STATS_AUTH
    })
  }, [dispatch]);

  const dropdown = useDropdown({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }
  });

  const changeLanguage = useCallback((lang: string) => {
    return () => {
      localStorage.setItem('lang', lang);
      dropdown.handleClose();
      dispatch({
        type: SettingsActions.CHANGE_LANG,
        payload: lang
      })
    }
  }, [dispatch, dropdown])


  return (
    <div className={classes.root}>
      <AuthToolbar title="Eqman" changeLanguage={changeLanguage} lang={language} dropdown={dropdown} />
      <main className={classes.main}>
        <div className={classes.form}>
          {
            stats.countryCodes.length > 0 && stats.country
              ? props.children
              : (
                <div className={classes.loader}>
                  <CircularProgress />
                  <p className="text-default">Loading</p>
                </div>
              )
          }
        </div>
        <div className={classes.content}>
          <img src={img('devices.svg')} alt=""/>
        </div>
      </main>
    </div>
  )
};

export default AuthLayout;
