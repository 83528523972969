import RU from '../languages/ru.json';
import EN from '../languages/en.json';
import ItemsEN from '../languages/en/items.json';
import ItemsRU from '../languages/ru/items.json';
import TxRU from '../languages/ru/txs.json';
import TxEN from '../languages/en/txs.json';
import UserEN from '../languages/en/user.json';
import UserRU from '../languages/ru/user.json';
import RequestRu from '../languages/ru/request.json';
import RequestEN from '../languages/en/request.json';
import ErrorsEN from '../languages/en/errors.json';
import ErrorsRU from '../languages/ru/errors.json';
import PaymentRU from '../languages/ru/payment.json';
import PaymentEN from '../languages/en/payment.json';
import NotificationEN from '../languages/en/notifications.json';
import NotificationRU from '../languages/ru/notifications.json';
import {useMappedState} from "redux-react-hook";
import {getLang} from "@core/utils/global";
import PL from '../languages/pl.json';
import UA from '../languages/ua.json';



const useLangProvider = () => {
  const language = useMappedState(state => state.settings.language || localStorage.getItem('lang') || getLang())
  return {
    resources: {
      ru: {
        translation: {
          ...RU,
          ...ItemsRU,
          ...TxRU,
          ...UserRU,
          ...RequestRu,
          ...ErrorsRU,
          ...PaymentRU,
          ...NotificationRU
        }
      },
      en: {
        translation: {
          ...NotificationEN,
          ...EN,
          ...ItemsEN,
          ...TxEN,
          ...UserEN,
          ...RequestEN,
          ...ErrorsEN,
          ...PaymentEN
        }
      },
      pl: {
        translation: {
          ...PL,
        }
      },
      ua: {
        translation: {
          ...UA
        }
      }
    },
    language
  };
}

export default useLangProvider;