
export enum AuthAction {
  LOGIN = 'login',
  REGISTER = 'register',
  SET_USER = 'set_user',
  GET_CURRENT_USER = 'get_current_user',
  FINISH_REGISTRATION = 'finish',
  CHANGE_PASSWORD = 'change_password',
  EDIT_SETTINGS = 'edit_settings',
  CLEAR_AUTH = 'clear_auth',
  SEND_SMS = 'send_sms',
  SEND_EMAIL = 'send_email',
  VERIFY_PHONE = 'verify_phone',
  SET_FIREBASE_TOKEN = 'set_firebase_token'
}
