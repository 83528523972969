import axios from '../utils/axios';
import {normalizeFilters} from "../utils/normalize";
import {Roles} from "../types";
import {IFiltersPagination} from "@core/types/store";

interface ICreateItem {
  role?: Roles,
  firstName: string,
  lastName: string,
  email: string,
  phone: string
}

const BASE = (company: string) => `/company/${company}/user`;

export default {
  getItems: async (company: string, filters: any) => (await axios.get(BASE(company), {params: {...normalizeFilters(filters)}})).data,
  getItem: async (id: string, company_id: string) => (await axios.get(`${BASE(company_id)}/${id}/detailed`)).data,
  getItemTransactions: async (id: string, company_id: string, pagination: IFiltersPagination) => (await axios.get(`${BASE(company_id)}/${id}/transactions`, {params: pagination})).data,
  getItemTransfers: async (id: string, company_id: string) => (await axios.get(`${BASE(company_id)}/${id}/transfers`)).data,
  create: async (company: string, data: ICreateItem) => (await axios.post(BASE(company), data)).data,
  edit: async (company: string, id: string, data: ICreateItem) => (await axios.put(`${BASE(company)}/${id}/edit`, data)).data,
  remove: async (company: string, id: string, owner: string) => (await axios.delete(`${BASE(company)}/${id}`, {params: {responsible: owner}})).data,
  sendSmsVerificationLink: async (company: string, id: string) => (await axios.post(`${BASE(company)}/${id}/send_sms`)).data
}
