interface NormalizeFiltersSettings {
  preventFalsable?: boolean;
}

const defaultSettings: NormalizeFiltersSettings = {
  preventFalsable: true,
}

export const normalizeFilters = (filters: any = {}, settings: NormalizeFiltersSettings = defaultSettings) => {
  const keys = Object.keys(filters);
  const result: any = {};

  keys.forEach((key: string) => {
    const item = filters[key];
    let value;

    if(Array.isArray(item)) {
      value = item
    }

    if(typeof item === 'string' || typeof item === 'number') {
      value = item;
    }
    if(typeof item === 'object' && item && !Array.isArray(item)) {
      if(typeof item.value === 'object' && (item.value && settings.preventFalsable)) {
        value = item.value.value || item.title || item._id;
      } else if(!Object.keys(item).includes('value') && Object.keys(item).length > 0) {
        value = normalizeFilters(item, settings)
      }
      else {
        value = item.value || item.title || item._id;
      }
    }
    if(value || !settings.preventFalsable) {
      result[key] = value
    }
  });
  return result;
};

export const normilizeFiltersValues = (filters: any) => {
  return Object.keys(filters).map((key: string) => {
    const item = filters[key];
    if(typeof item === 'object' && item) {
     return {key, value: item.title};
    }
    return {key, value: item}
  }).filter((item: any) => item.value && !['search', 'rewrite'].includes(item.key));
};

export const normalizePhone = (phone: string) => {
  if(phone.length < 5) return '';
  return phone.replace(/-/g, '').replace(/ /g, '').trim()
}