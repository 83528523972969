import React from "react";
import {Button, Icon} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {RouteComponentProps} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Card from "../../../shared/Common/Card";
import {img} from "@core/utils/string";

export const ItemActionStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down(1280)]: {
      display: 'block',
    },
  },
  card: {
    margin: '0 auto',
    width: 700,
    [theme.breakpoints.down(700)]: {
      width: '95%'
    },
  },
  cardTitle: {
    marginBottom: 0,
    position: 'absolute'
  },
  body: {
    marginTop: 20
  },
  title: {
    flex: '0 1 50%',
    textAlign: 'center'
  },
  img: {
    width: '100%'
  },
  item: {
    margin: '0px 0px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 15
  },
  input: {
    margin: 0
  },
  iconBack: {
    cursor: 'pointer'
  }
}));


const NetworkError = (props: RouteComponentProps) => {
  const classes = ItemActionStyles();
  const {t} = useTranslation();
  return (
    <Card
      classes={{title: classes.cardTitle}}
      className={classes.card}
    >
      <div className={classes.root}>
        <div className={classes.body}>
          <h3 className="text-default">Проводятся технические работы</h3>
          <p className="subtitle">Приносим наши извинения за временные неудобства.</p>
          <div className={classes.actions}>
            <Button
              color="secondary"
              onClick={() => props.history.push('/')}
            >
              {t('continue')}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
};

export default NetworkError;
