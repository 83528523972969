import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {FilledInput, FormHelperText, Select as MUISelect, Theme} from '@material-ui/core';
import {OutlinedInput, Input} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '10px 0',
    minWidth: 320,
    [theme.breakpoints.down(320)]: {
      minWidth: '100%'
    }
  }
}));


const Select = (props: any) => {
  const classes = useStyles();
  const {
    label,
    className,
    name,
    value,
    handleChange,
    data,
    required = false,
    error,
    helperText,
    variant = 'filled',
    size = 'medium',
    color = "secondary",
    translate = false,
    warning = false,
  } = props;
  const {t} = useTranslation();
  const SelectInput = variant === 'outlined' ? OutlinedInput : variant === 'filled' ? FilledInput : Input;
  return (
      <FormControl variant={variant} fullWidth color={color} size={size} className={classNames(classes.root, className)} error={error}>
        <InputLabel required={props.required}>{label}</InputLabel>
        <MUISelect
            native={isMobile}
            value={value || ''}
            onChange={handleChange}
            required={required}
            input={<SelectInput name={name} />}
        >
          {
            data && data.length > 0 &&
              data.map((item: any) => {
                return (
                  isMobile
                    ? <option key={item.label} disabled={item.disabled} value={item.value}>{translate ? t(item.label) : item.label}</option>
                    : <MenuItem key={item.label} disabled={item.disabled} value={item.value}>{translate ? t(item.label) : item.label}</MenuItem>
                )
              })
          }
        </MUISelect>
        <FormHelperText style={{whiteSpace: 'pre-wrap', fontSize: 13, fontWeight: warning || error ? 600 : 400}} error={warning || error}>{t(helperText)}</FormHelperText>
      </FormControl>
  )
};

export default Select;
