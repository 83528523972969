import {rules} from "./validation";

export const isStringLength = (value: string) => value.length > 0;
export const generateCode = (value: number, prefix: string, length: number) => {
  const valueLength = value.toString().length;
  const basis = new Array(length - valueLength).fill('0');
  return `${prefix}${basis.join('')}${value}`
}

export const loginTypeDetection = (login: string) => {
  if(!rules.email(login)) return 'email';
  if(!rules.phone(login)) return 'phone';
  return 'unknown';
}

export const img = (url: string) => {
  return `${process.env.REACT_APP_SERVER_URL}/images/${url}`
}
export const imgLocal = (url: string) => {
  return `/images/${url}`
}

export const cutLongString = (str: string, length: number, replacer: any = '...') => {
  return str.slice(0, length)+replacer
}

export const clearPhoneString = (phone: string) => {
  return phone.replace(/[A-Z]/gi, '').replace(/[+]?[-]?[ ]?/gm, '');
}

export const clearItemQuantity = (val: string) => {
  if(!val || Number.isNaN(val) || Number(val) === 0) return 1;
  return +val;
}

export const countFilesTotalSize = (files: File[]) => {
  return (files.reduce((acc: number, file: any) => acc + file.size, 0)) / (1024*1024);
}

export const setCookie = (name: string, value: string, options: any = {}) => {

  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export const getCookie = (name: string) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const deleteCookie = (name: string) => {
  setCookie(name, "", {
    'max-age': -1,
    'domain': 'eqman.co'
  })
}