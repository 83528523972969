import React, {PropsWithChildren} from "react";
import {
  Accordion as ExpansionPanel,
  AccordionDetails as ExpansionPanelDetails,
  AccordionSummary as ExpansionPanelSummary,
  Theme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";

interface IStaticDropdownProps {
  open: boolean,
  depth?: 0 | 1,
  className?: string
}
const useStyles = makeStyles((theme: Theme) => ({
  depth0: {
    boxShadow: 'none'
  }
}))

const StaticDropdown = (props: PropsWithChildren<IStaticDropdownProps>) => {
  const {open, className} = props;
  const classes = useStyles();
  return (
    <ExpansionPanel style={{margin: 0}} expanded={open} className={classNames(className, {[classes.depth0]: props.depth === 0})}>
      <ExpansionPanelSummary style={{display: 'none'}} />
      <ExpansionPanelDetails style={{padding: '10px 15px', display: 'block'}}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

export default StaticDropdown;
