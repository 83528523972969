import { IItem, IUser, IUserFull, Roles } from "@core/types";

export const defaultUnits = [
  { label: "шт", value: "шт" },
  { label: "мм", value: "мм" },
  { label: "см", value: "см" },
  { label: "м", value: "м" },
  { label: "м²", value: "м²" },
  { label: "м³", value: "м³" },
  { label: "км", value: "км" },
  { label: "г", value: "г" },
  { label: "кг", value: "кг" },
  { label: "т", value: "т" },
  { label: "мл", value: "мл" },
  { label: "л", value: "л" },
  { label: "ящ", value: "ящ" },
  { label: "кор", value: "кор" },
  { label: "рул", value: "рул" },
  { label: "пач", value: "пач" },
  { label: "упак", value: "упак" },
  { label: "комплект", value: "комплект" },
  { label: "бухт", value: "бухт" },
  { label: "пара", value: "пара" },
];

export const arrayToObject = (array: any[], key: string): any => {
  const obj: any = {};
  array.forEach((item: any) => {
    const objKey = item[key];
    if (objKey) {
      delete item[key];
      obj[objKey] = item;
    }
  });

  return obj;
};

export const mapFormFieldsToObject = (fields: any) => {
  const result: any = {};
  Object.keys(fields).forEach((key) => {
    result[key] = fields[key].value;
  });

  return result;
};

class Element implements Object {
  constructor(
    public value: any,
    public prev: number | null,
    public next: number | null
  ) {}
  getNext() {}
  getPrev() {}
}

export class LinkedList {
  public values: any[] = [];
  public list: any[] = [];

  constructor(list: any[]) {
    this.values = list.map((item) => ({ current: item }));
    this.values.forEach((item, index) => {
      this.list.push(this.add(item, index));
    });
  }

  add(item: any, index: number) {
    const newItem = new Element(
      item,
      index === 0 ? null : index - 1,
      index + 1
    );
    newItem.getNext = () => {
      return this.get(newItem.next);
    };
    newItem.getPrev = () => {
      return this.get(newItem.prev);
    };
    return newItem;
  }

  find(value: any) {
    return this.list.find((item) => item.value.current === value);
  }

  get(index: number | null) {
    if (index === null) return null;
    return this.list[index];
  }
}

export const getColorForString = (status: string) => {
  const statuses: any = {
    created: "",
    processing: "orange",
    declined: "red",
    approved: "green",
    expired: "purple",
  };

  return statuses[status] || "";
};

export const removeRepeatable = (arr: any[]) => {
  const set = new Set(arr);

  return Array.from(set);
};

export const isAdmin = (user: IUser) => {
  return [Roles.ADMIN, Roles.ROOT].includes(user.role);
};
export const isStockman = (user: IUser) => {
  return [Roles.ADMIN, Roles.ROOT, Roles.STOCKMAN].includes(user.role);
};
export const isRoot = (user: IUser | IUserFull) => {
  return Roles.ROOT === user.role;
};
export const isWorker = (user: IUser) => {
  return Roles.WORKER === user.role;
};

export const objectToQueryString = (field: string, object: any) => {
  let result = "";
  for (let key of Object.keys(object)) {
    result += `${field}[${key}]=${object[key]}&`;
  }
  return result;
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getLang = (): string => {
  let win: any = window;
  let language = win.navigator
    ? win.navigator.language ||
      win.navigator.systemLanguage ||
      win.navigator.userLanguage
    : "ua";
  language = language.substr(0, 2);
  return localStorage.getItem("lang") || (language === "ru" ? "ua" : language);
};

export const getUserName = (user: any) => {
  let name = "";

  if (user.firstName) {
    name += user.firstName;
  }

  if (user.lastName) {
    name += " " + user.lastName;
  }

  if (!user.firstName && !user.lastName && user.email) {
    name += user.email;
  }

  return name;
};

export const getItemTitle = (item: IItem) => {
  return ` ${getItemTitleFromMetadata(item.metadata)} ${
    item.metadata.type || ""
  } ${
    item.batch?.quantity ? `(${item.batch.quantity} ${item.batch.units})` : ""
  }`;
};

export const getItemTitleFromMetadata = (
  metadata: Partial<IItem["metadata"]>
) => {
  return ` ${metadata.title || ""} ${metadata.brand || ""} ${
    metadata.model || ""
  } ${metadata.type || ""}`;
};
