import { all } from 'redux-saga/effects'
import {rootAuthSaga} from "./auth";
import {rootStatsSaga} from "./stats";
import { rootItemSaga, itemBulkOperations, itemOperations } from './item';
import {rootUsersSaga} from "./user";
import { rootStocktakingSaga } from './stocktaking';
import rootCommentsSaga from "./comments";
import {rootRequestSaga} from "./request";
import rootDatafilesSaga from "./datafiles";
import rootPaymentSaga from "@core/store/sagas/payment";
import { rootLocationsSaga } from './locations';
import {rootAnalyticsSaga} from "@core/store/sagas/analytics";
import rootIntegrationSaga from "@core/store/sagas/integration";
import rootCompanySaga from "@core/store/sagas/company";


export default function* rootSaga() {
  yield all([
    rootAuthSaga(),
    rootStatsSaga(),
    rootItemSaga(),
    rootUsersSaga(),
    rootStocktakingSaga(),
    rootCommentsSaga(),
    rootRequestSaga(),
    rootDatafilesSaga(),
    rootPaymentSaga(),
    rootLocationsSaga(),
    rootAnalyticsSaga(),
    rootIntegrationSaga(),
    rootCompanySaga(),
    itemBulkOperations(),
    itemOperations()
  ])
}
