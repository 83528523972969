import React from "react";
import {Button, Paper, Theme} from "@material-ui/core";
import classNames from "classnames";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '30px 30px 20px 30px',
    position: "relative",
    zIndex: 100,
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    }
  },
  img: {
    width: '90%',
    margin: '0 auto',
    display: 'block'
  },
  actions: {
    marginTop: 20,
    textAlign: 'left'
  }
}))

const ForgotPasswordNotVerifiedError = (props: {back: () => void, emailOrPhone: string}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const header = props.emailOrPhone[0] === '+' ? t('error_forgot_pass_unverified_phone') : t('error_forgot_pass_unverified_email');
  const message = props.emailOrPhone[0] === '+' ? t('error_forgot_pass_unverified_phone_sub') : t('error_forgot_pass_unverified_email_sub');
  const messageBeforeSelection = message.split(' ').splice(0, 3).join(' ');
  const messageAfterSelection = message.split(' ').splice(3).join(' ');

  return (
    <Paper className={classNames(classes.root, 'root')}>
      <img src={img('verified.svg')} alt="" className={classes.img}/>
      <h3 className="text-default">{header}</h3>
      <p className="subtitle">{messageBeforeSelection} <strong>{props.emailOrPhone}</strong> {messageAfterSelection}</p>
      <div className={classes.actions}>
        <Button color="secondary" onClick={props.back}>{t('back')}</Button>
      </div>
    </Paper>
  )
}

export default ForgotPasswordNotVerifiedError;