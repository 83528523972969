import {IAction, IActionWithSingleSideEffect} from "../actions";
import {all, select, takeLatest, put} from "redux-saga/effects";
import {DatafileActions} from "../actions/datafile";
import notify from "../../utils/notify";
import Service from '../../services/datafiles';

function* getFiles(action: IAction) {
  try {
    const {auth: {user: {company}}} = yield select();
    const data = yield Service.getFiles(company);
    yield put({
      type: DatafileActions.SET_ITEMS,
      payload: data
    })
  } catch (e) {
    notify.error('Error getting datafiles')
  }
}

function* uploadFile(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield Service.upload(company, action.payload);
    yield action.sideEffect();
  } catch (e) {
    notify.error('Error uploading file')
  }
}

export default function* rootDatafilesSaga() {
  return yield all([
    yield takeLatest(DatafileActions.GET_ITEMS, getFiles),
    yield takeLatest(DatafileActions.UPLOAD_FILES, uploadFile),
  ])
}