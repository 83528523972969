import { all, put, select, takeLatest, delay } from "redux-saga/effects";
import notify from "../../utils/notify";
import {IAction, IActionWithSingleSideEffect} from "../actions";
import Request from '../../services/request';
import {RequestActions} from "../actions/request";
import {RequestPageType} from "../reducers/request";
import {normalizeFilters} from "../../utils/normalize";

function* getRequests(action: IAction) {
  try {
    yield put({
      type: RequestActions.SET_LOADING,
      payload: true
    })

    const {request: {markMethods, type, data: storeData}} = yield select();

    if(markMethods.length === 0 ) {
      yield put({
        type: RequestActions.GET_MARK_METHODS
      })
    }

    if(RequestPageType.REQUESTS === type) {
      const {data, count: total} = yield Request.getAll(normalizeFilters(action.payload));

      yield put({
        type: RequestActions.SET_ITEMS,
        payload: {
          total,
          data: total === storeData.length ? [] : data
        }
      });
    }

    yield delay(200, true)
    yield put({
      type: RequestActions.SET_LOADING,
      payload: false
    })
  } catch (e) {
    notify.error('Error getting the requests')
  }
}

function* getMarkMethods() {
  try {
    const markMethodsData = yield Request.getMarkMethods();
    yield put({
      type: RequestActions.SET_MARK_METHODS,
      payload: markMethodsData
    })
  } catch(E) {
    notify.error('Error getting mark methods')
  }
}

function* getSingle(action: IAction) {
  try {
    yield put({
      type: RequestActions.SET_LOADING,
      payload: true
    })
    const request = yield Request.getOne(action.payload);

    let {request: {markMethods}} = yield select();
    if(markMethods.length === 0) {
      markMethods = yield Request.getMarkMethods();
      yield put({
        type: RequestActions.SET_MARK_METHODS,
        payload: markMethods
      })
    }

    yield put({
      type: RequestActions.SET_ITEM,
      payload: request
    });

    yield put({
      type: RequestActions.SET_LOADING,
      payload: false
    })
  } catch (e) {
    notify.error('Error getting request')
  }
}

function* createRequest(action: IActionWithSingleSideEffect) {
  try {
    if(action.payload.publish) {
      const request = yield Request.createAndPublish(action.payload.data);
      action.sideEffect(request.checkoutUrl);
    } else {
      yield Request.create(action.payload.data);
      action.sideEffect();
    }
    notify.success('Request successfully created');
  } catch(E) {
    notify.error("Error request creation")
  }
}


function* updateRequest(action: IActionWithSingleSideEffect) {
  try {
    if(action.payload.publish) {
      const request = yield Request.updateAndPublish(action.payload.id, action.payload.data);
      yield action.sideEffect(request.checkoutUrl);
    } else {
      yield Request.update(action.payload.id, action.payload.data);
    }
    notify.success('Request successfully updated');
  } catch(E) {
    notify.error("Error request editing")
  }
}

function* reject(action: IActionWithSingleSideEffect) {
  try {
    yield Request.reject(action.payload);
    yield action.sideEffect();
  } catch(E) {
    notify.error('Error rejecting the request')
  }
}

export function* rootRequestSaga() {
  return yield all([
    yield takeLatest(RequestActions.GET_ITEMS, getRequests),
    yield takeLatest(RequestActions.GET_MARK_METHODS, getMarkMethods),
    yield takeLatest(RequestActions.GET_ITEM, getSingle),
    yield takeLatest(RequestActions.CREATE_ITEM, createRequest),
    yield takeLatest(RequestActions.EDIT_ITEM, updateRequest),
    yield takeLatest(RequestActions.REJECT_ORDER, reject)
  ])
}