export enum PaymentActions {
  GET_ITEMS = 'get_payments',
  GET_ITEM = 'get_payment',
  SET_ITEMS = 'set_payments',
  TOGGLE_FILTERS = 'toggle_payment_filters',
  SET_FILTERS = 'set_payment_filters',
  CLEAR_FILTERS = 'clear_payment_filters',
  DELETE_FILTERS = 'delete_payment_filters',
  SET_LOADING = 'set_payments_loading',
  SET_ITEM = 'set_payment',
  CLEAR_ITEM = 'clear_payment',
  SET_PAGINATION = 'set_pagination_payment',
  CREATE_PAYMENT = 'create_payment',
  CLEAR_ITEMS = 'clear_payments',
  DISABLE_SUBSCRIPTION = 'disable_subscription',
}