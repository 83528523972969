import {IActionWithErrorAndSuccess, IActionWithSingleSideEffect} from "@core/store/actions";
import {put, select, takeLatest, all, call, delay} from "redux-saga/effects";
import service from "@core/services/item";
import {StatsActions} from "@core/store/actions/stats";
import {ItemsActions} from "@core/store/actions/items";
import {setItemsLoading} from "@core/store/sagas/item";


function* removeAll(action: IActionWithSingleSideEffect) {
  try {
    yield setItemsLoading(true);
    const {auth: {user: {company}}, items: {filters, pagination, sort}} = yield select();

    yield service.remove(company, action.payload.ids);

    yield put({
      type: StatsActions.SUBTRACT_ITEMS_COUNT,
      payload: action.payload.ids.length
    });

    yield action.sideEffect();
    yield put({
      type: ItemsActions.GET_ITEMS,
      payload: {filters, pagination, sort, rewrite: true}
    });

  } catch (E) {
    console.log(E);
  }
}

function* move(action: IActionWithErrorAndSuccess<ItemsActions.MOVE_BULK, {ids: string[], user: string, object?: {object: string, location: string}}>) {
  try {
    yield setItemsLoading(true);

    const {auth: {user: {company}}, items: {filters, pagination, sort}} = yield select();
    const data = yield service.moveItems(company, action.payload.ids, action.payload.user, action.payload.object);
    yield action.sideEffectSuccess(data);
    yield put({
      type: ItemsActions.GET_ITEMS,
      payload: {filters, pagination, sort, rewrite: true}
    });
  } catch (E) {
    yield action.sideEffectError(E.response.data);
  }
}

function* changeLocation(action: IActionWithSingleSideEffect) {
  try {
    yield setItemsLoading(true);
    const {items: {filters, pagination, sort}} = yield select();
    const {auth: {user: {company}}} = yield select();

    yield service.changeLocation(company, action.payload.ids, action.payload.object, action.payload.location);

    yield put({
      type: ItemsActions.GET_ITEMS,
      payload: {filters, pagination, sort, rewrite: true}
    });
  } catch (E) {
    console.log(E);
  }
}

export default function* itemBulkOperationsRoot() {
  yield all([
    yield takeLatest(ItemsActions.CHANGE_ITEM_LOCATION_ALL, changeLocation),
    yield takeLatest(ItemsActions.MOVE_BULK, move),
    yield takeLatest(ItemsActions.REMOVE_ITEM_ALL, removeAll),
  ])
}