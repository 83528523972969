import {IAction} from "../actions";
import {StatsActions} from "../actions/stats";
import Countries from './../../utils/countries';

const initialState: any = {
  company: null,
  usersCount: 0,
  loading: true,
  itemsCount: 0,
  totalItemPrice: 0,
  country: null,
  countryCodes: Countries
};

const StatsReducer = (state: any = initialState, action: IAction) => {
  switch (action.type) {
    case StatsActions.SET_STATS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case StatsActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case StatsActions.ADD_ITEMS_COUNT:
      return {
        ...state,
        itemsCount: state.itemsCount + Math.round(action.payload)
      }
    case StatsActions.SUBTRACT_ITEMS_COUNT:
      return {
        ...state,
        itemsCount: state.itemsCount - Math.round(action.payload)
      }
    case StatsActions.SET_USERS_COUNTRY:
      return {
        ...state,
        country: action.payload
      }
    case StatsActions.SET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.payload
      }
    default:
      return state;
  }
};

export default StatsReducer;
