import {ITypedAction} from "../actions";
import {IDefaultState} from "../../types/store";
import {ILocation} from "../../types";
import {LocationActions} from "@core/store/actions/locations";


const initialState: IDefaultState<ILocation, any> = {
  data: [],
  filtersOpen: false,
  filters: {
    search: '',
  },
  pagination: {
    limit: 5,
    offset: 0
  },
  total: 0,
  loading: false,
  item: null
};

const LocationReducer = (state: any = initialState, action: ITypedAction<LocationActions>) => {
  switch (action.type) {
    case LocationActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case LocationActions.UPDATE_ITEMS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        total: action.payload.total
      };
    case LocationActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    case LocationActions.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters
      };
    case LocationActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case LocationActions.UPDATE_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload
        }
      }
    case LocationActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case LocationActions.CLEAR_ITEMS:
      return initialState;
    default:
      return {
        ...state
      }
  }
};

export default LocationReducer;
