import {NotificationActions} from "@core/store/actions/notification";

const initialState = {
  data: []
}

const NotificationReducer = (state = initialState, {type, payload}: any) => {
  switch (type) {
    case NotificationActions.ADD:
      return {
        ...state,
        data: [payload, ...state.data]
      }
    case NotificationActions.MAKE_REED:
      return {
        ...state,
        data: state.data.map((noty: any, index) => ({...noty, read: true}))
      }
    default:
      return state;
  }
}

export default NotificationReducer;