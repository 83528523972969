export enum StatsActions {
  SET_STATS = 'set_stats',
  GET_STATS = 'get_stats',
  GET_STATS_AUTH = 'get_stats_auth',
  SET_LOADING = 'set_stats_loading',
  ADD_ITEMS_COUNT = 'add_stats_count',
  SUBTRACT_ITEMS_COUNT = 'subtract_stats_count',
  SET_USERS_COUNTRY = 'set_users_country',
  GET_USERS_COUNTRY = 'get_users_country',
  GET_COUNTRY_CODES = 'get_country_codes',
  SET_COUNTRY_CODES = 'set_country_codes',
}
