import React from "react";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import {Button, MenuItem, Toolbar} from "@material-ui/core";
import Dropdown from "../../Dropdown";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `100%`,
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    paddingLeft: 65
  },
  menuButton: {
    marginRight: 10,
  },
  topActions: {
    flexGrow: 1,
    textAlign: 'right'
  },
  lang: {
    color: '#fff'
  }
}));

const AuthToolbar = (props: any) => {
  const {
    changeLanguage,
    lang,
    title,
    dropdown
  } = props;
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={clsx(classes.appBar)}
    >
      <Toolbar>
        <a href="https://eqman.co" className="title">
          {title}
        </a>
        <div className={classes.topActions}>
          <Button className={classes.lang} onClick={dropdown.handleOpen}>{lang}</Button>
        </div>
      </Toolbar>
      <Dropdown {...dropdown}>
        <MenuItem onClick={changeLanguage('ua')}>UA</MenuItem>
        <MenuItem onClick={changeLanguage('en')}>EN</MenuItem>
        <MenuItem onClick={changeLanguage('pl')}>PL</MenuItem>
        <MenuItem onClick={changeLanguage('ru')}>RU</MenuItem>
      </Dropdown>
    </AppBar>
  )
}

export default AuthToolbar;