import React, {useEffect, Suspense} from 'react';
import { Switch, Route as BaseRoute, Redirect} from 'react-router-dom';
import routes, {IRoute} from '../core/routes';
import PrivateRoute from "../shared/Auth/PrivateRoute";
import withTheme from "../theme";
import MainLayout from "./layout/Main";
import { BrowserRouter as Router } from 'react-router-dom'
import Login from "./pages/Auth/Login";
import FinishRegistration from "./pages/Auth/Finish/Finish";
import useSnackbar from "../shared/Common/Snackbar/useSnackbar";
import SnackbarCustom from '../shared/Common/Snackbar';
import Helmet from "react-helmet";
import Register from "./pages/Auth/Register";
import EmailApproved from "./pages/Auth/EmailApproved";
import NetworkError from "./pages/Errors/NetworkError";
import Index from "./pages/Auth/ForgotPassword";
import Verification from "./pages/Auth/Verification";
import PaymentLayout from "./layout/Payment";
import RootRoute from "@shared/Auth/RootRoute";
import PaymentSuccess from "@featured/pages/Payment/Response/Success";
import useLangProvider from "@core/hooks/useLangProvider";
import i18n from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import ScrollTopWrapper from "@shared/Common/Wrapper/ScrollTop";
import ReactGA from 'react-ga';

if(process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-172518818-1', {
    gaOptions: {
      siteSpeedSampleRate: 0
    },
  });
}

function App() {
  const {language, resources} = useLangProvider();
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: language,
      keySeparator: false,
      interpolation: {
        escapeValue: false
      }
    });

  const snackbar = useSnackbar();

  useEffect(() => {
    const notify = (e: any) => {
      snackbar.handleOpenFromEvent(e)
    };
    document.addEventListener(
      'notify',
      notify,
      false
    );
    return () => {
      document.removeEventListener(
        'notify',
        notify,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <Helmet>
          <meta name="theme-color" content="#097950" />
        </Helmet>
        <Router>
          <ScrollTopWrapper>
            <Switch>
              <BaseRoute path="/" exact>
                <Redirect to="/items"/>
              </BaseRoute>
              <BaseRoute path="/auth/finish" component={FinishRegistration}/>
              <BaseRoute path="/auth/login" exact component={Login}/>
              <BaseRoute path="/auth/register" exact component={Register}/>
              <BaseRoute path="/auth/approve" exact component={EmailApproved}/>
              <BaseRoute path="/auth/forgot_password" exact component={Index}/>
              <BaseRoute path="/auth/verification" exact component={Verification}/>
              <BaseRoute path="/payment" exact component={PaymentLayout}/>
              <BaseRoute path="/error/network" exact component={NetworkError}/>
              <MainLayout>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <BaseRoute path="/payment/success" exact component={PaymentSuccess}/>
                    {
                      routes.map((route: IRoute) => {
                        const AccessRoute = route.root ? RootRoute : PrivateRoute;
                        const Route = route.private ? AccessRoute : BaseRoute;
                        return (
                          <Route key={route.path} path={route.path} exact={route.exact} component={route.component}/>
                        );
                      })
                    }
                  </Switch>
                </Suspense>
              </MainLayout>
            </Switch>
          </ScrollTopWrapper>
          <SnackbarCustom {...snackbar}/>
        </Router>
      </div>
    </I18nextProvider>
  );
}

export default withTheme(App);
