import {useCallback} from "react";
import {SettingsActions} from "@core/store/actions/settings";
import {useDispatch, useMappedState} from "redux-react-hook";
import { useHistory } from "react-router-dom";
import {IItem} from "@core/types";
import {createEvent} from "@shared/Common/GAEvent";

const useTutorial = () => {
  const history = useHistory()
  const {state, items} = useMappedState(state => ({state: state.settings.tutorial, items: state.items.data}));
  const dispatch = useDispatch();

  const disableBodyScroll = useCallback(() => {
    const body = document.querySelector('body');
    if(body) {
      body.style.overflow = 'hidden'
    }
  }, []);

  const enableBodyScroll = useCallback(() => {
    const body = document.querySelector('body');
    if(body) {
      body.style.overflow = 'auto'
    }
  }, [])

  const start = useCallback(() => {
    if(state.open) return;
    dispatch({
      type: SettingsActions.START_TUTORIAL
    })
  }, [state.open]);

  const activate = useCallback(() => {
    localStorage.setItem('tutorialOpen', '1');
    dispatch({
      type: SettingsActions.ACTIVATE_TUTORIAL,
    })
  }, []);

  const handleClose = useCallback(() => {
    dispatch({
      type: SettingsActions.FINISH_TUTORIAL,
    })
    localStorage.removeItem('tutorialOpen');
    createEvent({
      category: 'GuideFirstLogin',
      action: `GuideFirstLoginClose${state.currentStep+1}`
    })
  }, [dispatch, state]);

  const handlePrev = useCallback(() => {
    dispatch({
      type: SettingsActions.SET_SETTINGS,
      payload: {
        tutorial: {
          ...state,
          currentStep: state.currentStep - 1,
        }
      }
    })
    if(state.currentStep === 2) {
      history.push('/items')
      dispatch({
        type: SettingsActions.STOP_TUTORIAL
      })
    }
  }, [dispatch, state]);

  const finish = useCallback(() => {
    dispatch({
      type: SettingsActions.FINISH_TUTORIAL
    });
    localStorage.removeItem('tutorialOpen');
    createEvent({
      category: 'GuideFirstLogin',
      action: `GuideFirstLoginComplete6`
    })
  }, [dispatch]);


  const handleNext = useCallback(() => {
    dispatch({
      type: SettingsActions.SET_SETTINGS,
      payload: {
        tutorial: {
          ...state,
          currentStep: state.currentStep + 1,
        }
      }
    });
    createEvent({
      category: 'GuideFirstLogin',
      action: `GuideFirstLogin${state.currentStep > 4 ? 'Complete' : 'NextButton'}${state.currentStep+1}`
    })
    if(state.currentStep === 1) {
      const unmarked = items.find((item: IItem) => !item.code) || items[0];
      history.push(`/items/${unmarked._id}`)
      dispatch({
        type: SettingsActions.STOP_TUTORIAL
      })
    }
    if(state.currentStep > 7) {
      finish();
    }
  }, [dispatch, state, items, finish]);


  const getCurrentStep = useCallback(() => state.currentStep, [state])

  return {
    step: state.currentStep,
    isOpen: state.open && state.active,
    finish,
    handlePrev,
    handleNext,
    handleClose,
    getCurrentStep,
    activate,
    start,
    onAfterStart: disableBodyScroll,
    onBeforeClose: enableBodyScroll
  }
}

export default useTutorial;