import * as React from "react";


const useModal = (onChange: (value: boolean) => void = () => {}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    if(onChange) {
      onChange(true);
    }
    setOpen(true);
  },[onChange]);

  const handleClose = React.useCallback(() => {
    if(onChange) {
      onChange(false);
    }
    setOpen(false);
  }, [onChange]);

  const handleToggle = React.useCallback(() => {
    if(onChange) {
      onChange(!open);
    }
    setOpen(!open);
  }, [open, onChange]);
  return {
    open,
    handleOpen,
    handleClose,
    handleToggle,
    setOpen
  }
};

export default useModal;
