import axios from "../utils/axios";
import { IFiltersPagination } from "../types/store";
import { normalizeFilters } from "../utils/normalize";
import { objectToQueryString } from "@core/utils/global";
import FileSaver from "file-saver";
import company from "@core/services/company";

interface ICreateItem {
  type: string;
  brand: string;
  model: string;
  serial: string;
  capacity?: string;
  person?: string;
  location?: string;
}

interface IUpdateItem {
  type: string;
  brand: string;
  model: string;
  serial: string;
  capacity: string;
}

interface IRepairItem {
  description: string;
  place: string;
}

const BASE = (company: string) => `/company/${company}/item`;

export default {
  createReport: async (
    company: string,
    email: string,
    filters: any,
    sort: any
  ) =>
    (
      await axios.post(
        `${BASE(company)}/report?${objectToQueryString("sort", sort)}`,
        { email },
        { params: { ...normalizeFilters(filters) } }
      )
    ).data,
  getItems: async (
    company: string,
    pagination: IFiltersPagination,
    filters: any,
    sort: any
  ) =>
    (
      await axios.get(
        `${BASE(company)}?${objectToQueryString("sort", sort)}&withPhoto=true`,
        {
          params: {
            ...pagination,
            ...normalizeFilters(filters),
            withPhoto: true,
          },
        }
      )
    ).data,
  getCountersData: async (company: string, filters: any) =>
    (
      await axios.get(`${BASE(company)}/counters`, {
        params: normalizeFilters(filters),
      })
    ).data,
  getItem: async (id: string, company_id: string) =>
    (await axios.get(`${BASE(company_id)}/${id}/detailed`)).data,
  getItemTransactions: async (
    id: string,
    company_id: string,
    pagination?: IFiltersPagination
  ) =>
    (
      await axios.get(`${BASE(company_id)}/${id}/transactions`, {
        params: pagination || {},
      })
    ).data,
  getItemTransfers: async (id: string, company_id: string) =>
    (await axios.get(`${BASE(company_id)}/${id}/transfers`)).data,
  create: async (company: string, data: ICreateItem) =>
    (await axios.post(BASE(company), data)).data,
  edit: async (company: string, id: string, data: IUpdateItem) =>
    (await axios.put(`${BASE(company)}/${id}/edit`, data)).data,
  repair: async (company: string, id: string, data: IRepairItem) =>
    (await axios.put(`${BASE(company)}/${id}/repair`, data)).data,
  ban: async (company: string, id: string, data: any) =>
    (await axios.put(`${BASE(company)}/${id}/ban`, data)).data,
  back: async (company: string, id: string, user?: string) =>
    (await axios.put(`${BASE(company)}/repair/back`, { item_ids: [id], user }))
      .data,
  remove: async (company: string, ids: string[]) =>
    (await axios.post(`${BASE(company)}/delete`, { item_ids: ids })).data,
  changeLocation: async (
    company: string,
    ids: string[],
    object: string,
    location: string
  ) =>
    (
      await axios.put(`${BASE(company)}/locations/add`, {
        item_ids: ids,
        object,
        location,
      })
    ).data,
  moveItems: async (
    company: string,
    item_ids: string[],
    user: string,
    object?: { object: string; location: string }
  ) =>
    (await axios.put(`${BASE(company)}/move`, { item_ids, user, object })).data,
  mountItems: async (
    company: string,
    data: { items: { id: string; quantity: number }[]; parent: string }
  ) => (await axios.put(`${BASE(company)}/mount`, data)).data,
  unmountItems: async (
    company: string,
    data: { items: string[]; parent: string }
  ) => (await axios.put(`${BASE(company)}/unmount`, data)).data,
  mergeItem: async (company: string, id: string, to: string) =>
    await axios.put(`${BASE(company)}/${id}/merge`, { to }),
  downloadCode: async (
    code: string,
    type: "regular" | "extended",
    company: string
  ) => {
    const { data } = await axios.get(
      `/company/${company}/item/${code}/download-code`,
      { responseType: "blob", params: { type } }
    );
    FileSaver.saveAs(data, `${code}.pdf`);
  },
  uploadPhotos: async (company: string, id: string, data: FormData) =>
    (await axios.patch(`${BASE(company)}/${id}/photos`, data)).data,
  setMainPhoto: async (company: string, id: string, photo: string) =>
    (await axios.patch(`${BASE(company)}/${id}/photos/${photo}/main`)).data,
  deletePhoto: async (company: string, id: string, photo: string) =>
    (await axios.delete(`${BASE(company)}/${id}/photos/${photo}`)).data,
};
