import {IActionWithErrorAndSuccess, IActionWithSingleSideEffect} from "@core/store/actions";
import {put, select, takeLatest, all} from "redux-saga/effects";
import service from "@core/services/item";
import {StatsActions} from "@core/store/actions/stats";
import {normalizeFilters} from "@core/utils/normalize";
import {ItemsActions} from "@core/store/actions/items";
import notify from "@core/utils/notify";

function* create(action: IActionWithErrorAndSuccess) {
  try {
    const {auth: {user: {company}}, stats: {itemsCount, totalItemPrice}} = yield select();

    const item = yield service.create(company, action.payload);
    yield action.sideEffectSuccess(item._id);
    yield put({
      type: StatsActions.SET_STATS,
      payload: {
        itemsCount: itemsCount + Number(action.payload.count),
        totalItemPrice: totalItemPrice + (Number(action.payload.count) * Number(action.payload.price || 0))
      }
    })
  } catch(E) {
    console.log(E);
    yield action.sideEffectError();
  }
}

function* edit(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield service.edit(company, action.payload.id, normalizeFilters(action.payload.data));
    yield put({
      type: ItemsActions.GET_ITEM,
      payload: {
        id: action.payload.id
      }
    })
    yield action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}

function* repair(action: IActionWithSingleSideEffect<{data: {place: string, description: string, quantity?: number}, id: string, recalculate: boolean}>) {
  try {
    const {auth: {user: {company}}, stats: {itemsCount}} = yield select();

    yield service.repair(company, action.payload.id, normalizeFilters(action.payload.data));
    yield put({
      type: ItemsActions.GET_ITEM,
      payload: {
        id: action.payload.id
      }
    });
    if(action.payload.recalculate) {
      yield put({
        type: StatsActions.SET_STATS,
        payload: {
          itemsCount: itemsCount + 1
        }
      })
    }
    yield action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}

function* ban(action: IActionWithSingleSideEffect<{quantity: number, recalculate: boolean}>) {
  try {
    const {auth: {user: {company}}, stats: {itemsCount}} = yield select();

    yield service.ban(company, action.payload.id, action.payload.data);
    yield put({
      type: ItemsActions.GET_ITEM,
      payload: {
        id: action.payload.id
      }
    });
    yield action.sideEffect();
    if(action.payload.recalculate) {
      yield put({
        type: StatsActions.SET_STATS,
        payload: {
          itemsCount: itemsCount + 1
        }
      })
    }
  } catch (E) {
    console.log(E);
  }
}

function* back(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();

    yield service.back(company, action.payload.id, action.payload.user);
    yield put({
      type: ItemsActions.GET_ITEM,
      payload: {
        id: action.payload.id
      }
    });
    yield action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}

function* remove(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();

    yield service.remove(company, action.payload.ids);
    yield put({
      type: StatsActions.SUBTRACT_ITEMS_COUNT,
      payload: action.payload.ids.length
    });
    yield action.sideEffect();
  } catch (E) {
    console.log(E);
  }
}
function* merge(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield service.mergeItem(company, action.payload.id, action.payload.to);
    yield action.sideEffect();
    yield put({
      type: StatsActions.GET_STATS,
    });
  } catch (e) {
    notify.warning('Error merging item');
  }
}

function* addItems(action: IActionWithErrorAndSuccess) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield service.mountItems(company, action.payload);
    yield put({
      type: ItemsActions.GET_ITEM,
      payload: {
        id: action.payload.parent
      }
    });
    yield put({
      type: StatsActions.GET_STATS
    })
    yield action.sideEffectSuccess();
  } catch (e) {
    yield action.sideEffectError()
    notify.error('Error mounting')
  }
}

function* removeItems(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield service.unmountItems(company, {items: [action.payload.id], parent: action.payload.parent});

    yield action.sideEffect();
  } catch (e) {
    notify.error('Error unmounting')
  }
}

export default function* itemsOperationsSaga() {
  yield all([
    yield takeLatest(ItemsActions.REPAIR_ITEM, repair),
    yield takeLatest(ItemsActions.REPAIR_BACK_ITEM, back),
    yield takeLatest(ItemsActions.CREATE_ITEM, create),
    yield takeLatest(ItemsActions.EDIT_ITEM, edit),
    yield takeLatest(ItemsActions.BAN_ITEM, ban),
    yield takeLatest(ItemsActions.REMOVE_ITEM, remove),
    yield takeLatest(ItemsActions.MERGE_ITEM, merge),
    yield takeLatest(ItemsActions.ADD_ITEMS, addItems),
    yield takeLatest(ItemsActions.REMOVE_ITEMS, removeItems),
  ])
}