import axios from '../utils/axios';
import {default as axiosBase} from "axios";

export default {
  getStats: async () => (await axios.get('/company/my')).data,
  getUsersCountry: async () => (await axiosBase.get('https://ipinfo.io/json', {
    headers: {
      authorization: `Bearer 39f73644e73463`
    }
  })).data
}
