import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon, MenuItem } from "@material-ui/core";
import { findRouteByPath, isInnerRoute, nav } from "../../../core/routes";
import UserInfo from "../../Widgets/User/UserInfo";
import List from "@material-ui/core/List";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useMappedState } from "redux-react-hook";
import useModal from "../../../core/hooks/useModal";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import GAEvent from "../GAEvent";
import useDropdown from "@core/hooks/useDropdown";
import Dropdown from "@shared/Common/Dropdown";
import { LangSettings } from "./Toolbar/LangSettings";
import { TopbarContext } from "@core/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  telegram: {
    display: "flex",
    alignItems: "center",
    color: "#439be5",
    fontWeight: 500,
    textTransform: "uppercase",
    "& img": {
      marginRight: 10,
    },
  },
  topbar: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff",
  },
  navlist: {
    padding: "10px 10px",
  },
  navitem: {
    padding: "10px 10px",
    borderRadius: 3,
    alignItem: "center",
    marginBottom: 5,
  },
  menuButton: {
    marginRight: 10,
  },
  hide: {
    display: "none",
  },
  drawer: {},
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0px",
    borderRadius: "0px 5px 0px 0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  active: {
    backgroundColor: theme.palette.primary.light,
  },
  topActions: {
    flexGrow: 1,
    textAlign: "right",
  },
  lang: {
    color: "#fff",
  },
  support: {
    padding: 20,
    color: "#ffffff",
    maxWidth: 210,
    borderRadius: 10,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    "& p": {
      color: "#f4f4f4",
      fontSize: 13,
      marginBottom: 15,
      marginTop: 5,
    },
    "& a": {
      fontSize: 13,
    },
  },
  actionsToolbar: {
    borderTop: `1px solid ${theme.palette.primary.light}`,
    height: "auto",
    minHeight: "auto",
  },
}));

interface IProps extends PropsWithChildren<RouteComponentProps<any>> {
  path: string;
  disabled: boolean;
  alert?: ReactNode;
}

export const MenuProvider = React.createContext<any>({});

const TopbarMobile = (props: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [pageOptions, setPageOptions] = useState<any>([]);
  const { user } = useMappedState((state) => ({ user: state.auth.user }));
  const isInner = isInnerRoute(props.path);
  const drawer = useModal();
  const [title, setTitle] = useState<any>();
  const [actions, setActions] = useState<any>();

  const handleTopbarButtonClick = useCallback(
    (e: any) => {
      if (isInner) {
        props.history.goBack();
      } else {
        drawer.handleOpen();
      }
    },
    [drawer, isInner, props.history]
  );

  const pageOptionsMenu = useDropdown();

  return (
    <div>
      <CssBaseline />
      <Helmet>
        {drawer.open && <meta name="theme-color" content="#414b5c" />}
      </Helmet>
      <AppBar
        position="relative"
        elevation={0}
        className={clsx(classes.topbar)}
        color="primary"
      >
        {props.alert}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleTopbarButtonClick}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <Icon>{isInner ? "arrow_back" : "menu"}</Icon>
          </IconButton>
          <h1 className="title" style={{ flex: "1 1 100%" }}>
            {t(findRouteByPath(props.path))}
          </h1>

          <LangSettings />
          {props.location.pathname.indexOf("users") !== -1 && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              id="first-step"
            >
              <Link to="/users/create">
                <Icon>add</Icon>
              </Link>
            </IconButton>
          )}

          {props.location.pathname.indexOf("items") !== -1 ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              id="first-step"
            >
              <Link to="/items/create">
                <Icon>add</Icon>
              </Link>
            </IconButton>
          ) : (
            <IconButton color="inherit" aria-label="open drawer" edge="end">
              <Link to={`/settings`}>
                <Icon>account_circle</Icon>
              </Link>
            </IconButton>
          )}
          {pageOptions.length > 0 && (
            <>
              <IconButton
                color="inherit"
                aria-label="open page settings"
                edge="end"
                id="first-step"
                onClick={pageOptionsMenu.handleToggle}
              >
                <Icon>more_vert</Icon>
              </IconButton>
              <Dropdown {...pageOptionsMenu}>
                {pageOptions.map((option: any) => (
                  <MenuItem
                    onClick={() => {
                      option.onClick();
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </>
          )}
        </Toolbar>
        {actions && (
          <Toolbar className={classes.actionsToolbar}>{actions}</Toolbar>
        )}
      </AppBar>
      <SwipeableDrawer
        className="menu"
        key="menu-main-drawer"
        open={drawer.open}
        onClose={drawer.handleClose}
        onOpen={drawer.handleOpen}
      >
        <Link
          to="/settings"
          onClick={drawer.handleClose}
          className={classes.toolbar}
        >
          <UserInfo
            image={user.image}
            name={`${user.firstName} ${user.lastName || ""}`}
            email={user.email}
          />
          <IconButton>
            <Icon style={{ color: "#fff" }}>more_vert</Icon>
          </IconButton>
        </Link>

        <List className={classes.navlist}>
          {nav.map((item) => {
            const haveAccess = item.root
              ? user.role === "root" || user.role === "admin"
              : true;
            return (
              !item.hidden &&
              haveAccess && (
                <GAEvent
                  category={item.event.category}
                  action={item.event.action}
                >
                  <Link
                    to={item.path}
                    key={item.path}
                    onClick={drawer.handleClose}
                  >
                    <ListItem
                      button
                      key={item.path}
                      className={clsx(classes.navitem, {
                        [classes.active]: item.path === props.location.pathname,
                      })}
                    >
                      <ListItemIcon style={{ minWidth: 40 }}>
                        <Icon style={{ color: "#fff", fontSize: 18 }}>
                          {item.icon}
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={t(item.name)}
                        className="small"
                        style={{ color: "#fff" }}
                      />
                    </ListItem>
                  </Link>
                </GAEvent>
              )
            );
          })}
        </List>
        <div className={clsx(classes.support)}>
          <h3 className="text-default">{t("support")}</h3>
          <p className="subtitle">{t("support_desc")}</p>
          <a
            href="https://eqman-support-bot.tg.pulse.is"
            id="telegram_bot"
            className={classes.telegram}
          >
            <img
              src="./images/telegram-icon.png"
              alt=""
              style={{ width: "20px", height: "auto" }}
            />
            <span>Telegram Bot</span>
          </a>
          <br />
          <a
            href="tel:+380675243397"
            className="link"
            style={{ color: "#4dcc6d" }}
          >
            +38 (067) 524 33 97
          </a>
          <br />
          <a
            href="mailto:support@eqman.co"
            className="link"
            style={{ color: "#4dcc6d" }}
          >
            support@eqman.co
          </a>
        </div>
      </SwipeableDrawer>
      <main className={clsx(classes.content)}>
        <TopbarContext.Provider
          value={{ setPageName: () => {}, setTopActions: setActions }}
        >
          <MenuProvider.Provider
            value={{
              toggleMenu: drawer.handleToggle,
              setPageOptions,
            }}
          >
            {props.children}
          </MenuProvider.Provider>
        </TopbarContext.Provider>
      </main>
    </div>
  );
};

export default withRouter(TopbarMobile);
