import React, {useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {CircularProgress, Input as SelectInput, MenuItem, Select, Theme} from "@material-ui/core";
import GlobalService from '@core/services/global';
import Input from "./Input";
import {rules} from "@core/utils/validation";
import classNames from "classnames";
import {clearPhoneString} from "@core/utils/string";
import {removeRepeatable} from "@core/utils/global";
import {useMappedState} from "redux-react-hook";
import {statsSelector} from "@core/store/selectors/config";
import {normalizePhone} from "@core/utils/normalize";
import {IInputComponentProps} from "@core/hooks/usePhoneInput";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    position: "relative",
    zIndex: 100,
    marginTop: -100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    },
    '& h3.text-default, & h3.text-default + p' : {
      textAlign: 'center'
    }
  },
  inputMain: {
    boxSizing: 'border-box',
    '& input': {
      paddingTop: 35,
    },
    '& .MuiSelect-root': {
      backgroundColor: 'transparent !important',
    }
  },
  card: {
    padding: '20px'
  },
  img: {
    width: '70%',
    display: 'block',
    margin: '0 auto'
  },
  selectOption: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  countryFlag: {
    width: 30
  },
  select: {
    backgroundColor: "transparent !important"
  },
  selectCode: {
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 10,
  },
  selectInput: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 25,
    '&:after, &:before': {
      display: "none"
    },
    "& span": {
      // display: "none"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const PhoneInput = (props: {
  input: IInputComponentProps,
  className?: string,

}) => {
  const {input, className} = props;
  const classes = useStyles();
  const [countries, setCountries] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [currentCountryCode, setCurrentCountryCode] = useState<any>('UA');
  const { country, countryCodes } = useMappedState(statsSelector);

  const searchInCountriesByCode = useCallback((codeStr: string) => {
    return countries.find((item: any) => item.callingCodes.includes(codeStr))
  }, [countries]);

  const {setValue: setPhoneValue, setError: setPhoneError} = input;
  const defaultValue = input.defaultValue;
  const phoneInput = input;


  const focusOnInput = useCallback(() => {
    setTimeout(() => {
      const phone: any = document.querySelector('#phone-input');
      if(phone) {
        phone.focus();
      }
    }, 0)
  }, [])

  const onKeyPress = (e: React.KeyboardEvent<any>) => {
    if(e.keyCode === 8 && !input.value && input.code) {
      input.setCode('');
      focusOnInput();
    }
  }
  const findCountryCodeByPhone = useCallback((phone: string) => {
    let search = phone[0];
    let result;
    for(let i = 0; i < 4; i++) {
      const current = searchInCountriesByCode(search);
      if(current) {
        result = current;
      }
      search += phone[i+1];
    }
    return result;
  }, [searchInCountriesByCode])

  const handleChange = useCallback((e: any) => {
    input.onChange(e);
    const search = e.target.value.replace(/[-]?[ ]?[+]?/gm, '').trim();
    if(search && search.length <= 4) {
      const current = searchInCountriesByCode(input.code.replace('+', '') + search.slice(0,3));
      if(current) {
        const code = current.callingCodes.find((callingCode: string) => callingCode === (input.code+search).replace(/[-]?[ ]?[+]?/gm, '').trim());
        setCurrentCountryCode(current.alpha2Code);
        input.setCode('+'+(code || current.callingCodes[0]))
        input.setValue('');
        focusOnInput();
      }
    }
  }, [input, focusOnInput]);

  const setDefaultCountry = useCallback((country: string, array: any[]) => {
    let current = array.find(cnt => cnt.alpha2Code === country);
    const value = input.value ? normalizePhone(input.getFullValue()).replace('+', '').slice(0,3) : '';
    let code = '';
    if(value) {
      for(let i = 0; i <= value.length; i++) {
        const cnt = array.find((item: any) => {
          const index = item.callingCodes.indexOf(value.slice(0, i+1));
          if(index !== -1) {
            code = item.callingCodes[index];
          }
          return index !== -1;
        });
        if(cnt) {
          current = cnt
        }
      }
    }

    if(current) {
      setCurrentCountryCode(current.alpha2Code || country);
      input.setCode('+'+(code || current.callingCodes[0]));
    } else {
      input.setCode("+380");
    }
  }, [defaultValue, phoneInput]);

  const load = useCallback(async () => {
    setLoading(true);
    if(defaultValue) {
      setPhoneError(rules.phone(defaultValue));
    }
    try {
      const result: any = [];
      countryCodes.forEach((item: any) => {
        result.push({
          value: item.alpha2Code,
          label: item.name,
          ...item,
        })
      })
      setCountries(result);
      input.setCode(result[0].callingCodes[0]);
      setDefaultCountry(country, result);
    } catch(E) {
      console.log(E);
    }
    setLoading(false);
  }, [setDefaultCountry, setPhoneError, setPhoneValue, defaultValue, country]);

  useEffect(() => {
    if(countries.length === 0 && !loading) {
      load();
    }
  }, [load, countries, loading]);

  const changeCountry = useCallback((value: number) => {
    const country = countries.find((item: any) => item.alpha2Code === value);
    input.setCode(`+${country.callingCodes[0]}`);
    setCurrentCountryCode(value);
  }, [phoneInput, countries]);


  const handlePaste = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.clipboardData.items[0].getAsString((text: string) => {
      const value = clearPhoneString(text);
      const code = value.slice(0, input.code.length);
      if(input.code && code === clearPhoneString(input.code)) {
         input.setValue(value.slice(input.code.length));
      } else {
        const country = findCountryCodeByPhone(clearPhoneString(text));
        if(country) {
          input.setCode('+'+country.callingCodes[0]);
          setCurrentCountryCode(country.alpha2Code);
          input.setValue(value.slice(country.callingCodes[0].length))
        } else {
          input.setValue(value);
        }
      }
    })

  }, [input, findCountryCodeByPhone])

  return (
    countries.length > 0 ? <Input
      {...input}
      id="phone-input"
      type="number"
      className={classNames(classes.inputMain, className)}
      onChange={handleChange}
      value={input.value.replace(new RegExp(`${input.code.replace('+', '\\+')}`), '')}
      onKeyDown={onKeyPress}
      onPaste={handlePaste}
      InputProps={{
        startAdornment: (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Select
              onChange={(e: any) => changeCountry(e.target.value)}
              style={{backgroundColor: 'transparent'}}
              variant="filled"
              value={currentCountryCode}
              input={<SelectInput className={classes.selectInput}/>}
              MenuProps={MenuProps}
              renderValue={(value: any) => (
                <img src={countries.find((i:any) => i.alpha2Code === value).flag} alt="" className={classes.countryFlag}/>
              )}
            >
              {
                countries.map((country: any) => {
                  return removeRepeatable(country.callingCodes).map((code: string) => (
                    <MenuItem value={country.alpha2Code} className={classes.selectOption}>
                      <span className={classes.selectCode}>{code}</span>
                    </MenuItem>
                  ))
                })
              }
            </Select>
            {input.code && <span className="badge default" style={{marginRight: 10, marginTop: 25}}>{input.code}</span>}
          </div>
        )
      }}/> : <CircularProgress/>
  )
}

export default PhoneInput;