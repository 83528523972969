import {IAction} from "../actions";
import {DatafileActions} from "../actions/datafile";


const initialState = {
  data: null
}

const DatafilesReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case DatafileActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state;
  }
}

export default DatafilesReducer;