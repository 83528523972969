import {useCallback, useEffect, useState} from "react";
import PaymentService from "@core/services/payment";
import notify from "@core/utils/notify";
import {useDispatch, useMappedState} from "redux-react-hook";
import {IPayment, IPlan, PaymentDuration, PaymentMethod} from "@core/types";
import {Dispatch} from "redux";
import {IActionWithErrorAndSuccess} from "@core/store/actions";
import {PaymentActions} from "@core/store/actions/payment";
import {createEvent} from "@shared/Common/GAEvent";
import Service from "@core/services/payment";

interface IState {
  plan: string | null,
  method: PaymentMethod,
  duration: 1 | 6 | 12,
  company: string | null
}

const usePayment = () => {
  const {company} = useMappedState((state) => ({company: state.stats.company}));
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [duration, setDuration] = useState<1 | 12>(12);
  const dispatch = useDispatch<Dispatch<IActionWithErrorAndSuccess>>();

  useEffect(() => {
    PaymentService.getPlans().then((data) => {
      setPlans(data);
    }).catch(() => {
      notify.error('Error getting plans');
    })
  }, []);

  const createPayment = useCallback((plan: string, promo: string) => {
    dispatch({
      type: PaymentActions.CREATE_PAYMENT,
      payload: {
        duration,
        method: duration === PaymentDuration.MONTHLY ? PaymentMethod.SUBSCRIPTION : PaymentMethod.FIXED,
        plan,
        company: company._id,
        promo
      },
      sideEffectSuccess: (data: IPayment) => {
        document.location.replace(data.checkoutUrl);
      },
      sideEffectError: () => {
        notify.error('Ошибка создания платежа')
      }
    })
  }, [company, duration, dispatch]);

  const toggleDuration = useCallback(() => {
    setDuration(duration === 1 ? 12 : 1);
    createEvent({
      category: 'Payment',
      action: 'PaymentPriceMonthYear'
    })
  }, [duration]);

  const getPlanIndex = useCallback((plan: IPlan) => {
    for(let i in plans) {
      if(plans[i]._id === plan._id) {
        return i;
      }
    }
    return -1;
  }, [plans]);

  const getPlanUpdateType = useCallback((plan: IPlan) => {
    const currentIndex = getPlanIndex(company.plan);
    const requestIndex = getPlanIndex(plan);

    return currentIndex < requestIndex ? 'Upgrade' : 'Downgrade'
  }, [company]);

  return {
    plans,
    company,
    createPayment,
    toggleDuration,
    duration,
    getPlanUpdateType
  }
}

export default usePayment;