import {IAction} from "../actions";
import {SettingsActions} from "../actions/settings";
import {getLang} from "@core/utils/global";


export interface CreateItemSectionStatusState {
  info: boolean,
  additional: boolean,
  location: boolean,
  responsible: boolean
}
export interface SettingsCompanyState {
  createItemSectionsStatus: CreateItemSectionStatusState,
  operationsWithApprovement: boolean;
  promo?: any;
}

export interface SettingsState {
  menuOpen: boolean;
  emailAlertOpen: boolean;
  company: SettingsCompanyState;
  language: string;
  tutorial: any;
}

const initialState: SettingsState = {
  menuOpen: true,
  emailAlertOpen: true,
  company: {
    createItemSectionsStatus: {
      info: true,
      additional: true,
      location: true,
      responsible: true
    },
    operationsWithApprovement: true,
  },
  language: getLang(),
  tutorial: {
    open: false,
    active: !!localStorage.getItem('tutorialOpen'),
    currentStep: 0
  },
};

const SettingsReducer = (state = initialState, action: IAction): SettingsState => {
  switch (action.type) {
    case SettingsActions.SET_SETTINGS:
      return {
        ...state,
        ...action.payload
      }
    case SettingsActions.ACTIVATE_TUTORIAL:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          active: true,
          currentStep: 0
        }
      }
    case SettingsActions.START_TUTORIAL:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          open: true
        }
      }
    case SettingsActions.STOP_TUTORIAL:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          open: false
        }
      }
    case SettingsActions.FINISH_TUTORIAL:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          open: false,
          currentStep: 0,
          active: false
        }
      }
    case SettingsActions.CHANGE_LANG:
      return {
        ...state,
        language: action.payload
      }
    case SettingsActions.SET_ITEM_CREATE_SECTIONS:
      return {
        ...state,
        company: {
          ...state.company,
          createItemSectionsStatus: {
            ... state.company.createItemSectionsStatus,
            [action.payload.key]: action.payload.value
          }
        }
      }
    default:
      return state
  }
};

export default SettingsReducer;
