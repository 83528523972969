export default [
  {
    "name":"Ukraine",
    "alpha2Code":"UA",
    "callingCodes":[
      "380"
    ],
    "flag":"https://flagcdn.com/w320/ua.png"
  },
  {
    "name":"Russian Federation",
    "alpha2Code":"RU",
    "callingCodes":[
      "7"
    ],
    "flag":"https://flagcdn.com/w320/ru.png"
  },
  {
    "name":"Belarus",
    "alpha2Code":"BY",
    "callingCodes":[
      "375"
    ],
    "flag":"https://flagcdn.com/w320/by.png"
  },
  {
    "name":"Kazakhstan",
    "alpha2Code":"KZ",
    "callingCodes":[
      "76",
      "77"
    ],
    "flag":"https://flagcdn.com/w320/kz.png"
  },
  {
    "name":"Netherlands",
    "alpha2Code":"NL",
    "callingCodes":[
      "31"
    ],
    "flag":"https://flagcdn.com/w320/nl.png"
  },
  {
    "name":"Germany",
    "alpha2Code":"DE",
    "callingCodes":[
      "49"
    ],
    "flag":"https://flagcdn.com/w320/de.png"
  },
  {
    "name":"Poland",
    "alpha2Code":"PL",
    "callingCodes":[
      "48"
    ],
    "flag":"https://flagcdn.com/w320/pl.png"
  },
  {
    "name":"Lithuania",
    "alpha2Code":"LT",
    "callingCodes":[
      "370"
    ],
    "flag":"https://flagcdn.com/w320/lt.png"
  },
  {
    "name": "Kyrgyzstan",
    "alpha2Code": "KG",
    "callingCodes": [
      "996"
    ],
    "flag":"https://flagcdn.com/w320/kg.png"
  },
  {
    "name": "Estonia",
    "alpha2Code": "EE",
    "callingCodes": [
      "372"
    ],
    "flag":"https://flagcdn.com/w320/ee.png"
  },
  {
    "name": "Uzbekistan",
    "alpha2Code": "UZ",
    "callingCodes": [
      "998"
    ],
    "flag":"https://flagcdn.com/w320/uz.png"
  },
  {
    "name": "Moldova",
    "alpha2Code": "MD",
    "callingCodes": [
      "373"
    ],
    "flag":"https://flagcdn.com/w320/md.png"
  }
]