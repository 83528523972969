import {IAction} from "../actions";
import {RequestActions} from "../actions/request";

export enum RequestPageType {
  MARK_METHODS = 0,
  CURRENT_REQUEST = 1,
  REQUESTS = 2,
}

const initialData = {
  data: [],
  markMethods: [],
  type: RequestPageType.MARK_METHODS,
  filters: {
    search: '',
    status: {title: '', value: ''},
    sort: -1
  },
  pagination: {
    limit: 10,
    offset: 0
  },
  total: 0,
  loading: false,
  item: null,
  order: []
};

const RequestReducer = (state: any = initialData, action: IAction) => {
  switch (action.type) {
    case RequestActions.SET_ITEMS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        total: action.payload.total
      }
    case RequestActions.SET_MARK_METHODS:
      return {
        ...state,
        markMethods: action.payload,
      }
    case RequestActions.SET_TYPE:
      return {
        ...state,
        type: action.payload
      }
    case RequestActions.CLEAR_ITEMS:
      return {
        ...initialData
      }
    case RequestActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    case RequestActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      }
    case RequestActions.SET_ITEM:
      return {
        ...state,
        item: action.payload
      }
    case RequestActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case RequestActions.ADD_TO_ORDER:
      const itemInOrder = state.order.find((item: any) => item.type === action.payload.type);
      let order;
      if(itemInOrder) {
        order = state.order.map((item: any) => {
          if(item.type === action.payload.type) {
            return {
              ...item,
              count: item.count + action.payload.count
            }
          }
          return item;
        })
      } else {
        order = [action.payload, ...state.order]
      }
      return {
        ...state,
        order
      }
    case RequestActions.UPDATE_ORDER:
      return {
        ...state,
        order: state.order.map((item: any) => {
          if(item.type === action.payload.type) {
            return {
              type: action.payload.type,
              count: action.payload.count
            }
          }
          return item;
        })
      }
    case RequestActions.REMOVE_FROM_ORDER:
      const newOrder = state.order.filter((item: any) => item.type !== action.payload)
      return {
        ...state,
        order: newOrder
      }
    case RequestActions.CLEAR_ORDER:
      return {
        ...state,
        order: []
      }
    case RequestActions.CLEAR_ITEM:
      return {
        ...state,
        item: null
      }
    default:
      return state;
  }
}

export default RequestReducer;