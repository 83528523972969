import { IAction } from "@core/store/actions";
import { IntegrationActions } from "@core/store/actions/integration";

const initialState = {
  data: null,
  loading: true,
};

const IntegrationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case IntegrationActions.SET_DATA:
      return {
        ...state,
        data: action.payload.data,
      };
    case IntegrationActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case IntegrationActions.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default IntegrationReducer;
