import React, {useCallback, useEffect, useState} from "react";
import {Button, makeStyles, Theme} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useDispatch, useMappedState} from "redux-react-hook";
import {AuthAction} from "@core/store/actions/auth";
import notify from "@core/utils/notify";
import {useTranslation} from "react-i18next";
import {SettingsActions} from "@core/store/actions/settings";
import GAEvent, {createEvent} from "@shared/Common/GAEvent";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiAlert-message': {
      flexGrow: 1,
      width: '100%'
    }
  }
}))

const EmailAlert = () => {
  const classes = useStyles();
  const {user} = useMappedState((state) => (state.auth));
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [sent, setSent] = useState(false)

  useEffect(() => {
    createEvent({category: 'Header', action: 'HeaderWarningConfirmEmail'})
  }, [])

  const handleResend = useCallback(() => {
    dispatch({
      type: AuthAction.SEND_EMAIL
    });
    setSent(true);
    notify.success(t('resend_email_notify'))
  }, [t, dispatch]);

  const handleClose = useCallback(() => {
    dispatch({
      type: SettingsActions.SET_SETTINGS,
      payload: {
        emailAlertOpen: false
      }
    })
  }, [dispatch]);

  return (
    user ? <Alert
      className={classes.root}
      severity="error"
      onClose={handleClose}
      closeText={'ASD'}
    >
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {t('resend_email_alert')}
        {!sent ?
          <GAEvent category="Header" action="HeaderResendConfirmEmail">
            <Button variant="outlined" size="small" onClick={handleResend}>{t('resend_email_button')}</Button>
          </GAEvent>
          :
          t('resend_email_notify')
        }
      </div>
    </Alert> : <div></div>
  )
}

export default EmailAlert;