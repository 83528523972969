import React, {useCallback, useEffect, useState} from "react";
import AuthLayout from "../../../layout/Auth";
import { RouteComponentProps } from "react-router-dom";
import Auth from "@core/services/auth";
import {CircularProgress, Theme, Button} from "@material-ui/core";
import Card from "@shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import UserInfo from "@shared/Widgets/User/UserInfo";
import {IUser} from "@core/types";
import useDropdown from "@core/hooks/useDropdown";
import StaticDropdown from "@shared/Common/Dropdown/Static";
import useInput from "@core/hooks/useInput";
import Input from "@shared/Common/Form/Input";
import {isFormSubmitDisabled} from "@core/utils/form";
import {useDispatch} from "redux-react-hook";
import {Dispatch} from "redux";
import {IActionWithSingleSideEffect} from "@core/store/actions";
import {AuthAction} from "@core/store/actions/auth";
import {useTranslation} from "react-i18next";
import useMode from "@core/hooks/useMode";
import Download from "./Download";
import useTutorial from "@core/hooks/useTutorial";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800
  }
}));

type IMode = "default" | "success";

const FinishRegistration = (props: RouteComponentProps) => {
  const {location, history} = props;
  const {t} = useTranslation();
  const classes = useStyles();
  const [token, setToken] = useState<string>('');
  const [user, setUser] = useState<IUser>();
  const {activate: activateTutorial} = useTutorial();


  const {changeMode, mode} = useMode<IMode>({defaultMode: 'default'});

  if(Auth.isLoggedIn()) {
    history.push('/');
  }

  const processLoading = useCallback(async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    if(!token) {
      history.push('/auth/login');
      return
    }

    const authDetails = await Auth.checkToken(token);
    if(!authDetails) {
      history.push('/auth/login');
      return
    }
    setToken(token);
    setUser(authDetails.user);
  }, [location, history]);

  useEffect(() => {
    if(!token) {
      processLoading();
    }
  }, [token, processLoading]);

  const dispatch = useDispatch<Dispatch<IActionWithSingleSideEffect>>();
  const dropdown = useDropdown();

  const password = useInput({
    type: "password",
    label: t('password'),
    required: true
  });

  const passwordRepeat = useInput({
    type: "password",
    label: t('repeat'),
    required: true,
    onChangeHook: (value: string) => {
      if(value !== password.value) {
         passwordRepeat.setError(true);
         passwordRepeat.setHelperText(t("passwords_not_match"))
      } else {
        passwordRepeat.setError(false);
        passwordRepeat.setHelperText('')
      }
    }
  });

  const handleSubmit = useCallback(() => {
    dispatch({
      type: AuthAction.FINISH_REGISTRATION,
      payload: {token, password: password.value},
      sideEffect: () => {
        activateTutorial();
        changeMode('success');
      }
    })
  }, [token, password, dispatch, changeMode, activateTutorial]);

  return (
    <AuthLayout>
      {
        !token && <div className="preloader-main"><h3>Eqman</h3><CircularProgress/></div>
      }
      {
        user && mode === 'default' && <Card title={t("finish_registration")} className={classes.root}>
          <UserInfo name={user.firstName} email={user.lastName} badge={user.role} badgeColor="green"/>
          <div className="info-list">
            <div className="list-item small">
              <span className="label">{t('email')}</span>
              <span className="value">{user.email}</span>
            </div>
            <div className="list-item small">
              <span className="label">{t('phone')}</span>
              <span className="value">{user.phone}</span>
            </div>
          </div>
          <div className="actions-list">
            {!dropdown.open && <div className="list-item small" onClick={dropdown.handleOpen}>
              <span className="title">{t("set_password")}</span>
            </div>}
          </div>
          <StaticDropdown {...dropdown} depth={0}>
            <h3 className="text-default">{t("fill_pass")}</h3>
            <p className="subtitle">{t("fill_pass_desc")}</p>
            <div>
              <Input {...password} size="small"/>
              <Input {...passwordRepeat} size="small"/>
              <Button
                type="button"
                disabled={isFormSubmitDisabled({password, passwordRepeat}) || password.value !== passwordRepeat.value}
                color="secondary"
                onClick={handleSubmit}
              >
                {t("finish")}
              </Button>
            </div>
          </StaticDropdown>
        </Card>
      }
      {
        mode === 'success' && <Download/>
      }
    </AuthLayout>
  )
};

export default FinishRegistration;
