import TextField from "@material-ui/core/TextField/TextField";
import React, { useCallback, useState } from "react";
import InputBase from "@material-ui/core/InputBase/InputBase";
import Icon from "@material-ui/core/Icon/Icon";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { IInputComponentProps } from "../../../core/hooks/useInput";
import classNames from "classnames";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    position: "relative",
    minWidth: 320,
    [theme.breakpoints.down(320)]: {
      minWidth: "100%",
    },
  },
  hideBtn: {
    position: "absolute",
    top: 32,
    right: 10,
    color: "#9c9c9c",
    cursor: "pointer",
    "&.small": {
      top: 28,
      fontSize: 16,
    },
  },
  small: {
    "& input": {
      fontSize: 14,
    },
  },
}));

const Input = (propsInitial: IInputComponentProps) => {
  const {
    errorText: helper,
    setError,
    setHelperText,
    setValue,
    onlyInt,
    validate,
    classNameInput,
    ...props
  } = Object.assign({}, propsInitial);

  const classes = useStyles();
  const variant = props.variant || "filled";
  const color = props.color || "secondary";
  const [type, setType] = useState(props.type || "text");
  const [showHideBtn] = useState(props.type === "password");

  const handleShowPassword = useCallback(() => {
    if (!props.value) {
      return;
    }
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }, [type, props.value]);
  return props.naked ? (
    <InputBase {...props} />
  ) : (
    <div
      className={classnames(classes.input, props.className, {
        [classes.small]: props.size === "small",
      })}
    >
      <TextField
        {...props}
        type={type}
        fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
        margin="normal"
        variant={variant}
        color={color}
        helperText={helper}
        className={propsInitial.classNameInput}
      />
      {showHideBtn && (
        <Icon
          className={classNames(classes.hideBtn, props.size || "")}
          onClick={handleShowPassword}
        >
          {type === "password" ? "visibility" : "visibility_off"}
        </Icon>
      )}
    </div>
  );
};
export default Input;
