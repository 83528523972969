import * as React from 'react'

const useSnackbar = () => {
  const [open, toggleOpen] = React.useState(false);
  const [type, setType] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [details, setDetails] = React.useState({});

  const handleClose = React.useCallback(() => {
    toggleOpen(false)
  }, []);

  const handleOpen = React.useCallback((message: string, type: string, detail: any) => {
    toggleOpen(true);
    setDetails(detail);
    setMessage(message);
    setType(type);
  }, []);

  const handleOpenFromEvent = React.useCallback(
    (options: any) => {
      const message = options.detail ? options.detail.message : '';
      const type = options.detail ? options.detail.type : 'default';
      handleOpen(message, type, options.detail.data)
    },
    [handleOpen]
  );

  return {
    open,
    details,
    handleClose,
    handleOpen,
    message,
    type,
    handleOpenFromEvent
  }
};
export default useSnackbar;
