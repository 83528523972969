import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import Card from "@shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import {Button, Theme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PhoneInput from "@shared/Common/Form/PhoneInput";
import {IUser} from "@core/types";
import {rules} from "@core/utils/validation";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    marginTop: -100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    },
    '& h3.text-default, & h3.text-default + p' : {
      textAlign: 'center'
    }
  },
  card: {
    padding: '20px'
  },
  img: {
    width: '70%',
    display: 'block',
    margin: '0 auto'
  },
  selectOption: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  countryFlag: {
    width: 30
  },
  select: {
    backgroundColor: "transparent !important"
  },
  selectCode: {
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 10
  },
  selectInput: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 5,
    '&:after, &:before': {
      display: "none"
    },
    "& span": {
      display: "none"
    }
  }
}));

interface IProps {
  user: IUser,
  sendSms: (phone: string) => void,
}

const RegistrationSMSRepeat = (props: IProps) => {
  const {
    user: {phone},
    sendSms
  } = props;
  const {t} = useTranslation();
  const classes = useStyles();
  const [phoneValue, setValue] = useState(phone);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if(phone) {
      setError(rules.phone(phone))
    }
  }, [phone])

  const phoneChange = useCallback((value: string) => {
    setError(rules.phone(value));
    setValue(value);
  }, []);

  const handleSend = useCallback(() => {
    sendSms(phoneValue);
  }, [sendSms, phoneValue])

  return (
    <Card className={classNames(classes.root, classes.card)}>
      <img src={img('sms.jpg')} alt="" className={classes.img}/>
      <h3 className="text-default">Отправить SMS-сообщение </h3>
      <p className="subtitle">Перейдите на ваш почтовый ящик и в подтвердите ваш email нажав на ссылку в письме</p>
      <div className="actions">
        <Button color="secondary" disabled={!phoneValue || hasError} onClick={handleSend}>{t('send_sms')}</Button>
      </div>
    </Card>
  )
}

export default RegistrationSMSRepeat;