import React, {PropsWithChildren, ReactNode, Ref} from 'react';
import {Paper, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 30,
    backgroundColor: '#fff',
    boxShadow:'0 0 13px 0 rgba(82,63,105,.1)',
    borderRadius: 7,
    margin: 10,
    zIndex: 10,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 15px',
    }
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    }
  },
  actionIcon: {
    fontSize: '18px !important',
    cursor: 'pointer'
  },
  titleActions: {

  },
  body: {
    width: '100%'
  },

}));

interface ICardProps {
  title?: string | ReactNode,
  actions?: ReactNode,
  className?: string,
  classes?: any,
  ref?: Ref<any>,
  onClick?: (e: any) => void,
  style?: any;
}

const Card = (props: PropsWithChildren<ICardProps>) => {
  const {ref} = props;
  const classes = useStyles();
  return (
    <Paper ref={ref} style={props.style || {}} className={classNames(classes.root, props.className)} elevation={3} onClick={props.onClick ? props.onClick : () => {}}>
      {props.title && <div className={classNames(classes.title, props.classes ? props.classes.title : '')}>
        {props.title}
        {props.actions && <div>{props.actions}</div>}
      </div>}
      <div className={classNames(classes.body, props.classes ? props.classes.body : '')}>
        {
          props.children
        }
      </div>
    </Paper>
  )
};
export default Card;
