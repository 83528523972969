import useDropdown from "@core/hooks/useDropdown";
import { SettingsActions } from "@core/store/actions/settings";
import { Button, MenuItem } from "@material-ui/core";
import Dropdown from "@shared/Common/Dropdown";
import React, { MouseEvent, useCallback } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";

export const LangSettings = () => {
  const dispatch = useDispatch();

  const { lang } = useMappedState((state) => ({
    lang: state.settings.language,
  }));

  const dropdown = useDropdown({
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  });

  const changeLanguage = useCallback(
    (lang: string) => {
      localStorage.setItem("lang", lang);
      dispatch({
        type: SettingsActions.CHANGE_LANG,
        payload: lang,
      });
    },
    [dispatch]
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      const { dataset } = e.target as HTMLLIElement;
      console.log(dataset);
      const { value } = dataset;

      if (value) {
        changeLanguage(value);
      }
      dropdown.handleClose();
    },
    [changeLanguage, dropdown]
  );

  return (
    <div style={{ margin: "0 5px" }}>
      <Button
        size="small"
        style={{
          color: "#fff",
          fontWeight: "bold",
          padding: "3px 7px",
          minWidth: "auto",
        }}
        onClick={dropdown.handleOpen}
      >
        {lang}
      </Button>
      <Dropdown {...dropdown}>
        <MenuItem data-value="ru" onClick={handleClick}>
          RU
        </MenuItem>
        <MenuItem data-value="en" onClick={handleClick}>
          EN
        </MenuItem>
        <MenuItem data-value="pl" onClick={handleClick}>
          PL
        </MenuItem>
        <MenuItem data-value="ua" onClick={handleClick}>
          UA
        </MenuItem>
      </Dropdown>
    </div>
  );
};
