import { IInputComponentProps } from "@core/hooks/useInput";
import { statsSelector } from "@core/store/selectors/config";
import React, { useCallback, useState } from "react";
import PhoneInput, { Country } from "react-phone-number-input";
import { useMappedState } from "redux-react-hook";
import InputPhone from "./InputPhone";
import "./InputPhone.scss";

const Phone = (
  props: IInputComponentProps & {
    onCountryChange?: (country: string | undefined) => void;
  }
) => {
  const { value, setValue, setError, setHelperText, onCountryChange } = props;
  const { country } = useMappedState(statsSelector);
  const [currentCountryCode, setCurrentCountryCode] = useState<any>("UA");

  const handleChange = useCallback(
    (newValue: string = "") => {
      setError(false);
      setHelperText("");
      setValue(newValue);
    },
    [setValue]
  );
  return (
    <PhoneInput
      {...(props as any)}
      defaultCountry={country}
      international
      value={value}
      inputComponent={InputPhone as any}
      onChange={handleChange}
      onCountryChange={onCountryChange}
      onBlur={props.onBlur}
    />
  );
};

export default Phone;
