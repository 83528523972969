import axios from "axios";

export default {
  getCountries: async () =>
    (
      await axios.get(
        "https://restcountries.eu/rest/v2/alpha?codes=ua;ru;by;kz;nl;de;pl;lt;ky;ee"
      )
    ).data,
  getCountryByCode: async (code: string) => {
    try {
      const { data } = await axios.get(
        `https://restcountries.com/v3.1/alpha?codes=${code}`
      );
      return data && data.length > 0 ? data[0] : null;
    } catch (E) {
      return undefined;
    }
  },
};
