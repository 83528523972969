import React, {useCallback, useEffect, useState} from "react";
import { RouteComponentProps } from "react-router-dom";
import AuthLayout from "../../../layout/Auth";
import {useDispatch} from "redux-react-hook";
import {AuthAction} from "../../../../core/store/actions/auth";
import useMode from "../../../../core/hooks/useMode";
import RegistrationEmailMessage from "./Actions/Email";
import RegistrationSMSRepeat from "./Actions/SMS";
import {IUser} from "../../../../core/types";
import VerifyCode from "./Actions/VerifyCode"
import PhoneVerificationSuccess from "./Actions/Success";
import {normalizePhone} from "../../../../core/utils/normalize";
import {CircularProgress} from "@material-ui/core";
import decode from 'jwt-decode';

type IMode = 'email' | 'sms' | 'verify' | 'success';

const Verification = (props: RouteComponentProps<any>) => {
  const {history} = props;
  const {mode, changeMode} = useMode<IMode>({defaultMode: "email"});
  const dispatch = useDispatch();
  const [user, setUser] = useState<IUser>();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    try {
      const authToken = localStorage.getItem('auth');
      const mode: any = localStorage.getItem('mode');

      if(!authToken) {
        history.push('/auth/login');
      }

      if(authToken) {
        setUser(decode(authToken));
        setToken(authToken);
      }

      if(mode) {
        changeMode(mode);
      }

    } catch(E) {
      history.push('/auth/login');
    }
  }, [changeMode, history]);

  const sendSms = useCallback((phone: string) => {
    if(user && token) {
      dispatch({
        type: AuthAction.SEND_SMS,
        payload: {
          token,
          phone: normalizePhone(phone)
        },
        sideEffect: () => {
          localStorage.setItem('mode', 'verify');
          changeMode('verify');
        }
      });
    }
  }, [user, token, changeMode, dispatch]);

  const verify = useCallback((code: string) => {
    if(!user || !token) return;
    dispatch({
      type: AuthAction.VERIFY_PHONE,
      payload: {
        token,
        code
      },
      sideEffect: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('mode');
        changeMode('success')
      }
    })
  }, [user, changeMode, token, dispatch]);

  return (
    <AuthLayout>
      {
        mode === 'default' && <CircularProgress/>
      }
      {
        mode === 'email' && user && <RegistrationEmailMessage
          user={user}
          goToSms={() => changeMode('sms')}
          withTimer
        />
      }
      {
        mode === 'sms' && user && <RegistrationSMSRepeat
          user={user}
          sendSms={sendSms}
        />
      }
      {
        mode === 'verify' && user && <VerifyCode user={user} back={() => changeMode('sms')} verify={verify}/>
      }
      {
        mode === 'success' && <PhoneVerificationSuccess/>
      }
    </AuthLayout>
  )
};

export default Verification;
