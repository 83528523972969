import React from "react";
import {Button, Paper, Theme} from "@material-ui/core";
import classNames from "classnames";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '30px 30px 20px 30px',
    position: "relative",
    zIndex: 100,
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    }
  },
  img: {
    width: '90%',
    margin: '0 auto',
    display: 'block'
  },
  actions: {
    marginTop: 20,
    textAlign: 'left'
  }
}))

const ForgotPasswordTimeError = (props: {back: () => void}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Paper className={classNames(classes.root, 'root')}>
      <img src={img('time.svg')} alt="" className={classes.img}/>
      <h3 className="text-default">{t('error_forgot_pass_time')}</h3>
      <p className="subtitle">{t('error_forgot_pass_time_sub')}</p>
      <div className={classes.actions}>
        <Button color="secondary" onClick={props.back}>{t('back')}</Button>
      </div>
    </Paper>
  )
}

export default ForgotPasswordTimeError;