import { IFiltersPagination } from "@core/types/store";
import axios from "../utils/axios";
import { normalizeFilters } from "../utils/normalize";

interface ICreateItem {
  title: string;
  locations?: string[];
}

interface IEditItem {
  title: string;
  locations?: { id?: string; title: string }[];
}

const BASE = `/company/get/locations`;

export default {
  getItems: async (filters: any, pagination: IFiltersPagination) =>
    (
      await axios.get(BASE, {
        params: { ...normalizeFilters(filters), ...pagination, withIds: 1 },
      })
    ).data,
  create: async (data: ICreateItem) => (await axios.post(BASE, data)).data,
  edit: async (id: string, data: IEditItem, shouldUpdate = false) =>
    (await axios.put(`${BASE}/${id}/edit`, data, { params: { shouldUpdate } }))
      .data,
  remove: async (id: string, shouldUpdate = false) =>
    (await axios.delete(`${BASE}/${id}`, { params: { shouldUpdate } })).data,
};
