export enum LocationActions {
  GET_ITEMS = 'get_location_items',
  SET_ITEMS = 'set_location_items',
  UPLOAD_ITEMS = 'upload_location_items',
  UPDATE_ITEMS = 'update_location_items',
  SET_FILTERS = 'set_location_filters',
  CLEAR_FILTERS = 'clear_location_filters',
  TOGGLE_FILTERS = 'toggle_location_filters',
  DELETE_FILTERS = 'delete_location_filters',
  SET_LOADING = 'set_location_items_loading',
  UPDATE_ITEM = 'updated_location_item',
  SET_PAGINATION = 'set_location_pagination',
  CREATE_ITEM = 'create_location_item',
  EDIT_ITEM = 'edit_location_item',
  REMOVE_ITEM = 'delete_location_item',
  CLEAR_ITEMS = 'clear_location_items'
}
