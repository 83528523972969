import React, {useCallback} from "react";
import {ISnackbarMessage} from "@shared/Common/Snackbar/index";
import Notification from "@shared/Common/Topbar/Notifications/Notification";
import {makeStyles, Theme} from "@material-ui/core";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '400px',
    [theme.breakpoints.down(400)]: {
      maxWidth: '100%'
    }
  }
}))

const SnackbarMessage = ({type, message, details, handleClose}: ISnackbarMessage) => {
  const classes = useStyles();
  const history = useHistory();

  const onClickMessage = useCallback((message: any) => {
    if(message.link) {
      history.push(message.link);
      handleClose();
    }
  }, [history, handleClose])

  return (
    <div className={classes.root}>
      {type !== 'notification'
        ?
          <span>{message}</span>
        : <>
          <Notification message={details} onClick={onClickMessage}/>
        </>
      }

    </div>
  )
}

export default SnackbarMessage;