export enum UsersActions {
  GET_ITEMS = 'get_user_items',
  GET_ITEMS_MOBILE = 'get_user_items_mobile',
  GET_ITEM = 'get_user_item',
  GET_ITEM_TXS = 'get_user_item_txs',
  SET_ITEM_TXS = 'set_user_item_txs',
  CLEAR_TXS_DATA = 'clear_user_item_txs',
  SET_ITEM_TXS_PAGINATION = 'set_user_item_txs_pagination',
  GET_ITEM_TRANSFERS = 'get_user_item_transfers',
  SET_ITEMS = 'set_user_items',
  SET_ITEMS_MOBILE = 'set_user_items_mobile',
  TOGGLE_FILTERS = 'toggle_user_filters',
  SET_FILTERS = 'set_user_filters',
  CLEAR_FILTERS = 'clear_user_filters',
  DELETE_FILTERS = 'delete_user_filters',
  SET_LOADING = 'set_user_items_loading',
  SET_TX_LOADING = 'set_user_items_txs_loading',
  SET_ITEM = 'set_user_item',
  UPDATE_ITEM = 'updated_user_item',
  CLEAR_ITEM = 'clear_user_item',
  SET_PAGINATION = 'set_user_pagination',
  CREATE_ITEM = 'create_user_item',
  EDIT_ITEM = 'edit_user_item',
  REMOVE_ITEM = 'delete_user_item',
  SEND_SMS_VERIFICATION = 'send_user_sms',
  SET_TRANSFERS = 'set_user_transfers',
  CLEAR_ITEMS = 'clear_user_items'
}
