import { useCallback, useState } from 'react'

type DefaultMode = 'default'

interface IModeProps<T> {
  defaultMode: T;
  onChangeHook?: (mode?: T) => void;
}

const useMode = <T>(props: IModeProps<T>) => {
  const { onChangeHook } = props
  const [mode, setMode] = useState<T | 'default'>(props.defaultMode)

  const changeMode = useCallback(
    (newMode: T) => {
      setMode(newMode)
      if (onChangeHook) {
        onChangeHook(newMode)
      }
    },
    [onChangeHook]
  )

  const back = useCallback(() => {
    setMode('default')
  }, [])

  return { mode, changeMode, back }
}

export default useMode
