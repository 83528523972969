import React, { FormEvent, useCallback, useEffect, useState } from "react";
import useInput from "@core/hooks/useInput";
import { Paper, Button, Theme, Icon } from "@material-ui/core";
import Input from "@shared/Common/Form/Input";
import { makeStyles } from "@material-ui/styles";
import { Link, RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthLayout from "../../../layout/Auth";
import service from "../../../../core/services/auth";
import { useDispatch, useMappedState } from "redux-react-hook";
import { AuthAction } from "@core/store/actions/auth";
import { normalizePhone } from "@core/utils/normalize";
import { useTranslation } from "react-i18next";
import Service from "@core/services/auth";
import notify from "@core/utils/notify";
import { Alert } from "@material-ui/lab";
import { validate } from "@core/utils/form";
import useTutorial from "@core/hooks/useTutorial";
import GAEvent from "@shared/Common/GAEvent";
import { deleteCookie, getCookie } from "@core/utils/string";
import ReactPixel from "react-facebook-pixel";
import Phone from "@shared/Common/Form/Phone/Phone";
import { statsSelector } from "@core/store/selectors/config";
ReactPixel.init("1035121083556872");

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "50px 50px",
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      padding: "20px",
    },
  },
  card: {
    padding: "20px",
  },
  img: {
    width: "70%",
    display: "block",
    margin: "0 auto",
  },
  description: {
    padding: "10px 0px",
  },
  googleBtn: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
    boxShadow:
      "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)",
    backgroundColor: "#4485f4",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4778cb",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 25,
  },
  error: {
    fontSize: 12,
    color: "#ff7f70",
    display: "block",
    margin: "0px 15px 20px 15px",
  },
}));

const Register = (props: RouteComponentProps) => {
  const { history } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [authTypeError, setAuthTypeError] = useState<string>("");
  const { activate: activateTutorial } = useTutorial();
  const token = localStorage.getItem("token");
  if (token) {
    history.push("/");
  }

  const firstName = useInput({
    type: "text",
    label: t("firstName"),
  });

  const email = useInput({
    label: t("email"),
    rule: "email",
    required: true,
    validate: (value: string) => {
      return value.indexOf(" ") !== -1;
    },
  });

  const password = useInput({
    type: "password",
    label: t("password"),
    required: true,
    errorText: t("min_pass"),
  });

  const phone = useInput({
    label: t("phone"),
    type: "phone",
    required: true,
  });

  const { country: defaultCountry } = useMappedState(statsSelector);
  const [country, setCountry] = useState<string | undefined>(defaultCountry);

  useEffect(() => {
    setCountry(defaultCountry);
  }, [defaultCountry]);

  const checkEmailAvailability = useCallback(async () => {
    if (email.error || email.value.length === 0) return;
    const isEmailUsed = await service.checkEmail(email.value);
    if (isEmailUsed) {
      email.setError(true);
      email.setHelperText("email_used");
    }
  }, [email]);

  const checkPhoneAvailability = useCallback(async () => {
    if (phone.error || phone.value.length === 0) return;
    const isPhoneUsed = await service.checkPhone(phone.value);
    if (isPhoneUsed) {
      phone.setError(true);
      phone.setHelperText("phone_used");
    }
  }, [email]);

  const handleSubmit = useCallback(
    (e: FormEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      const valid = validate({ email, phone, password });
      if (!valid) return;

      dispatch({
        type: AuthAction.REGISTER,
        payload: {
          firstName: firstName.value,
          email: email.value,
          password: password.value,
          phone: normalizePhone(phone.value),
          countryCode: country,
          referer: getCookie("Referer"),
        },
        sideEffectSuccess: (token: string) => {
          Service.setUserToken(token);
          deleteCookie("Referer");
          activateTutorial();
          ReactPixel.track("CompleteRegistration");
          history.push("/");
        },
        sideEffectError: (type: string, message: string) => {
          if (type === "phone") {
            phone.setHelperText(message);
            phone.setError(true);
          }
        },
      });
    },
    [
      firstName,
      email,
      password,
      phone,
      country,
      dispatch,
      history,
      activateTutorial,
    ]
  );

  const googleLogin = useCallback(
    async (data: any) => {
      try {
        const { token } = await Service.googleLogin(data.tokenId, {
          referer: getCookie("Referer"),
        });
        Service.setUserToken(token);
        activateTutorial();
        history.push("/");
      } catch (E) {
        const errorName = (E as any).response
          ? (E as any).response.data.message.name
          : "";
        if (errorName === "AuthTypeError") {
          setAuthTypeError("auth_type_google_error");
        }
        notify.error(t("login_error"));
      }
    },
    [history, t, activateTutorial]
  );

  return (
    <AuthLayout>
      <Helmet>
        <title>Eqman - Sign up</title>
        {process.env.NODE_ENV === "production" && (
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TQVH4L6');`}</script>
        )}
      </Helmet>
      <div className={classes.root}>
        <h2 style={{ fontWeight: 700 }}>Создание аккаунта</h2>
        {authTypeError && <Alert severity="error">{t(authTypeError)}</Alert>}
        <form onSubmit={handleSubmit} noValidate>
          <Input {...firstName} />
          <Input {...email} onBlur={checkEmailAvailability} />
          <Input {...password} />
          <Phone
            {...phone}
            onCountryChange={setCountry}
            onBlur={checkPhoneAvailability}
          />
          <GAEvent category="Registration" action="Registration">
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="secondary"
              type="submit"
              style={{ marginTop: 10 }}
            >
              {t("create_account")}
            </Button>
          </GAEvent>
          {/*<div style={{textAlign: "center", margin: '15px 0', fontWeight: 500, fontSize: 14}}>{t('or')}</div>*/}
          {/*<GoogleLogin*/}
          {/*   clientId="847850377337-1ncq9f8h420r59jpl8cnm13d5vr5ngsd.apps.googleusercontent.com"*/}
          {/*   buttonText="Auth with Login"*/}
          {/*   onSuccess={googleLogin}*/}
          {/*   onFailure={(error: any) => {console.log(error)}}*/}
          {/*   cookiePolicy={'single_host_origin'}*/}
          {/*   render={(renderProps: any) => (*/}
          {/*     <GAEvent category="Registration" action="RegistrationG">*/}
          {/*       <Button*/}
          {/*         className={classes.googleBtn}*/}
          {/*         onClick={renderProps.onClick}*/}
          {/*         style={{margin: 0}}*/}
          {/*         startIcon={ <Icon style={{*/}
          {/*           backgroundColor: '#fff',*/}
          {/*           borderRadius: '50%',*/}
          {/*           display: 'block',*/}
          {/*           padding: "5px",*/}
          {/*           width: '30px',*/}
          {/*           height: '30px',*/}
          {/*           lineHeight: 0.9*/}
          {/*         }}>{GoogleIcon}</Icon>}*/}
          {/*       >*/}
          {/*         {t('sign_up_google')}*/}
          {/*       </Button>*/}
          {/*     </GAEvent>*/}
          {/*   )}*/}
          {/* />*/}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 25,
            }}
          >
            <span>{t("have_account")}</span>
            <GAEvent category="Registration" action="RegistrationLogin">
              <Link to="/auth/login">
                <span
                  className="link green underline"
                  style={{ marginLeft: 10 }}
                >
                  {t("sign_in")}
                </span>
              </Link>
            </GAEvent>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Register;
