import {IAction} from "@core/store/actions";
import {AnalyticsActions} from "@core/store/actions/analytics";


const initialState = {
  data: {
    brands: [],
    models: [],
    types: [],
    objects: [],
    locations: [],
    customs: [],
    total: 0
  },
  filters: {
    brand: {title: '', value: '', values: []},
    model: {title: '', value: ''},
    title: {title: '', value: ''},
    type:  {title: '', value: ''},
    object: {title: '', value: '', values: []},
    location:  {title: '', value: ''},
    customs:  {title: '', value: ''},
    customsValue: ''
  },
  loading: true,
}

const AnalyticsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case AnalyticsActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload
      }
    case AnalyticsActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case AnalyticsActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    case AnalyticsActions.CLEAR_FILTERS:
      return  {
        ...state,
        filters: initialState.filters
      }
    case AnalyticsActions.CLEAR_DATA:
      return initialState
    default:
      return state;
  }
}

export default AnalyticsReducer;