import { combineReducers } from 'redux'
import AuthReducer from "./auth";
import StatsReducer from "./stats";
import ItemReducer from "./item";
import SettingsReducer from "./settings";
import UserReducer from "./users";
import StocktakingReducer from './stocktaking';
import CommentsReducer from "./comments";
import RequestReducer from "./request";
import DatafilesReducer from "./datafiles";
import PaymentReducer from "@core/store/reducers/payment";
import LocationReducer from "@core/store/reducers/locations";
import AnalyticsReducer from "@core/store/reducers/analytics";
import IntegrationReducer from "@core/store/reducers/integration";
import NotificationReducer from "@core/store/reducers/notification";


export default combineReducers({
  notifications: NotificationReducer,
  auth: AuthReducer,
  stats: StatsReducer,
  items: ItemReducer,
  comments: CommentsReducer,
  settings: SettingsReducer,
  users: UserReducer,
  stocktaking: StocktakingReducer,
  request: RequestReducer,
  files: DatafilesReducer,
  payments: PaymentReducer,
  locations: LocationReducer,
  analytics: AnalyticsReducer,
  integration: IntegrationReducer,
});

