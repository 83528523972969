import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

interface ButtonWithTimer {
  awaitTime: number,
  variant?: 'text' | 'contained' | 'outlined',
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  className?: string,
  color?: 'primary' | 'secondary'
}

const useStyles = makeStyles(() => ({
  timer: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 15,
    color: "#444"
  },
  width: {
    width: '42%'
  }
}))

const ButtonWithTimer = (props: PropsWithChildren<ButtonWithTimer>) => {
  const {
    variant = 'text',
    color = 'primary',
    awaitTime,
    onClick,
    className
  } = props;
  const classes = useStyles();
  const [time, setTime] = useState(awaitTime);

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    if(time === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    }
  }, [time]);

  const getTimeUnit = useCallback((number: number) => {
    return `${number >= 10 ? number : '0'+number}`
  }, []);

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if(time !== 0 || !onClick) return;
    onClick(e);
  }, [time, onClick]);

  return (
    <Button disabled={time !== 0} className={className ? className : classes.width} variant={variant} color={color} onClick={handleClick}>
      {props.children}
      {time !== 0 && <span className={classes.timer}>{getTimeUnit(Math.floor(time / 60))}:{getTimeUnit(time%60)}</span>}
    </Button>
  )
}

export default ButtonWithTimer;