import {ITypedAction} from "../actions";
import {IDefaultState, IFiltersPagination} from "../../types/store";
import {IUser, IUserFull} from "../../types";
import {UsersActions} from "../actions/users";

interface UsersState extends IDefaultState<IUser, IUserFull> {
  txs: {
    data: any,
    pagination: IFiltersPagination,
    count: number,
    loading: boolean
  }
  transfers: any[];
}

const initialState: UsersState = {
  data: [],
  filtersOpen: false,
  filters: {
    search: '',
  },
  pagination: {
    limit: 10,
    offset: 0
  },
  total: 0,
  loading: false,
  item: null,
  txs: {
    data: [],
    pagination: {limit: 10, offset: 0},
    count: 0,
    loading: false
  },
  transfers: []
};

const UserReducer = (state = initialState, action: ITypedAction<UsersActions>) => {
  switch (action.type) {
    case UsersActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total
      };
    case UsersActions.SET_ITEMS_MOBILE:
      return {
        ...state,
        data: [...(state.data || []), ...action.payload.data],
        total: action.payload.total,
      };
    case UsersActions.SET_ITEM_TXS:
      return {
        ...state,
        txs: {
          data: [...state.txs.data, ...action.payload.data],
          pagination: state.txs.pagination,
          count: action.payload.count,
          loading: state.txs.loading
        }
      }
    case UsersActions.SET_TRANSFERS:
      return {
        ...state,
        transfers: action.payload
      }
    case UsersActions.CLEAR_TXS_DATA:
      return {
        ...state,
        txs: initialState.txs
      }
    case UsersActions.SET_ITEM_TXS_PAGINATION:
      return {
        ...state,
        txs: {
          ...state.txs,
          pagination: action.payload,
        }
      }
    case UsersActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        data: [],
        pagination: initialState.pagination,
      };
    case UsersActions.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters
      };
    case UsersActions.DELETE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: initialState.filters[action.payload]
        }
      }
    case UsersActions.TOGGLE_FILTERS:
      return {
        ...state,
        filtersOpen: action.payload
      }
    case UsersActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case UsersActions.SET_ITEM:
      return {
        ...state,
        item: action.payload
      };
    case UsersActions.UPDATE_ITEM:
      return {
        ...state,
        item: action.payload
      }
    case UsersActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case UsersActions.SET_TX_LOADING:
      return {
        ...state,
        txs: {
          ...state.txs,
          loading: action.payload
        }
      };
    case UsersActions.CLEAR_ITEM:
      return {
        ...state,
        item: null
      };
    case UsersActions.CLEAR_ITEMS:
      return {
        ...state,
        data: [],
      };
    default:
      return {
        ...state
      }
  }
};

export default UserReducer;
