import React from 'react';
import { hotjar } from 'react-hotjar';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './featured';
import * as serviceWorker from './serviceWorker';
import { StoreContext } from 'redux-react-hook'
import { store } from '@core/store';

if(process.env.NODE_ENV === 'production') {
  hotjar.initialize(2026885, 6);
}

const AppContainer = (
  <StoreContext.Provider value={store}>
      <App />
  </StoreContext.Provider>
);

ReactDOM.render(AppContainer, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.registerFirebaseServiceWorker();

serviceWorker.unregister();
