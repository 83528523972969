export enum CommentsActions {
  GET_ITEMS = 'get_comments',
  SET_ITEMS = 'set_comments',
  SET_PAGINATION = 'set_pagination_comments',
  CREATE_ITEM = 'create_comment',
  EDIT_ITEM = 'edit_comment',
  UPDATE_ITEM = 'update_comment',
  ADD_ITEM = 'add_comment',
  CLEAR_ITEMS = 'clear_comments',
  SET_LOADING = 'set_comments_loading',
  DELETE_PHOTO = 'delete_comment_photo'
}
