import {EMAIL_REGEXP, FACEBOOK_REGEXP, URL_REGEXP, STR_NUMBER, NAME_REGEXP, PHONE, PRICE} from './regexp'


export const rules: {[key: string]: (value: string) => boolean} = {
  email: (value: string) => !value.toString().match(EMAIL_REGEXP) && value !== '',
  facebook_url: (value: string) => !value || !value.toString().match(FACEBOOK_REGEXP),
  url: (value: string) => !value.toString().match(URL_REGEXP),
  str_number: (value: string) => !value.toString().match(STR_NUMBER),
  username: (value: string) => !value.toString().match(NAME_REGEXP),
  full_name: (value: string) => value === '',
  phone_number: (value: string) => !value.toString().match(PHONE),
  password: (value: string) => value === '' || value.length < 4,
  date: (value: string) => value === '',
  string: (value: string) => value === '',
  phone: (value: string) => !value.toString().match(PHONE) && value !== '',
  price: (value: string) => !value.toString().match(PRICE),
  emailOrPhone: (value: string) => {
    if (value[0] === '+') return rules.phone(value);
    else return rules.email(value);
  }
};
