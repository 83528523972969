import axios from '../utils/axios';
import {IFiltersPagination} from "../types/store";
import {IRequestCodeData} from "../types";

interface IGetRequests extends IFiltersPagination {
  search: string,
  status: string,
  sort: 1 | -1,
}

export default {
  getMarkMethods: async () => (await axios.get('/request/mark-methods', {params: {lang: localStorage.getItem('lang') || 'ru'}})).data,
  getAll: async (filters: IGetRequests) => (await axios.get('/request/', {params: filters})).data,
  getOne: async (id: string) => (await axios.get('/request/' + id)).data,
  create: async (data: IRequestCodeData) => (await axios.post('/request', data)).data,
  createAndPublish: async (data: IRequestCodeData) => (await axios.post('/request/publish', data)).data,
  updateAndPublish: async (id: string, data: IRequestCodeData) => (await axios.put('/request/' + id + '/publish', data)).data,
  update: async (id: string, data: IRequestCodeData) => (await axios.put('/request/' + id, data)).data,
  reject: async (id: string) => (await axios.patch('/request/' + id, {})).data,
  getEndpoints: async (search: string, city: string) => {
    const {data} = await axios.get('/request/delivery/endpoints', {params: {search, city}});
    return data.map((item: any) => ({
      ...item,
      title: item.name,
      value: item.id
    }))
  },
  getCities: async (search: string) => {
    const {data} = await axios.get('/request/delivery/cities', {params: {search}});
    return data.map((item: any) => ({
      ...item,
      title: item.name,
      value: item.id
    }))
  },
  getStreets: async (search: string, city: string) => {
    const {data} = await axios.get('/request/delivery/streets', {params: {search, city}});
    return data.map((item: any) => ({
      ...item,
      title: item.name,
      value: item.id
    }))
  },
}