import {IAction} from "../actions";
import {AuthAction} from "../actions/auth";

const initialState: any = {
  token: ''
};

const AuthReducer = (state: any = initialState, action: IAction) => {
  switch (action.type) {
    case AuthAction.SET_USER:
      return {
        ...state,
        ...action.payload
      };
    case AuthAction.CLEAR_AUTH:
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;
