import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import {img} from "@core/utils/string";
import AccentButton from "@shared/Common/Button/AccentButton";
import {IPlan} from "@core/types";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Modal from "@shared/Common/Modal";
import useModal from "@core/hooks/useModal";
import useInput from "@core/hooks/useInput";
import Input from "@shared/Common/Form/Input";
import Service from '@core/services/payment';

const useStyles = makeStyles((theme: Theme) => ({
  plan: {
    position: 'relative',
    padding: '60px 0 40px 0',
    textAlign: 'center',
    height: '100%',
    width: '25%',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
    [theme.breakpoints.down(1420)]: {
      padding: '30px 0 20px 0',
    },
    [theme.breakpoints.down(670)]: {
      width: '50%',
      height: '250px',
      margin: '20px 0'
    },
    [theme.breakpoints.down(400)]: {
      width: '100%',
    },
  },
  planBody: {
    flexBasis: '80%',
    color: "#394252",
    '& h2': {
      textTransform: 'capitalize',
      fontWeight: 800,
      color: "#FB7C22",
      marginBottom: 20,
      [theme.breakpoints.down(1420)]: {
        fontSize: 20,
        marginTop: 10
      }
    },
  },
  price: {
    '& .prev-price': {
      fontSize: 16,
      textDecoration: 'line-through'
    },
    '& .price': {
      fontSize: 36,
      fontWeight: 900,
      [theme.breakpoints.down(1420)]: {
        fontSize: 24
      }
    },
    '& .period': {
      fontSize: 18,
      fontWeight: 400,
      [theme.breakpoints.down(1420)]: {
        fontSize: 14
      }
    },
    '& .peryear': {
      fontSize: 14,
      marginTop: 10,
      [theme.breakpoints.down(1420)]: {
        fontSize: 12
      }
    }
  },
  popular: {
    '& h2': {
      color: theme.palette.secondary.main,
    }
  },
  disabled: {
    '& h2': {
      color: '#B8CCDF'
    }
  },
  sticker: {
    position: "absolute",
    top: '-17px',
    width: 160,
    left: 'calc(50% - 80px)',
    [theme.breakpoints.down(1420)]: {
      width: 100,
      left: 'calc(50% - 50px)',
      top: '-10px',
    },
    [theme.breakpoints.down(670)]: {
      display: 'none',
    },
  },
  btn: {
    borderRadius: 50,
    [theme.breakpoints.down(1420)]: {
      fontSize: 12,
      padding: '7px 15px'
    }
  },
  action: {},
  current: {
    padding: '10px 0',
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  totalPaymentPrice: {
    margin: '10px 0',
    fontWeight: 500,
  }
}))

interface IPaymentPlanProps {
  plan: IPlan,
  duration: 1 | 12,
  handleBuy: (plan: IPlan, promocode: string) => void,
  current: boolean,
  canBuy: boolean,
  promo?: any
}

const PaymentPlan = ({plan, duration, handleBuy, current, promo, canBuy}: IPaymentPlanProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const modal = useModal();
  let sale = promo.sale;
  const [appliedSale, setAppliedSale] = useState(0);
  const [canSubmit, setCanSubmit] = useState(true);



  const code = useInput({
    label: t('promocode'),
    defaultValue: promo.code,
    mutate: (value: string) => {
      return value.toUpperCase();
    },
    onChangeHook: async (value: string) => {
      await changeSale(value);
    }
  });

  const calcPrice = useCallback((price: number) => {
    const saleValue = appliedSale || sale;
    const salePrice = Number((price * saleValue)/100);
    return Number(price - salePrice).toFixed(2)
  }, [duration, sale, appliedSale]);

  const changeSale = useCallback(async (value) => {
    if(!value) {
      sale = duration === 12 ? 10 : 0
      return;
    }
    const {sale: saleValue} = await Service.getPromoSale(value);
    console.log('SALE', saleValue);
    if(!saleValue) {
      code.setHelperText(t("invalid_promocode"));
      code.setError(true);
      sale = (duration === 12 ? 10 : 0)
    } else {
      sale = saleValue;
      setAppliedSale(saleValue);
    }
  }, [code, duration])

  const handleSubmit = useCallback(() => {
    setCanSubmit(false);
    if(!canSubmit) return;
    handleBuy(plan, code.value);
  }, [code, plan]);

  const handleBuyClick = useCallback(() => {
    if(canBuy) {
      modal.handleOpen();
    } else {
      handleBuy(plan, code.value)
    }
  }, [code, plan, current]);

  return (
    <div className={classes.plan}>
      <div
        className={classNames(classes.planBody, {
          [classes.popular]: plan.popular,
        })}
      >
        <h2>{plan.title}</h2>
        {plan.popular && <img src={img('popular.png')} alt="" className={classes.sticker}/>}
        <div className={classes.price}>
          <p className="prev-price">${calcPrice(plan.price + 20)}</p>
          <p className="price">${calcPrice(plan.price)}</p>
          <p className="period">month</p>
          {plan.title !== 'free' && <p className="peryear">${calcPrice(plan.price*12)} / year</p>}
        </div>
      </div>
      <div className={classes.action}>
        {current && <p className={classes.current}>{t('current_plan')}</p>}
        {!current &&

          <AccentButton
            variant={plan.popular ? 'contained' : 'outlined'} size="large" color={plan.popular ? 'secondary' : 'primary'}
            className={classes.btn}
            onClick={handleBuyClick}
          >
            {t( current ? 'continue_plan' : 'buy')}
          </AccentButton>
        }
      </div>
      <Modal {...modal} title={t('buy_plan_title')} btnSubmit={t('buy')} handleSubmit={handleSubmit} canSubmit={canSubmit}>
        <p className="subtitle">{t('buy_plan_desc')}</p>
        <p className={classes.totalPaymentPrice}>{t('price')}: ${calcPrice(plan.price * duration)}</p>
        <p className={classes.totalPaymentPrice}>{t('sale')}: {appliedSale || sale}%</p>
        <Input {...code} />
      </Modal>
    </div>
  )
}

export default PaymentPlan;