import { all, put, takeLatest, select, call } from "redux-saga/effects";
import { IntegrationActions } from "@core/store/actions/integration";
import { IAction } from "@core/store/actions";
import Service from "@core/services/integration";
import { SagaIterator } from "redux-saga";

function* getAll(action: IAction): SagaIterator {
  try {
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: true,
    });

    const data = yield call(Service.get);

    yield put({
      type: IntegrationActions.SET_DATA,
      payload: {
        data,
      },
    });

    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: false,
    });
  } catch (E) {
    yield put({
      type: IntegrationActions.SET_DATA,
      payload: {
        data: [],
      },
    });
  }
}

function* createIntegration(
  action: IAction<any, { type: string }>
): SagaIterator {
  try {
    const int = yield call(Service.create);
    const {
      integration: { item },
    } = yield select();
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: true,
    });
    yield put({
      type: IntegrationActions.GET_DATA,
    });
  } catch (e) {}
}

export default function* rootIntegrationSaga(): SagaIterator {
  yield all([
    yield takeLatest(IntegrationActions.GET_DATA, getAll),
    yield takeLatest(IntegrationActions.CREATE_INTEGRATION, createIntegration),
  ]);
}
