import {all, takeLatest, put, call, select} from 'redux-saga/effects';
import {IAction} from "../actions";
import {StatsActions} from "../actions/stats";
import service from '../../services/stats';
import GlobalService from '../../services/global';
import {statsSelector} from "@core/store/selectors/config";

function* getStats(action: IAction) {
  try {
    const stats = yield service.getStats();
    yield call(getUsersCountry);
    // yield call(getCountryCodes);
    yield put({
      type: StatsActions.SET_STATS,
      payload: {
        ...stats,
      }
    })
  } catch(E) {
    console.log(E);
  }
}

function* getStatsAuth() {
  yield call(getUsersCountry);
  // yield call(getCountryCodes);
}

function* getUsersCountry() {
  try {
    const stats = yield select(statsSelector);
    if(stats.country) return;
    const { country } = yield service.getUsersCountry();
    yield put({
      type: StatsActions.SET_USERS_COUNTRY,
      payload: country,
    })
  } catch(e) {
    yield put({
      type: StatsActions.SET_USERS_COUNTRY,
      payload: 'UA',
    })
  }
}

function* getCountryCodes() {
  const stats = yield select(statsSelector);
  if(stats.countryCodes.length > 0) return;
  const countries = yield GlobalService.getCountries();
  yield put({
    type: StatsActions.SET_COUNTRY_CODES,
    payload: countries,
  })
}

export function* rootStatsSaga() {
  yield all([
    yield takeLatest(StatsActions.GET_STATS, getStats),
    yield takeLatest(StatsActions.GET_USERS_COUNTRY, getUsersCountry),
    yield takeLatest(StatsActions.GET_COUNTRY_CODES, getCountryCodes),
    yield takeLatest(StatsActions.GET_STATS_AUTH, getStatsAuth),
  ])
}
