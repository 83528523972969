import {IFiltersPagination} from "../types/store";
import axios from "../utils/axios";

const BASE = (company: string, id: string) => `/company/${company}/item/${id}/comments`;

export default {
  getItems: async (id: string, company_id: string, pagination: IFiltersPagination) => (await axios.get(`${BASE(company_id,id)}`, {params: pagination})).data,
  create: async (id: string, company_id: string, data: any) => (await axios.post(`${BASE(company_id, id)}`, data)).data,
  update: async (id: string, company_id: string, comment: string, data: any) => (await axios.put(`${BASE(company_id, id)}/${comment}`, data)).data,
  updatePhotos: async (id: string, company_id: string, comment: string, photos: any) => (await axios.patch(`${BASE(company_id, id)}/${comment}/photos`, {photos})).data,
}
