import React, {PropsWithChildren, useCallback} from "react";
import GA from 'react-ga';

interface IGAEvent {
  category: string,
  action: string
}

interface IGAProps extends IGAEvent {}

export const createEvent = ({category, action}: IGAEvent) => {
  if(process.env.NODE_ENV === 'production') {
    GA.event({
      category,
      action
    });
  } else {
    console.log('GAEvent', {
      category,
      action
    })
  }
}

const GAEvent = ({category, action, children}: PropsWithChildren<IGAProps>) => {

  const emit  = useCallback(() => {
    createEvent({category, action})
  }, [category, action]);
  return (
    <div onClick={emit} style={{display: 'inherit'}}>
      {children}
    </div>
  )
}
export default GAEvent;