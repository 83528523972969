import React from "react";
import Card from "../../../../../shared/Common/Card";
import classNames from "classnames";
import {Button, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    marginTop: -100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    },
    '& h3.text-default, & h3.text-default + p' : {
      textAlign: 'center'
    }
  },
  card: {
    padding: '20px'
  },
  img: {
    width: '70%',
    display: 'block',
    margin: '0 auto'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

const PhoneVerificationSuccess = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Card className={classNames(classes.root, classes.card)}>
      <img src={img('approved.svg')} alt="" className={classes.img}/>
      <h3 className="text-default">Номер телефона успешно подтвержден</h3>
      <p className="subtitle">В дальнейшем используйте номер телефона для авторизации</p>
      <div className={classes.actions}>
        <Link to="/auth/login">
          <Button color="secondary">{t('continue')}</Button>
        </Link>
      </div>
    </Card>
  )
}

export default PhoneVerificationSuccess;