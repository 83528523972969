import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme: string) => ({
  root: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  input: {
    maxWidth: 50,
    margin: '0 10px',
    fontWeight: 600,
    textAlign: 'center'
  }
}))

const createObject = (length: number) => {
  let obj: any = {};
  (new Array(length).fill('')).forEach((item, index) => {
    const ref = useRef<any>()
    obj[index] = {
      value: '',
      ref
    }
  });
  return obj;
}

const CodeInput = (props: {length: number, send: (code: string) => void}) => {
  const {length, send} = props;
  const classes = useStyles();
  const [value, setValue] = useState<any>(createObject(length));

  useEffect(() => {
    const code = Object.values(value).map((item: any) => item.value).join('');
    if(code.length === length) {
      send(code)
    }
  }, [value, length, send])

  const handleChange = useCallback((index: number) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value ? e.target.value[e.target.value.length - 1] : '';
      if(Number.isNaN(+val)) return;
      setValue({
        ...value,
        [index]: {
          ...value[index],
          value: val
        }
      });

      if(value[index + 1] && val !== '') {
        value[index + 1].ref.current.focus();
      }
    }
  }, [value]);

  const onBackspaceClick = useCallback((index) => {
    return (e: React.KeyboardEvent<HTMLDivElement>) => {
      if(e.keyCode !== 8) return;
      const prev = value[index - 1];
      const current = value[index];
      if(!current.value && prev) {
        setValue({
          ...value,
          [index - 1]: {
            ...value[index - 1],
            value: ''
          }
        });
        prev.ref.current.focus();
      }
    }
  }, [value]);

  return (
    <div className={classes.root}>
      {
        (new Array(length).fill('')).map((item, index) => (
          <TextField
            key={index}
            onChange={handleChange(index)}
            value={value[index].value}
            inputRef={value[index].ref}
            className={classes.input}
            onKeyDown={onBackspaceClick(index)}
          />
        ))
      }
    </div>
  )
}

export default CodeInput;