import {ITypedAction} from "../actions";
import {IDefaultState, IFiltersPagination} from "../../types/store";
import {IItemFull} from "../../types";
import {ItemsActions} from "../actions/items";
import Settings from "../../services/settings";

export interface ItemsState extends IDefaultState<IItemFull, IItemFull> {
  txs: {
    data: any,
    pagination: IFiltersPagination,
    count: number,
    loading: boolean
  }
  settings?: {
    createItemSections: {
      info: boolean,
      location: boolean,
      responsible: boolean,
      additional: boolean,
    }
  },
  counters: {
    count: number;
    total: number;
    countInService: number;
    countInBan: number;
    isLoading: boolean;
  }
}

export const initialItemState: ItemsState = {
  data: null,
  filtersOpen: false,
  filters: {
    search: '',
    brand: {title: '', value: '', values: []},
    customs: {title: '', value: ''},
    customsValue: '',
    model: '',
    type:  {title: '', value: ''},
    serial: '',
    status: '',
    responsible: {title: '', value: ''},
    object: {title: '', value: '', values: []},
    location:  {title: '', value: ''},
  },
  pagination: {
    limit: Settings.getItemsPaginationLimit(10),
    offset: 0
  },
  sort: {
    updatedAt: -1
  },
  total: 0,
  loading: false,
  item: null,
  txs: {
    data: [],
    pagination: {limit: 10, offset: 0},
    count: 0,
    loading: false
  },
  counters: {
    isLoading: true,
    count: 0,
    countInBan: 0,
    total: 0,
    countInService: 0
  }
};

const ItemReducer = (state: any = initialItemState, action: ITypedAction<ItemsActions>) => {
  switch (action.type) {
    case ItemsActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total
      };
    case ItemsActions.SET_ITEMS_MOBILE:
      return {
        ...state,
        data: [...(state.data || []), ...action.payload.data],
        total: action.payload.total,
      };
    case ItemsActions.SET_ITEM_TXS:
      return {
        ...state,
        txs: {
          data: [...state.txs.data, ...action.payload.data],
          pagination: state.txs.pagination,
          count: action.payload.count,
          loading: state.txs.loading
        }
      }
    case ItemsActions.CLEAR_TXS_DATA:
      return {
        ...state,
        txs: initialItemState.txs
      }
    case ItemsActions.SET_ITEM_TXS_PAGINATION:
      return {
        ...state,
        txs: {
          ...state.txs,
          pagination: action.payload,
        }
      }
    case ItemsActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        data: [],
        pagination: initialItemState.pagination
      };
    case ItemsActions.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialItemState.filters,
        pagination: initialItemState.pagination
      };
    case ItemsActions.DELETE_FILTERS:
      let dependencies: any = {};
      if(action.payload === 'brand') {
        dependencies['model'] = {label: '', value: ''}
      }
      if(action.payload === 'object') {
        dependencies['location'] = {label: '', value: ''}
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: initialItemState.filters[action.payload],
          ...dependencies
        }
      }
    case ItemsActions.TOGGLE_FILTERS:
      return {
        ...state,
        filtersOpen: action.payload
      }
    case ItemsActions.SET_SORT:
      return {
        ...state,
        sort: {
          ...action.payload
        }
      }
    case ItemsActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case ItemsActions.SET_ITEM:
      return {
        ...state,
        item: action.payload
      };
    case ItemsActions.UPDATE_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload
        }
      }

    case ItemsActions.UPDATE_ITEM_PHOTOS:
      return {
        ...state,
        item: {
          ...state.item,
          photos: [...(state.item.photos || []), ...action.payload]
        }
      }
    case ItemsActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ItemsActions.SET_TX_LOADING:
      return {
        ...state,
        txs: {
          ...state.txs,
          loading: action.payload
        }
      };
    case ItemsActions.CLEAR_ITEM:
      return {
        ...state,
        item: null
      };
    case ItemsActions.CLEAR_ITEMS:
      return {
        ...state,
        data: []
      };
    case ItemsActions.DELETE_ITEMS:
      return {
        ...state,
        data: state.data.filter((item: any) => !action.payload.includes(item._id))
      }
    case ItemsActions.SET_ITEM_COUNTERS_DATA:
      return {
        ...state,
        counters: {
          ...action.payload,
          isLoading: false
        }
      }
    case ItemsActions.SET_ITEM_COUNTERS_DATA_LOADING:
      return {
        ...state,
        counters: {
          ...state.counters,
          isLoading: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
};

export default ItemReducer;
