import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const ItemActionStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(1280)]: {
      display: 'block',
    },
  },
  card: {
  },
  cardTitle: {
    marginBottom: 0,
    position: 'absolute'
  },
  body: {
    flex: '1 1 80%',
    marginTop: 20
  },
  title: {
    marginLeft: 45,
    marginBottom: 30
  },
  img: {
    width: '80%'
  },
  item: {
    margin: '0px 0px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 15
  },
  input: {
    margin: '0 !important',
  },
  wrapperInput: {
    minWidth: '100% !important'
  },
  iconBack: {
    cursor: 'pointer'
  }
}));
