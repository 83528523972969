import {IAction} from "../actions";
import {CommentsActions} from "../actions/comments";
import { ICommentsState } from "../../types/store";
import {IItemCommentFull} from "../../types";

const initialState: ICommentsState<IItemCommentFull> = {
  data: null,
  total: 0,
  loading: false,
  item: null,
  pagination: {
    limit: 20,
    offset: 0
  }
};


const CommentsReducer = (state = initialState, action: IAction<CommentsActions>) => {
  switch (action.type) {
    case CommentsActions.SET_ITEMS:
      return {
        ...state,
        item: action.payload.item,
        data: [...(state.data || []), ...action.payload.data],
        total: action.payload.total
      };
    case CommentsActions.CLEAR_ITEMS:
      return initialState;
    case CommentsActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CommentsActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case CommentsActions.ADD_ITEM:
      return {
        ...state,
        data: [action.payload, ...(state.data || [])]
      }
    case CommentsActions.DELETE_PHOTO:
      return {
        ...state,
      }
    case CommentsActions.UPDATE_ITEM:
      let data: any = [];
      if(state.data !== null) {
        data = state.data.map((item: any) => {
          if(item._id === action.payload.id) {
            item.message = action.payload.message
            item.photos = action.payload.photos;
          }
          return item;
        });
      }
      return {
        ...state,
        data
      };
    default:
      return state;
  }
};

export default CommentsReducer;
