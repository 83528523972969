import axios from '../utils/axios';
import FileSaver from "file-saver";
import {IUser} from "@core/types";

export interface ISupportRequest {
  name: string;
  email: string;
  phone: string;
  message: string;
  location: string;
  user: IUser;
}

export default {
  getAnalytics: async (id: string, params: any) => (await axios.get(`/company/${id}/analytics`, {params})).data,
  edit: async (id: string, data: any) => (await axios.put(`/company/${id}`, data)).data,
  editSettings: async (id: string, data: any) => (await axios.put(`/company/${id}/settings`, data)).data,
  generateCode: async (count: number, company: string) => {
    const {data} = await axios.get(`/company/${company}/generate`,{params: {count}, responseType: "blob"});
    FileSaver.saveAs(data, 'EqmanQrCodes.pdf')
  },
  sendAppLinks: async (phone: string) => (await axios.post(`/company/send-app-links`, {phone})),
  sendSupport: async (id: string, data: ISupportRequest) => (await axios.post(`/company/${id}/support`, data)),
}