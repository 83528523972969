import React, {ReactNode} from "react";
import {Roles} from "@core/types";
import {
  BoxIcon,
  InventorizationIcon,
  UsersIcon
} from "@shared/Icons/svg";

const ItemsPage = React.lazy(() => import("@featured/pages/Items"))
const UsersPage = React.lazy(() => import("@featured/pages/Users"))
const StocktakingRouter = React.lazy(() => import("@featured/pages/Stocktaking"))
const RequestPage = React.lazy(() => import("@featured/pages/Request"))
const SettingsPage = React.lazy(() => import("@featured/pages/Settings"))
const Payment = React.lazy(() => import("@featured/layout/Payment"))
const AnalyticsPage = React.lazy(() => import("@featured/pages/Analytics"))
const LocationsPage = React.lazy(() => import("@featured/pages/Locations"))


const routes: IRoute[] = [
  {
    name: 'items_list',
    icon: BoxIcon,
    path: '/items',
    component: ItemsPage,
    private: true,
    children: {
      '/items/create': 'create_item',
      '/items/datafiles': 'datafiles'
    },
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuAssetsList'
    }
  },
  {
    name: 'stats',
    icon: 'pie_chart',
    path: '/analytics',
    component: AnalyticsPage,
    private: true,
    exact: true,
    excludedRoles: [Roles.WORKER],
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuAnalytics'
    }
  },
  {
    name: 'users',
    icon: UsersIcon,
    path: '/users',
    component: UsersPage,
    private: true,
    root: true,
    children: {
      '/users/create': 'create_user'
    },
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuUsersList'
    }
  },
  {
    name: 'stocktaking',
    icon: InventorizationIcon,
    path: '/stocktaking',
    component: StocktakingRouter,
    private: true,
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuInventory'
    }
  },
  // {
  //   name: 'transfer_history_menu',
  //   icon: TimeIcon,
  //   path: '/transfers',
  //   component: TransferHistory,
  //   private: true,
  // },
  {
    name: 'sticker_orders',
    icon: 'shopping_cart',
    path: '/requests',
    component: RequestPage,
    private: true,
    root: true,
    location: 'bottom',
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuLabelsShop'
    }
  },
  {
    name: 'locations',
    icon: 'room',
    path: '/locations',
    component: LocationsPage,
    private: true,
    root: true,
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuObjectsList'
    }
  },
  {
    name: 'settings',
    icon: 'settings',
    path: '/settings',
    component: SettingsPage,
    private: true,
    location: 'bottom',
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuSettings'
    }
  },
  {
    name: 'payment_tariff',
    icon: 'credit_card',
    path: '/payment',
    component: Payment,
    private: true,
    location: 'bottom',
    root: true,
    event: {
      category: 'LeftSideMenu',
      action: 'LeftSideMenuTariffs'
    }
  },
];

export default routes;

export const nav = routes.filter(item => item.private);

export const findRouteByPath = (path: string) => {
  let route = '';
  for(let i in routes) {
    const item = routes[i];
    if(path.trim().toString() === item.path.toString()) {
      route = item.name;
      break;
    }
    if(path.indexOf(item.path) !== -1 && path !== item.path && item.children) {
      route = item.children[path];
      if(route) break;
    }
    if(path.indexOf(item.path) !== -1) {
      route = item.name;
      break;
    }
  }
  return route;
};

export const isInnerRoute = (path: string) => {
  return !routes.find(item => item.path === path);
};

export interface IRoute {
  name: string,
  path: string,
  icon?: string | ReactNode,
  component: any,
  private: boolean,
  exact?: boolean,
  hidden?: boolean,
  root?: boolean,
  excludedRoles?: Roles[],
  location?: 'top' | 'bottom',
  children?: any,
  event: {
    category: string,
    action: string
  }
}
