import {IActionWithSingleSideEffect} from "@core/store/actions";
import Service from '@core/services/company'
import {select, put, all, takeLatest} from "redux-saga/effects";
import {AnalyticsActions} from "@core/store/actions/analytics";
import notify from "@core/utils/notify";
import {normalizeFilters} from "@core/utils/normalize";
import {StatsActions} from "@core/store/actions/stats";

function* getData(action: IActionWithSingleSideEffect) {
  try {
    yield put({
      type: AnalyticsActions.SET_LOADING,
      payload: true
    });

    const {auth: {user: {company}}} = yield select();
    const data = yield Service.getAnalytics(company, normalizeFilters(action.payload));

    yield put({
      type: AnalyticsActions.SET_ITEMS,
      payload: data
    })

    yield put({
      type: AnalyticsActions.SET_LOADING,
      payload: false
    });
  } catch (e) {
    notify.error('Error getting analytics')
  }
}



export function* rootAnalyticsSaga() {
  yield all([
    yield takeLatest(AnalyticsActions.GET_ITEMS, getData)
  ])
}