import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = (props: any) => {
  if (localStorage.getItem('token')) {
    return <Route path={props.path} exact={props.exact} render={props.render} component={props.component}/>
  } else {
    return <Redirect to="/auth/login" />
  }
};
export default PrivateRoute
