import axios from "axios";
import notify from "./notify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create();
apiClient.defaults.baseURL = BASE_URL;

apiClient.interceptors.request.use(function (request) {
  const token = localStorage.getItem('token');
  if(token) {
    request.headers.authorization = `Bearer ${token}`;
  }
  return request;
});

apiClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const data = error.response || {status: 0};
  if(error.message === 'Network Error') {
    // notify.warning('NetworkError');
    // window.location.replace('/error/network');
  }
  if(data.status === 401) {
    notify.warning('Ошибка авторизации');
    localStorage.removeItem('token');
    document.location.reload();
  }
  return Promise.reject(error);
});


export default apiClient;
