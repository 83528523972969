export enum RequestActions {
  GET_ITEMS = 'get_requests',
  GET_MARK_METHODS = 'get_mark_methods',
  SET_TYPE = 'set_request_page_type',
  GET_ITEM = 'get_request',
  SET_ITEMS = 'set_requests',
  SET_MARK_METHODS = 'set_mark_methods',
  SET_FILTERS = 'set_request_filters',
  CLEAR_FILTERS = 'clear_request_filters',
  SET_LOADING = 'set_requests_loading',
  SET_ITEM = 'set_request',
  CLEAR_ITEM = 'clear_request',
  SET_PAGINATION = 'set_request_pagination',
  CREATE_ITEM = 'create_request',
  EDIT_ITEM = 'edit_request',
  UPDATE_ITEM = 'updated_request',
  REJECT_ORDER = 'reject_order',
  CLEAR_ITEMS = 'clear_requests',
  ADD_TO_ORDER = 'add_to_order',
  UPDATE_ORDER = 'update_order',
  REMOVE_FROM_ORDER = 'remove_from_order',
  CLEAR_ORDER = 'clear_order'
}
