import {select, put, all, takeLatest, delay} from 'redux-saga/effects';
import {IAction, IActionWithSingleSideEffect} from "../actions";
import service from '../../services/comments'
import {CommentsActions} from "../actions/comments";
import {IFiltersPagination} from "../../types/store";

function* getAll(action: IAction<CommentsActions, {item: string, pagination: IFiltersPagination}>) {
  try {
    yield put({
      type: CommentsActions.SET_LOADING,
      payload: true
    });

    const {item, pagination} = action.payload;
    const {auth: {user: {company}}} = yield select();
    const {data, count} = yield service.getItems(item, company, pagination);

    yield delay(200, true);

    yield put({
      type: CommentsActions.SET_ITEMS,
      payload: {
        item,
        data,
        total: count
      }
    });

    yield put({
      type: CommentsActions.SET_LOADING,
      payload: false
    })
  } catch (e) {
    console.log(e);
  }
}

function* create(action: IActionWithSingleSideEffect<CommentsActions, {files: File[], message: string}>) {
  try {
    const {
      auth: {
        user: {company},
        user
      },
      comments: {
        item
      }
    } = yield select();
    const {message, files} = action.payload;
    const formData = new FormData();
    formData.append('message', message)
    if(files.length > 0) {
      files.forEach(file => {
        formData.append('file', file)
      })
    }

    const comment = yield service.create(item, company, formData);

    yield put({
      type: CommentsActions.ADD_ITEM,
      payload: {
        _id: comment._id,
        user,
        message,
        photos: comment.photos,
        createdAt: new Date()
      }
    });
    yield action.sideEffect();
  } catch(E) {
    console.log(E);
  }
}

function* update(action: IActionWithSingleSideEffect<CommentsActions, {message: string, id: string, photos: {name: string, url: string}[], files: File[]}>) {
  try {
    const {
      auth: {
        user: {company}
      },
      comments: {
        item
      }
    } = yield select();
    const {message, id, files, photos} = action.payload;

    yield service.updatePhotos(item, company, id, photos.map(photo => photo.name));

    const formData = new FormData();
    formData.append('message', message);

    if(files.length > 0) {
      files.forEach(file => {
        formData.append('file', file)
      })
    }

    const comment = yield service.update(item, company, id,formData);

    yield put({
      type: CommentsActions.UPDATE_ITEM,
      payload: {
        id,
        message,
        photos: [...photos, ...comment.photos]
      }
    })
    yield action.sideEffect();
  } catch(E) {
    console.log(E);
  }
}

export default function* rootCommentsSaga() {
  return yield all([
    yield takeLatest(CommentsActions.GET_ITEMS, getAll),
    yield takeLatest(CommentsActions.CREATE_ITEM, create),
    yield takeLatest(CommentsActions.EDIT_ITEM, update),
  ])
}
