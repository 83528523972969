import React from "react";
import {Menu} from "@material-ui/core";

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const Dropdown = (props: any) => {
  const {
    id,
    open,
    anchorEl,
    handleClose,
  } = props;
  return (
    <Menu
      id={id}
      open={open}
      elevation={2}
      anchorEl={anchorEl || null}
      onClose={handleClose}
      anchorOrigin={props.anchorOrigin || anchorOrigin}
      transformOrigin={props.transformOrigin || transformOrigin}
    >
      {props.children}
    </Menu>
  )
}

export default Dropdown;
