import React, {useCallback} from "react";
import {Paper, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {IPlan} from "@core/types";
import {useTranslation} from "react-i18next";
import PaymentPlan from "@featured/pages/Payment/PlanPaymentForm/Plan";

interface IPaymentPlansProps {
  plans: any,
  handleBuy: (plan: IPlan, promocode: string) => void,
  duration: 1 | 12,
  currentPlan: IPlan,
  promo?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 327,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(1420)]: {
      height: 250,
    },
    [theme.breakpoints.down(670)]: {
      flexWrap: 'wrap',
      height: 'auto'
    },
  },
}))


const PaymentPlans = (props: IPaymentPlansProps) => {
  const {plans, duration, handleBuy, currentPlan, promo} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <>
      <Paper>
        <div className={classes.root}>
          {
            plans.map((plan: IPlan) => (
              <PaymentPlan
                plan={plan}
                duration={duration}
                handleBuy={handleBuy}
                current={currentPlan._id === plan._id}
                promo={promo}
                canBuy={currentPlan.title === 'free'}
              />
            ))
          }
        </div>
      </Paper>
      <div style={{marginTop: '30px', textAlign: 'center'}}>
        {t("terms_accept")} <a href="https://eqman.co/privacy-policy/" target="_blank" className="link underline green">{t('terms_offert')}</a>
        <br/>
        <p style={{color: "#444", fontSize: 14, marginTop: 10}}>
          {t('cashless_payment_desc').split('{email}')[0]} <a href="mailto:support@eqman.co" className="link underline green">support@eqman.co</a>  {t('cashless_payment_desc').split('{email}')[1]}
        </p>
      </div>
    </>
  )
}

export default PaymentPlans;