export enum SettingsActions {
  SET_SETTINGS = 'set_settings',
  CHANGE_LANG = 'change_lang',
  START_TUTORIAL = 'start_tutorial',
  STOP_TUTORIAL = 'stop_tutorial',
  FINISH_TUTORIAL = 'finish_tutorial',
  ACTIVATE_TUTORIAL = 'activate_tutorial',
  TOGGLE_ITEM_CREATE_SECTIONS = 'toggle_item_create_sections',
  SET_ITEM_CREATE_SECTIONS = 'set_item_create_sections',
}
