import React, {useCallback} from 'react';
import {makeStyles} from "@material-ui/styles";
import { Theme, ButtonBase } from '@material-ui/core';
import User from '../../../Icons/user.png';
import classnames from 'classnames';
import {Roles} from "../../../../core/types";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 7px',
    alignItems: 'center',
    width: "100%",
    textAlign: 'left'
  },
  outlined: {
    backgroundColor: '#f4f4f4',
    borderRadius: 4,
    padding: '10px 15px',
    margin: '15px 0px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.08), 0 1px 5px 0 rgba(0,0,0,0.2)'
  },
  small: {
    '& img': {
      width: 20
    }
  },
  img: {
    width: '40px',
    borderRadius: '50%'
  },
  data: {
    flexBasis: '100%',
    marginLeft: 10,
    '& p': {
      margin: 0,
      fontSize: 13,
      fontWeight: 500
    },
    '& span': {
      fontSize: 12
    }
  },
  badge:{
    [theme.breakpoints.down(1366)]: {
      display: 'block'
    },
    [theme.breakpoints.down(1280)]: {
      display: "inline"
    }
  }
}));

interface IPropsUserInfo extends RouteComponentProps {
  id?: string,
  image?: string,
  name: string,
  email?: string,
  outlined?: boolean,
  small?: boolean,
  style?: any
  className?: string,
  badge?: Roles | string,
  badgeColor?: string,
  withLink?: boolean,
  test?: boolean
}

const UserInfo = (props: IPropsUserInfo) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const handleNavigate = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(props.withLink) {
      e.preventDefault();
      e.stopPropagation();
      props.history.push(`/users/${props.id}`)
    }
  }, [props.withLink, props.history, props.id]);

  return (
    <ButtonBase style={props.style} className={classnames(classes.root, props.className, {
      [classes.outlined] : props.outlined,
      [classes.small]: props.small
    })} onClick={handleNavigate}>
      {!props.small && <img src={props.image || img('user.png')} alt="" className={classes.img}/>}
      <div className={classes.data}>
        <p>
          {props.name}
          {props.test && <span style={{marginLeft: 10}} className={`badge orange`}>test data</span>}
          {props.badge && props.badgeColor && <span style={{marginLeft: 10}} className={`badge ${props.badgeColor} ${classes.badge}`}>{t(props.badge)}</span>}
        </p>
        <span>{props.email}</span>
      </div>
    </ButtonBase>
  )
};

export default withRouter(UserInfo);
