import React, {useEffect, useState} from "react";
import useAuth from "../../core/hooks/useAuth";
import {CircularProgress} from "@material-ui/core";
import TopBar from "../../shared/Common/Topbar";
import TopbarMobile from "../../shared/Common/Topbar/Mobile";
import {
  isMobile
} from "react-device-detect";
import CompanyPage from "../pages/Company";
import {withRouter, useLocation} from 'react-router-dom';
import {IMessage, IUser} from "@core/types";
import {useDispatch, useMappedState} from "redux-react-hook";
import {isAdmin} from "@core/utils/global";
import Support from "@shared/Common/Support";
import useModal from "@core/hooks/useModal";
import {SupportContext} from "@core/context";
import EmailAlert from "@shared/Alerts/Email";
import Tutorial from "@shared/Tutorial/Global";
import {getHandlers} from "@core/hooks/useFirebaseMessaging";
import { NotificationActions } from "@core/store/actions/notification";
import notify from "@core/utils/notify";
import {AuthAction} from "@core/store/actions/auth";
import moment from "moment";

export const AuthProvider = React.createContext<IUser | null>(null);

const handlers = getHandlers();

const MainLayout = (props: React.PropsWithChildren<any>) => {
  const auth = useAuth(props.history);
  const dispatch = useDispatch();
  const {company, settings} = useMappedState((state) => ({company: state.stats.company, settings: state.settings}))
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if(auth.token !== '' && loading) {
      setLoading(false);
      if(handlers) {
        handlers.requestFirebaseNotificationPermission().then(token => {
          localStorage.removeItem('notifyRequestDelay');
          dispatch({
            type: AuthAction.SET_FIREBASE_TOKEN,
            payload: {token}
          });
        }).catch(E => {
          if(E.code === 'messaging/permission-default') {
            localStorage.setItem('notifyRequestDelay', moment().add(1, 'day').format('YYYY-MM-DD hh:mm:ss'));
          }
        });
      }

    }
  }, [auth, loading, company]);

  if(handlers) {
    handlers.onMessageListener().then((res: IMessage) => {
      dispatch({
        type: NotificationActions.ADD,
        payload: {...res, read: false}
      });
      notify.notification('Notification', res);
    });
  }


  const BarComponent = isMobile ? TopbarMobile : TopBar;
  const SupportControl = useModal();

  return (
      loading
      ? <div className="preloader-main"><h3>Eqman</h3><CircularProgress/></div>
      :
      <AuthProvider.Provider value={auth.user}>
        <BarComponent path={props.location.pathname} disabled={auth.user.role === 'user'}>
          <div style={{zIndex: 100}} className='main'>
            {auth.user && auth.user.role !== 'user' ?
              <div>
                {
                  company &&
                  <>
                    <div style={{position: 'relative', zIndex: 100}}>
                      {
                        !isMobile && !auth.user.email_approved && isAdmin(auth.user) && settings.emailAlertOpen &&
                        <EmailAlert/>
                      }
                      {/*{!isMobile && !company.trial && isAdmin(auth.user) && <PlanExpirationAlert company={company}/>}*/}
                      {/*{!isMobile && company.trial && isAdmin(auth.user) && <TrialPlanAlert company={company}/>}*/}
                    </div>
                    <SupportContext.Provider value={{open: SupportControl.handleOpen}}>
                      <Tutorial/>
                      {props.children}
                    </SupportContext.Provider>
                    <Support control={SupportControl}/>
                  </>
                }
              </div> : <CompanyPage/>}
          </div>
        </BarComponent>
      </AuthProvider.Provider>
  )
};

export default withRouter(MainLayout)
