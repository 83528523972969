import axios from '../utils/axios';

export default {
  getPlans: async () => (await axios.get('/payment/plans')).data,
  createPayment: async (data: any) => (await axios.post('/payment/plan', data)).data,
  getPayment: async (params: any) => (await axios.get('/payment', {params})).data,
  getSinglePayment: async (id: string) => (await axios.get(`/payment/${id}`)).data,
  updatePlan: async (id: string) => (await axios.put(`/payment/plan/${id}`)).data,
  getPromoSale: async (code: string) => (await axios.post('/payment/check/promo', {code})).data,
  disableSubscription: async () => (await axios.post('/payment/subscription/pause')).data
}