import React, {useCallback} from "react";
import {IMessageStore} from "@core/types";
import useNotification from "@shared/Common/Topbar/Notifications/useNotification";
import {makeStyles, Theme, Icon} from "@material-ui/core";
import classNames from "classnames";

interface INotificationProps {
  message: IMessageStore,
  onClick: (message: IMessageStore) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "flex-start",
    padding: '10px 20px'
  },
  clickable: {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f4f4f4'
    }
  },
  body: {

  },
  icon: {
    display: "block",
    marginRight: 20
  },
  actions: {
    paddingLeft: 20,
    borderLeft: '1px solid #eee'
  }
}))

const Notification = ({message, onClick}: INotificationProps) => {
  const classes = useStyles();
  const {getInfo} = useNotification(message);
  const {title, description, icon} = getInfo();

  const handleClick = useCallback(() => {
    onClick(message);
  }, [message, onClick]);

  return (
    <div className={classNames(classes.root,{[classes.clickable]: !!message.link})} onClick={handleClick}>
      <Icon className={classes.icon}>{icon}</Icon>
      <div className={classes.body}>
        <h2 className="text-default">{title}</h2>
        <p className="subtitle" style={{fontSize: 13}}>{description}</p>
      </div>
      {message.link && <div className={classes.actions}>
        <Icon>link</Icon>
      </div>}
    </div>
  )
}

export default Notification;