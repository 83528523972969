const notify = {
  success: (message: string, options?: any) => {
    const notifyEvent = new CustomEvent('notify', { detail: { message, type: 'success', data: options } });
    document.dispatchEvent(notifyEvent)
  },
  warning: (message: string, options?: any) => {
    const notifyEvent = new CustomEvent('notify', { detail: { message, type: 'warning', data: options } });
    document.dispatchEvent(notifyEvent)
  },
  error: (message: string, options?: any) => {
    const notifyEvent = new CustomEvent('notify', { detail: { message, type: 'error', data: options } });
    document.dispatchEvent(notifyEvent)
  },
  notification: (message: string, options?: any) => {
    const notifyEvent = new CustomEvent('notify', { detail: { message, type: 'notification', data: options } });
    document.dispatchEvent(notifyEvent)
  },
  info: (message: string, options?: any) => {
    const notifyEvent = new CustomEvent('notify', { detail: { message, type: 'info', data: options } });
    document.dispatchEvent(notifyEvent)
  }
};

export default notify
