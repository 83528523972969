import React from 'react';
import {Button, Paper, Theme, Typography} from "@material-ui/core";
import Input from "../../../../shared/Common/Form/Input";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    }
  },
  description: {
    padding: '10px 0px'
  },
  actions: {
    textAlign: 'left'
  },
  back: {
    fontSize: '0.9375rem',
    float: 'right',
    marginTop: '-31px'
  }
}))

interface IProps {
  send: () => void,
  emailOrPhone: any,
}

const ForgotPasswordMultiMode = (props: IProps) => {
  const {send, emailOrPhone} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Paper className={classes.root}>
      <div className={classes.description}>
        <b style={{fontSize: 18, fontWeight: 600}}>{t('forgot_password')}</b>
        <Typography variant="subtitle2" color="textSecondary">{t('forgot_password_desc')}</Typography>
      </div>
      <form>
        <Input {...emailOrPhone}/>
        <div className={classes.actions}>
          <Button
            size="large"
            color="primary"
            disabled={!emailOrPhone.value || emailOrPhone.error}
            onClick={send}
          >{t('continue')}</Button>
        </div>
        <Link to="/auth/login">
          <Button color="primary" className={classes.back}>{t('back')}</Button>
        </Link>
      </form>
    </Paper>
  )
}

export default ForgotPasswordMultiMode;
