import * as React from "react";
import {rules} from "../utils/validation";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import useDebounce from "@core/hooks/useDebounce";
import {useTranslation} from "react-i18next";
export interface IInputProps {
  rule?: string,
  defaultValue?: string | number,
  naked?: boolean,
  type?: string,
  label?: string,
  className?: string,
  variant?: any,
  size?: 'medium' | 'small',
  required?: boolean,
  helperText?: string,
  onChangeHook?: (value: string, setValue: (value: string) => void) => void,
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  mutate?: (value: string) => string
  maxLength?: number,
  minLength?: number,
  validate?: (value: string) => boolean,
  errorText?: any,
  debounce?: number,
  onlyNumbers?: boolean,
  onlyPositive?: boolean,
  onlyInt?: boolean,
}
export interface IInputComponentProps extends IInputProps {
  value: string,
  id?: string,
  ref?: any,
  variant?: any,
  required?: boolean
  className?: string,
  classNameInput?: string;
  error: boolean,
  placeholder?: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  size?: 'medium' | 'small',
  helperText?: string,
  setHelperText: (value: string) => void
  setError: (value: boolean) => void
  setValue: (value: string) => void
  disabled?: boolean,
  color?: any,
  multiline?: boolean,
  rows?: number,
  InputProps?: any,
  InputLabelProps?: any,
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  inputComponent?: any,
  fullWidth?: boolean,
  onPaste?: (e: any) => void,
}

const useInput = (props: IInputProps): IInputComponentProps => {
  const {t} = useTranslation();
  const {rule, onChangeHook, maxLength, validate, debounce = 300, onlyNumbers = false, onlyPositive = false, onlyInt = false} = props;
  const [value, setValue] = React.useState(props.defaultValue || '');
  const [error, setError] = React.useState(false);
  const [touched, setTouched] = useState(false);
  const [prev, setPrevValue] = React.useState(props.defaultValue || '');
  const debounced = useDebounce(value, debounce);

  useEffect(() => {
    if(onChangeHook && touched && debounced !== prev) {
      onChangeHook(debounced, setValue);
      setPrevValue(debounced);
    }
  }, [debounced, touched, prev, onChangeHook])

  const [errorText, setHelperText] = React.useState(props.helperText || '');

  const handleSetHelperText = useCallback((value: string) => {
    setHelperText(t(value))
  }, [])

  const onChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let newValue: any = e.target.value;
    setError(false);
    setHelperText('');
    if(props.type === 'email') {
      newValue = newValue.replace(/ /gm, '').toLowerCase();
    }
    if(props.mutate) {
      newValue = props.mutate(newValue);
    }
    if(newValue !== '' && onlyNumbers) {
      if((Number.isNaN(+newValue)) || (onlyPositive && +newValue <= 0)) {
        return;
      }
      if(onlyInt) {
        newValue = Math.round(newValue)
      }
    }
    if(validate && validate(newValue)) {
      return;
    }
    if(maxLength && newValue.length >= maxLength) {
      return;
    }
    if(rule) {
      const isError = rules[rule](newValue);
      setError(isError);
    }
    setValue(newValue);
    setTouched(true);
  }, [rule, maxLength, validate, onlyNumbers, onlyPositive]);

  delete props.defaultValue;
  delete props.onChangeHook;
  delete props.debounce;
  delete props.onlyPositive;
  delete props.onlyNumbers;
  return {
    ...props,
    error,
    onChange,
    value: value.toString(),
    errorText,
    setError,
    setHelperText: handleSetHelperText,
    setValue
  }
};

export default useInput;
