import {IAction, IActionWithSingleSideEffect} from "../actions";
import {all, select, takeLatest, put} from "redux-saga/effects";
import notify from "../../utils/notify";
import {CompanyActions} from "@core/store/actions/company";
import CompanyService, {ISupportRequest} from "@core/services/company";
import i18next from "i18next";
import {StatsActions} from "@core/store/actions/stats";
import {SettingsActions} from "@core/store/actions/settings";
import {CreateItemSectionStatusState, SettingsState} from "@core/store/reducers/settings";


function* edit(action: IActionWithSingleSideEffect<CompanyActions.EDIT_COMPANY>) {
  try {
    const {auth: {user: {company}}, stats } = yield select();
    yield CompanyService.edit(company, action.payload);
    yield put({
      type: StatsActions.SET_STATS,
      payload: {
        ...stats,
        company: {
          ...stats.company,
          currency: action.payload.currency
        }
      }
    })
    notify.success(i18next.t('success'));
  } catch (e) {
    notify.error(i18next.t('error'));
  }
}

function* sendAppLinks(action: IActionWithSingleSideEffect<CompanyActions.SEND_APP_LINKS, {phone: string}>) {
  try {
    yield CompanyService.sendAppLinks(action.payload.phone);
    notify.success('Успешно отправленно')
  } catch (e) {
    notify.error("Отправить можно только 1 сообщение в течении 10 минут")
  }
}

function* sendSupport(action: IActionWithSingleSideEffect<CompanyActions.SEND_SUPPORT, ISupportRequest>) {
  try {
    const {auth: {user: {company}}} = yield select();

    yield CompanyService.sendSupport(company, action.payload);
    notify.success(i18next.t('success'));
  } catch (e) {
    notify.error("Отправить можно только 1 сообщение в течении 10 минут")
  }
}

function* toggleItemCreateSections(action: IAction<string, { key: keyof CreateItemSectionStatusState, value: boolean }>) {
  try {
    const {auth: {user: {company}}, settings: { company: settings }} = yield select();
    const data = yield CompanyService.editSettings(company, {
      ...settings,
      createItemSectionsStatus: {
        ...settings.createItemSectionsStatus,
        [action.payload.key]: action.payload.value
      }
    });
    yield put({
      type: SettingsActions.SET_ITEM_CREATE_SECTIONS,
      payload: action.payload
    })
  } catch (e) {
    notify.error('Cannot toggle this section');
  }
}

export default function* rootCompanySaga() {
  return yield all([
    yield takeLatest(CompanyActions.EDIT_COMPANY, edit),
    yield takeLatest(CompanyActions.SEND_APP_LINKS, sendAppLinks),
    yield takeLatest(CompanyActions.SEND_SUPPORT, sendSupport),
    yield takeLatest(SettingsActions.TOGGLE_ITEM_CREATE_SECTIONS, toggleItemCreateSections),
  ])
}