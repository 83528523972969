import {IMessageStore, NotificationTypes} from "@core/types";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {InventorizationIcon} from "@shared/Icons/svg";


const useNotification = (message: IMessageStore) => {
  const {t} = useTranslation();

  const getInfo = useCallback(() => {
    switch (message.type.toString()) {
      case NotificationTypes.STOCKTAKING_FINISHED:
        return {
          title: t('stocktaking_finished_title'),
          description: t('stocktaking_finished_desc') + ` ${message.userName || message.userEmail}`,
          icon: InventorizationIcon
        }
      default:
        return {
          title: 'unknown',
          description: 'unknown'
        }
    }
  }, [message]);

  return {
    getInfo
  }
}

export default useNotification;