import React from "react";
import Card from "../../../shared/Common/Card";
import {Button, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {img} from "@core/utils/string";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(1280)]: {
      display: 'block',
    },
  },
  card: {
    margin: '0 auto',
    width: 700,
    [theme.breakpoints.down(700)]: {
      width: '95%'
    },
  },
  cardTitle: {
    marginBottom: 0,
    position: 'absolute'
  },
  body: {
    flex: '1 1 60%',
    marginTop: 0,
    textAlign: 'center',
    '& h3': {
      fontSize: 18,
      marginBottom: 10
    },
  },
  title: {
    flex: '0 1 50%',
    textAlign: 'center'
  },
  img: {
    width: '100%'
  },
  item: {
    margin: '0px 0px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 30,
    alignSelf: 'flex-start'
  },
  input: {
    margin: 0
  },
  iconBack: {
    cursor: 'pointer'
  }
}));

const Welcome = (props: {continue: () => void}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.root}>
        <div className={classes.title}>
          <img src={img('welcome.svg')} alt="" className={classes.img}/>
        </div>
        <div className={classes.body}>
          <h3 className="text-default">Добро пожаловать на платформу Eqman</h3>
          <p>Для начала работы следует зарегистрировать вашу компанию</p>
        </div>
        <div className={classes.actions}>
          <Button
            color="secondary"
            onClick={props.continue}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </Card>
  )
};

export default Welcome;