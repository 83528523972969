import * as React from "react";
import {rules} from "../utils/validation";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import useDebounce from "@core/hooks/useDebounce";
import {useTranslation} from "react-i18next";
export interface IInputProps {
  rule?: string,
  defaultValue?: string,
  naked?: boolean,
  type?: string,
  label?: string,
  className?: string,
  variant?: any,
  size?: 'medium' | 'small',
  required?: boolean,
  helperText?: string,
  onChangeHook?: (value: string) => void,
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  maxLength?: number,
  minLength?: number,
  validate?: (value: string) => boolean,
  errorText?: any,
}
export interface IInputComponentProps extends IInputProps {
  code: string,
  getFullValue: () => string,
  setCode: (code: string) => void,
  value: string,
  id?: string,
  ref?: any,
  variant?: any,
  required?: boolean
  className?: string,
  error: boolean,
  placeholder?: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  size?: 'medium' | 'small',
  helperText?: string,
  setHelperText: (value: string) => void
  setError: (value: boolean) => void
  setValue: (value: string) => void
  disabled?: boolean,
  color?: any,
  multiline?: boolean,
  rows?: number,
  InputProps?: any,
  InputLabelProps?: any,
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  inputComponent?: any
}

const usePhoneInput = (props: IInputProps): IInputComponentProps => {
  const {t} = useTranslation();
  const {rule, onChangeHook, maxLength, validate} = props;
  const [code, setCode] = useState('');
  const [value, setValue] = React.useState(props.defaultValue || '');
  const [error, setError] = React.useState(false);
  const [touched, setTouched] = useState(false);
  const [prev, setPrevValue] = React.useState(props.defaultValue || '');
  const debounced = useDebounce(value, 300);

  useEffect(() => {
    if(onChangeHook && touched && debounced !== prev) {
      onChangeHook(debounced);
      setPrevValue(debounced);
    }
  }, [debounced, touched, prev, onChangeHook])

  const [errorText, setHelperText] = React.useState(props.helperText || '');

  const getFullValue = useCallback(() => {
    if(!value) return '';
    return (code+value).toString().replace(/[-]?[ ]?/gm, '');
  }, [code, value]);

  const handleSetHelperText = useCallback((value: string) => {
    setHelperText(t(value))
  }, [t])

  const onChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    setError(false);
    setHelperText('');
    if(validate && validate(newValue)) {
      return;
    }
    if(maxLength && newValue.length >= maxLength) {
      return;
    }
    if(rule) {
      const isError = rules[rule](newValue);
      setError(isError);
    }
    setValue(newValue);
    setTouched(true);
  }, [rule, maxLength, validate]);

  delete props.defaultValue;
  delete props.onChangeHook;
  return {
    ...props,
    error,
    onChange,
    value,
    errorText,
    setError,
    setHelperText: handleSetHelperText,
    setValue,
    code,
    setCode,
    getFullValue
  }
};

export default usePhoneInput;
