import React, {PropsWithChildren} from "react";
import {ThemeProvider} from "@material-ui/styles";
import {Button, ButtonProps, createMuiTheme} from "@material-ui/core";

const colors = {
  dark: '#a46b35',
  main: '#f98725',
  light: '#f8cfab',
  contrastText: '#fff'
}
const theme = createMuiTheme({
  palette: {
    primary: colors,
    secondary: {
      dark: "#236d56",
      main: '#0ab883',
      light: '#4eb89c',
      contrastText: '#fff',
    },
  },
});

const AccentButton = (props: PropsWithChildren<ButtonProps>) => {
  return (
    <ThemeProvider theme={theme}>
      <Button {...props} color={props.color || 'primary'}>{props.children}</Button>
    </ThemeProvider>
  )
}

export default AccentButton;