import React, {useEffect} from 'react';
import Tour from "reactour";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {makeStyles, Theme, Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import useTutorial from "@core/hooks/useTutorial";
import {img} from "@core/utils/string";
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
  mask: {
    "& rect:nth-child(2)": {
      fill: "transparent",
    },
    "& rect:nth-child(3)": {
      fill: "rgba(0,0,0,0.35)",
    }
  },
  tourOpen: {
    dispay: 'block',
  },
  tourClosed:  {
    display: 'none',
  },
  stepper: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20
  },
  stepTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 20
  },
  stepDescription: {
    fontSize: 13,
    color: '#5c5e6b'
  },
  list: {
    paddingLeft: 15,
    '& li': {
      margin: '5px 0'
    }
  },
  telegram: {
    display: 'flex',
    alignItems: 'center',
    color: '#439be5',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginTop: 10,
    '& img': {
      marginRight: 10,
    }
  }
}))

const Tutorial = (props: RouteComponentProps) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {
    getCurrentStep,
    finish,
    handleClose,
    handleNext,
    handlePrev,
    step,
    isOpen,
    onAfterStart,
    onBeforeClose
  } = useTutorial();



  return (
    <div>
      <Tour
        accentColor="#0ab883"
        maskSpace={15}
        goToStep={step}
        getCurrentStep={getCurrentStep}
        disableInteraction
        rounded={5}
        updateDelay={1}
        scrollDuration={2}
        scrollOffset={50}
        onAfterOpen={onAfterStart}
        onBeforeClose={onBeforeClose}
        steps={[
          {
            style: {
              padding: '15px 24px'
            },
            selector: '#telegram_bot',
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_1_title')}</h2>
                <div className={classes.stepDescription}>
                  <p>{t('tutorial_step_1_description_1')}</p>
                  <br/>
                  <p>{t('tutorial_step_1_description_2')}</p>
                  {isMobile && <a href="tg://resolve?domain=Kirill_Prianykov_bot&start=ml2" className={classes.telegram}>
                    <img src="./images/telegram-icon.png" alt="" style={{width: '20px', height: 'auto'}}/>
                    <span>Start</span>
                  </a>}
                </div>
              </div>
            )
          },
          {
            selector: '#first-step',
            observe: 'body',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_2_title')}</h2>
                <div className={classes.stepDescription}>
                  {t('tutorial_step_2_description')}
                  <ol className={classes.list}>
                    <li>{t('tutorial_step_2_list_item_1')}</li>
                    <li>{t('tutorial_step_2_list_item_2')}</li>
                  </ol>
                </div>
              </div>
            )
          },
          {
            selector: '#second-step',
            observe: 'body',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_3_title')}</h2>
                <div className={classes.stepDescription}>
                  {t('tutorial_step_3_description')}
                </div>
              </div>
            ),
            position: 'top'
          },
          {
            selector: '#third-step',
            observe: 'main',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_4_title')}</h2>
                <div className={classes.stepDescription}>
                  {t('tutorial_step_4_description')}
                  <ol className={classes.list}>
                    <li>{t('tutorial_step_4_list_item_1')}</li>
                    <li>{t('tutorial_step_4_list_item_2')}</li>
                    <li>{t('tutorial_step_4_list_item_3')}</li>
                  </ol>
                </div>
              </div>
            )
          },
          {
            selector: '#fourth-step',
            observe: 'main',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_5_title')}</h2>
                <div className={classes.stepDescription}>
                  {t('tutorial_step_5_description_1')}
                  <ol className={classes.list}>
                    <li>{t('tutorial_step_5_list_item_1')}</li>
                    <li>{t('tutorial_step_5_list_item_2')}</li>
                    <li>{t('tutorial_step_5_list_item_3')}</li>
                  </ol>
                  {t('tutorial_step_5_description_2')}
                </div>
              </div>
            )
          },
          {
            observe: 'main',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_6_title')}</h2>
                <div className={classes.stepDescription}>
                  <ol className={classes.list}>
                    <li>{t('tutorial_step_6_list_item_1')}</li>
                    <li>{t('tutorial_step_6_list_item_2')}</li>
                    <li>{t('tutorial_step_6_list_item_3')}</li>
                    <li>{t('tutorial_step_6_list_item_4')}</li>
                    <li>{t('tutorial_step_6_list_item_5')}</li>
                  </ol>
                </div>
              </div>
            )
          },
          {
            selector: '#sixth-step',
            observe: 'body',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_7_title')}</h2>
                <div className={classes.stepDescription}>
                  {t('tutorial_step_7_description')}
                </div>
              </div>
            )
          },
          {
            selector: '#plan_counters',
            observe: 'body',
            style: {
              padding: '15px 24px'
            },
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_8_title')}</h2>
                <div className={classes.stepDescription}>
                  {t('tutorial_step_8_description')}
                </div>
              </div>
            )
          },
          {
            style: {
              padding: '15px 24px'
            },
            selector: '#telegram_bot',
            content: (
              <div>
                <h2 className={classes.stepTitle}>{t('tutorial_step_9_title')}</h2>
                <div className={classes.stepDescription}>
                  <p>
                    {t('tutorial_step_9_description_1')}
                    <a href="https://eqman.notion.site/EqMan-20cc5d28c8c04f2ba6a0f15db09b231d" target="_blank" className="lin green"> {t('instruction')}</a>
                  </p>
                  <br/>
                  <p>
                    {t('tutorial_step_9_description_2')}
                    <a href="https://www.youtube.com/channel/UCm2jkIyvrkvrtzIY0D9enew" target="_blank" className="lin green"> Youtube</a>
                  </p>
                  <br/>
                  <p>
                    {t('tutorial_step_9_description_3')}
                    {isMobile && <a href="tg://resolve?domain=Kirill_Prianykov_bot&start=ml2" className={classes.telegram}>
                      <img src="./images/telegram-icon.png" alt="" style={{width: '20px', height: 'auto'}}/>
                      <span>Start</span>
                    </a>}
                  </p>
                </div>
              </div>
            )
          }
        ]}
        startAt={step}
        maskClassName={classes.mask}
        isOpen={isOpen}
        onRequestClose={handleClose}
        nextStep={handleNext}
        prevStep={handlePrev}
        showNavigation={false}
        prevButton={<Button size="small" disabled={step === 0}>{t('back')}</Button>}
        nextButton={
          <Button variant="contained" color="secondary" size="small" onClick={step !== 8 ? () => {} : finish}>{
            step !== 8 ? t('next') : t('finish')}
          </Button>
        }
      >
        <div className={classes.stepper}>
          {t('step')} {step+1} {t('of')} 9
        </div>
      </Tour>
    </div>
  );
}

export default withRouter(Tutorial);