import React, {useCallback} from 'react';
import AuthLayout from "../../../layout/Auth";
import {Helmet} from "react-helmet";
import AuthService from '../../../../core/services/auth';
import useMode from "../../../../core/hooks/useMode";
import ForgotPasswordMultiMode from "./MultiMode";
import ForgotPasswordError from "./Error";
import ForgotPasswordSuccess from "./Success";
import useInput from "../../../../core/hooks/useInput";
import {useTranslation} from "react-i18next";
import ForgotPasswordTimeError from "./Time";
import ForgotPasswordNotVerifiedError from "./NotVerified";

type IMode = 'default' | 'success' | 'error' | 'time' | 'unverified';
const Index = () => {
  const {mode, changeMode, back} = useMode<IMode>({defaultMode: "default"});
  const {t} = useTranslation();

  const emailOrPhone = useInput({
    label: t('login'),
    type: 'text',
    required: true,
  });

  const send = useCallback(async () => {
    if(!emailOrPhone.value) return;
    try {
      await AuthService.forgotPassword(emailOrPhone.value);
      changeMode('success');
    } catch(E) {
      const errorType = E.response ? E.response.data.message.name : E.name;
      if(errorType === 'NotNow') {
        changeMode('time');
      } else if(errorType === 'NotVerified') {
        changeMode('unverified');
      } else {
        changeMode('error');
      }
    }

  }, [emailOrPhone, changeMode]);

  return (
    <AuthLayout>
      <Helmet>
        <title>Eqman - {t('forgot_password_title')}</title>
      </Helmet>
      {mode === 'default' && <ForgotPasswordMultiMode emailOrPhone={emailOrPhone} send={send}/>}
      {mode === 'error' && <ForgotPasswordError back={back} emailOrPhone={emailOrPhone.value} />}
      {mode === 'success' && <ForgotPasswordSuccess back={back} emailOrPhone={emailOrPhone.value} />}
      {mode === 'time' && <ForgotPasswordTimeError back={back} />}
      {mode === 'unverified' && <ForgotPasswordNotVerifiedError back={back} emailOrPhone={emailOrPhone.value}/>}
    </AuthLayout>
  )
}

export default Index;
