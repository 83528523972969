import { all, takeLatest, put, select, delay, call } from "redux-saga/effects";
import {
  IAction,
  IActionWithSingleSideEffect,
  IActionWithErrorAndSuccess,
} from "../actions";
import service from "../../services/location";
import { LocationActions } from "../actions/locations";
import { normalizeFilters } from "../../utils/normalize";
import { Saga, SagaIterator } from "redux-saga";

function* getItems(action: IAction): SagaIterator {
  try {
    yield put({
      type: LocationActions.SET_LOADING,
      payload: true,
    });

    const {
      locations: { filters },
    } = yield select();

    const pagination = action.payload.pagination;

    const items = yield call(service.getItems, filters, pagination);
    yield put({
      type: LocationActions.SET_ITEMS,
      payload: {
        data: items.data,
        total: items.count,
      },
    });

    yield put({
      type: LocationActions.SET_LOADING,
      payload: false,
    });
  } catch (E) {
    yield put({
      type: LocationActions.SET_ITEMS,
      payload: {
        data: [],
        total: 0,
      },
    });
  }
}

function* upload(action: IActionWithSingleSideEffect): SagaIterator {
  try {
    yield put({
      type: LocationActions.SET_LOADING,
      payload: true,
    });

    const {
      locations: { filters },
    } = yield select();

    const pagination = action.payload.pagination;

    const items = yield call(service.getItems, filters, pagination);

    yield put({
      type: LocationActions.UPDATE_ITEMS,
      payload: {
        data: items.data,
        total: items.count,
      },
    });

    yield put({
      type: LocationActions.SET_LOADING,
      payload: false,
    });
  } catch (E) {
    yield put({
      type: LocationActions.SET_ITEMS,
      payload: {
        data: [],
        total: 0,
      },
    });
  }
}

function* create(action: IActionWithErrorAndSuccess): SagaIterator {
  try {
    const item = yield call(service.create, action.payload);
    yield call(action.sideEffectSuccess, item._id);

    const {
      locations: { pagination },
    } = yield select();

    yield put({
      type: LocationActions.GET_ITEMS,
      payload: {
        pagination,
      },
    });
  } catch (E) {
    yield call(action.sideEffectError, E);
  }
}

function* edit(action: IActionWithSingleSideEffect): SagaIterator {
  try {
    const item = yield call(
      service.edit,
      action.payload.id,
      normalizeFilters(action.payload.data),
      action.payload.shouldUpdate
    );
    yield put({
      type: LocationActions.UPDATE_ITEM,
      payload: {
        ...item,
      },
    });

    const {
      locations: { pagination },
    } = yield select();

    yield put({
      type: LocationActions.GET_ITEMS,
      payload: {
        pagination: { ...pagination, offset: 0 },
      },
    });
    yield call(action.sideEffect);
  } catch (E) {
    console.log(E);
  }
}

function* remove(action: IActionWithSingleSideEffect) {
  try {
    yield call(service.remove, action.payload.id, action.payload.shouldUpdate);
    yield action.sideEffect();

    const {
      locations: { pagination },
    } = yield select();

    yield put({
      type: LocationActions.GET_ITEMS,
      payload: {
        pagination,
      },
    });
  } catch (E) {
    console.log(E);
  }
}

export function* rootLocationsSaga(): SagaIterator {
  yield all([
    yield takeLatest(LocationActions.GET_ITEMS, getItems),
    yield takeLatest(LocationActions.UPLOAD_ITEMS, upload),
    yield takeLatest(LocationActions.CREATE_ITEM, create),
    yield takeLatest(LocationActions.REMOVE_ITEM, remove),
    yield takeLatest(LocationActions.EDIT_ITEM, edit),
  ]);
}
