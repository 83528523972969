import * as React from "react";


const useDropdown = (props: any = {}) => {
  const {open: openDefault = false} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(openDefault || false);
  const handleOpen = React.useCallback((event) => {
    setAnchorEl(event.target);
    setOpen(true);
  },[]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
  }, []);

  const handleToggle = React.useCallback((event:any) => {
    if(!open) {
      setAnchorEl(event.target);
      setOpen(true);
    } else {
      setOpen(false);
      setAnchorEl(null);
    }
  }, [open]);
  const id = open ? 'simple-popover' : undefined;

  return {
    ...props,
    id,
    anchorEl,
    handleOpen,
    handleClose,
    handleToggle,
    open
  }
}
export default useDropdown;
