/* eslint-disable no-restricted-globals */
import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import Card from "@shared/Common/Card";
import {makeStyles} from "@material-ui/styles";
import {Button, Theme} from "@material-ui/core";
import {IUser} from "@core/types";
import ButtonWithTimer from "@shared/Common/Button/ButtonWithTimer";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    padding: '50px 50px',
    position: "relative",
    zIndex: 100,
    marginTop: -100,
    [theme.breakpoints.down(600)]: {
      width: '95%',
      padding: '20px'
    },
    '& h3.text-default, & h3.text-default + p' : {
      textAlign: 'center'
    }
  },
  card: {
    padding: '20px'
  },
  img: {
    width: '70%',
    display: 'block',
    margin: '0 auto'
  },
  description: {
    padding: '10px 0px'
  },
  googleBtn: {
    width: '100%',
    padding: '10px 0px',
    marginTop: '10px',
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 25,
  },
}));

interface IProps extends RouteComponentProps {
  user: IUser,
  goToSms: () => void,
  withTimer?: boolean,
}

const RegistrationEmailMessage = (props: IProps) => {
  const {
    user: {email},
    goToSms,
    withTimer,
    history
  } = props;
  const {t} = useTranslation();
  const classes = useStyles();
  const [time, setTime] = useState(withTimer ? 60 : 0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    if(time === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    }
  }, [time]);

  const handleContinue = useCallback(() => {
    localStorage.removeItem('auth');
    history.push('/auth/login')
  }, [history]);

  return (
    <Card className={classNames(classes.root, classes.card)}>
      <img src={img('email_sent.svg')} alt="" className={classes.img}/>
      <h3 className="text-default">Сообщение отправлено на <br/> <b>{email}</b></h3>
      <p className="subtitle">Перейдите на ваш почтовый ящик и в подтвердите ваш email нажав на ссылку в письме. Проверьте все катеогрии вашего почтового клиента или выберите "Вся почта".</p>
      <div className={classes.actions}>
        <ButtonWithTimer variant="contained" color="secondary" onClick={goToSms} awaitTime={0}>
          Сообщение не пришло
        </ButtonWithTimer>
        <Button color="secondary" onClick={handleContinue}>{t('continue')}</Button>
      </div>
    </Card>
  )
}

export default withRouter(RegistrationEmailMessage);