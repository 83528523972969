import React, {useCallback} from "react";
import Card from "../../../../shared/Common/Card";
import {ItemActionStyles} from "../../Items/Actions/styles";
import {makeStyles} from "@material-ui/styles";
import {Button, Theme} from "@material-ui/core";
import Input from "../../../../shared/Common/Form/Input";
import useInput from "../../../../core/hooks/useInput";
import Service from '../../../../core/services/auth';
import notify from "../../../../core/utils/notify";
import {img} from "@core/utils/string";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.down(700)]: {
      width: '95%'
    },
  }
}))

const CreateCompanyPage = () => {
  const rootStyles = useStyles();
  const classes = ItemActionStyles();

  const title = useInput({
    label: 'Название компании',
    required: true
  });

  const description = useInput({
    label: 'Опишите ваш род деятельности',
    required: true
  });

  const handleSubmit = useCallback(async () => {
    try {
      await Service.createCompany({
        title: title.value,
        description: description.value
      });
      window.location.reload();
    } catch (E) {
      const name = E.response ? E.response.data.message.name : E.name;
      if(name === 'UniquenessError') {
        notify.error('Компания с таким названием уже существует')
      } else {
        notify.error('Ошибка создания компании')
      }
    }

  },[title, description]);

  return (
    <div className={rootStyles.root}>
      <Card className={classes.card}>
        <div className={classes.root}>
          <div className={classes.title}>
            <img src={img('create-company.jpg')} alt="" className={classes.img}/>
          </div>
          <div className={classes.body}>
            <h3 className="text-default">Создание компании</h3>
            <p className="subtitle">Заполните все поля для создание компании</p>
            <div className={classes.item}>
              <Input {...title}/>
            </div>
            <div className={classes.item}>
              <Input {...description} multiline rows={8}/>
            </div>
            <div className={classes.actions}>
              <Button color="secondary" disabled={!title.value || !description.value} onClick={handleSubmit}>Создать</Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
};

export default CreateCompanyPage;